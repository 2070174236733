import React, { Component } from "react"
import { connect } from "react-redux"
import _ from "lodash"
import { Dimmer, Header, Loader } from "semantic-ui-react"

import ScrollView from "../../components/ScrollView"
import BorrowableDetails from "../../components/BorrowableDetails/BorrowableDetails"
import Pagination from "../../components/Pagination"

import SearchHeader from "./SearchHeader"
import Borrowable from "./Borrowable"

import {
	requestBorrowable,
	closeBorrowable,
	createBorrowable,
	deleteBorrowable,
	editBorrowable,
	requestAddBorrowableNote,
	requestBorrowables,
	setMovieDetails,
	editMedia,
	deleteMedia,
	createMedia,
	motionPictureValidation,
	deleteBorrowableNote,
	loadAllRentals,
} from "../../actions/movies"

import { addToBasket, removeFromBasket } from "../../actions/basket"

class MovieList extends Component {
	constructor(props) {
		super(props);

		this.state = {excludeDeleted: true, resetScroll: 0}

		this.handleResetQuery = _.bind(this.handleResetQuery, this);
		this.handleExcludeDeletedChange = _.bind(this.handleExcludeDeletedChange, this);
		this.handleQueryChange = _.bind(this.handleQueryChange, this);
		this.handleSearch = _.bind(this.handleSearch, this);
		this.changePage = _.bind(this.changePage, this);
		this.selectBorrowable = this.selectBorrowable.bind(this);
	}

	handleResetQuery() {
		this.handleSearch({
			code: "",
			title: "",
			director: "",
			actor: "",
		})
	}

	handleExcludeDeletedChange(_e, data) {
		this.setState({
			excludeDeleted: !data.checked
		})
	}

	handleQueryChange(_e, props) {
		this.handleSearch(_.assign({}, this.props.query, {
			[props.name]: props.value
		}))
	}

	handleSearch(query) {
		this.props.requestBorrowables(query);
	}

	changePage(event, page) {
		if (page >= 0 && page <= this.props.maxPage) {
			this.setState({resetScroll: this.state.resetScroll+1});
			event.preventDefault();
			this.props.requestBorrowables(this.props.query, page);
		}
	}

	selectBorrowable(borrowable) {
		if (!borrowable) {
			this.props.closeBorrowable();
		} else {
			this.props.requestBorrowable(borrowable.id);
		}
	}

	toggleCreateNew() {
		if (this.props.details.isNew) {
			this.props.setClientDetails({
				isNew: false,
			});
		} else {
			this.props.setClientDetails({
				isNew: true,
			});
		}
	}

	render() {
		let filteredBorrowables = this.props.borrowables
		if(this.state.excludeDeleted) {
			filteredBorrowables = _.filter(
				_.map(filteredBorrowables, (borrowable) => {
					return _.assign({}, borrowable, {
						hadMedias: borrowable.medias.length > 0,
						medias: _.filter(borrowable.medias, (m) => m.status !== "deleted")
					})
				}), (borrowable) => borrowable.medias.length || !borrowable.hadMedias
			)
		}

		const borrowables = _.map(filteredBorrowables, borrowable => {
			return {
				...borrowable,
				medias: _.map(borrowable.medias, media => {
					return {
						...media,
						isBasketRes: _.find(
							this.props.basketReservations,
							r => r.media.id ===  media.id
						),
						isBasketRental: _.find(
							this.props.basketRentals,
							r => r.media.id ===  media.id
						),
						hasRes: (media.reservations.length > 0 ? true : false),
						isRes: this.props.basketClient && _.find(media.reservations,
							{ clientId: this.props.basketClient.id }) ? true : false,
					}
				})
			}
		});

		return (
			<ScrollView
				headerHeight="6em"
				footerHeight="5em"
				detailsWidth="30em"
				resetScroll={this.state.resetScroll}
				header={(
					<SearchHeader
						handleQueryChange={this.handleQueryChange}
						handleExcludeDeletedChange={this.handleExcludeDeletedChange}
						excludeDeleted={this.state.excludeDeleted}
						query={this.props.query}
						toggleCreateNew={this.toggleCreateNew}
						resetQuery={this.handleResetQuery}
					/>
				)}
				footer={(
					<Pagination
						page={this.props.page}
						maxPage={this.props.maxPage}
						handlePageChange={this.changePage}
					/>
				)}
				details={(
					<BorrowableDetails
						mode={this.props.details}
						creatingMovieNote={this.props.creatingMovieNote}
						borrowable={this.props.borrowable}
						apiUrl={this.props.apiUrl}
						apiKey={this.props.apiKey}
						basePosterUrl={this.props.basePosterUrl}
						langOptions={this.props.langOptions}
						handleAddNote={this.props.addBorrowableNote}
						editMedia={this.props.editMedia}
						deleteMedia={this.props.deleteMedia}
						createMedia={this.props.createMedia}
						motionPictureValidation={this.props.motionPictureValidation}
						closeBorrowable={this.props.closeBorrowable}
						createBorrowable={this.props.createBorrowable}
						deleteBorrowable={this.props.deleteBorrowable}
						editBorrowable={this.props.editBorrowable}
						addToBasket={this.props.addToBasket}
						basketRentals={this.props.basketRentals}
						users={this.props.users}
						handleDeleteNote={this.props.deleteBorrowableNote}
						loadAllRentals={this.props.loadAllRentals}
					/>
				)}
			>
					{
						<Dimmer.Dimmable
							blurring
							dimmed={this.props.searching || borrowables.length === 0}
							style={{ minHeight: "100%" }}
						>
							<Dimmer inverted active={this.props.searching}>
								<Loader>Lade…</Loader>
							</Dimmer>
							<Dimmer
								inverted
								active={
									(
										borrowables.length === 0 &&
										_.join(_.values(this.props.query), "") &&
										!this.props.searching
									) ? true : false
									   }
							>
								<Header as="h2">
									Es konnten keine Filme gefunden werden.
								</Header>
							</Dimmer>
							{_.map(borrowables, borrowable =>
								<Borrowable
									key={borrowable.id}
									borrowable={borrowable}
									isActive={this.props.borrowable && this.props.borrowable.id === borrowable.id}
									selectBorrowable={this.selectBorrowable}
									addToBasket={this.props.addToBasket}
									removeFromBasket={this.props.removeFromBasket}
								/>
							)}
						</Dimmer.Dimmable>
					}
			</ScrollView>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		...state.movies,
		basketRentals: state.basket.rentals,
		basketReservations: state.basket.reservations,
		basketClient: state.basket.client,
		priceCategories: state.app.priceCategories,
		apiUrl: state.app.tmdb.apiUrl,
		apiKey: state.app.tmdb.apiKey,
		basePosterUrl: state.app.tmdb.basePosterUrl,
		langOptions: state.app.langOptions,
		users: state.app.users,
	}
}
const mapDispatchToProps = (dispatch) => {
	return {
		requestBorrowable: id => dispatch(
			requestBorrowable(id)
		),
		closeBorrowable: () => dispatch(
			closeBorrowable()
		),
		createBorrowable: (data) => dispatch(
			createBorrowable(data)
		),
		deleteBorrowable: (borrowableId, replaceWith) => dispatch(
			deleteBorrowable(borrowableId, replaceWith)
		),
		editBorrowable: (data, borrowableId) => dispatch(
			editBorrowable(data, borrowableId)
		),
		requestBorrowables: (query, page) => dispatch(
			requestBorrowables(query, page)
		),
		addToBasket: (borrowable, media, isReservation = false) => dispatch(
			addToBasket(borrowable, media, isReservation)
		),
		removeFromBasket: (media, isReservation) => dispatch(
			removeFromBasket(media, isReservation)
		),
		setMovieDetails: details => dispatch(
			setMovieDetails(details)
		),
		editMedia: (data, borrowableId, mediaId) => dispatch(
			editMedia(data, borrowableId, mediaId)
		),
		deleteMedia: (mediaId) => dispatch(
			deleteMedia(mediaId)
		),
		createMedia: (data, borrowableId) => dispatch(
			createMedia(data, borrowableId)
		),
		motionPictureValidation: (borrowableId, method, data = {}, mpId = null) => dispatch(
			motionPictureValidation(borrowableId, method, data, mpId)
		),
		addBorrowableNote: (borrowableId, content) => dispatch(
			requestAddBorrowableNote(borrowableId, content)
		),
		deleteBorrowableNote: (borrowableId, noteId) => dispatch(
			deleteBorrowableNote(borrowableId, noteId)
		),
		loadAllRentals: (media) => dispatch(
			loadAllRentals(media)
		),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(MovieList)
