import React, { Component } from "react"
import { Switch, Route, Redirect } from "react-router-dom"
import { connect } from "react-redux"
import { push } from "react-router-redux"
import Sidebar from '../../components/Sidebar/Sidebar'

import Dashboard from '../../views/Dashboard/Dashboard'
import Movies from "../../views/Movies/Movies"
import Clients from "../../views/Clients/Clients"
import Basket from "../../views/Basket/Basket"
import Returns from "../../views/Returns/Returns"
import CashRegister from "../../views/CashRegister/CashRegister"

import styled from 'styled-components'

const SidebarWrap = styled.section`
	position: fixed;
	overflow-x: hidden;
	overflow-y: auto;
	top: 0;
	width: 10em;
	bottom: 0;
	left: 0;
	transition: width 0.4s ease;
`
const MainWrap = styled.section`
	position: fixed;
	overflow-x: hidden;
	overflow-y: auto;
	top: 0;
	left: 10em;
	bottom: 0;
	right: 0;
	transition: left 0.4s ease;
	padding: 1em;
`

class Full extends Component {

	constructor(props) {
		super(props)

		this.keyPress = this.keyPress.bind(this)
	}

	componentDidMount() {
		document.addEventListener("keydown", this.keyPress, false)
	}

	componentWillUnmount() {
		document.removeEventListener("keydown", this.keyPress)
	}

	keyPress(event) {
		let preventDef = true

		switch (event.keyCode) {
			case 112:                           // "F1" key
				this.props.push("/dashboard")
				break

			case 113:                           // "F2" key
				this.props.push("/movies")
				break

			case 114:                           // "F3" key
				this.props.push("/clients")
				break

			case 115:                           // "F4" key
				this.props.push("/basket")
				break

			case 116:                           // "F5" key
				this.props.push("/cash-register")
				break

			case 117:                           // "F6" key
				this.props.push("/returns")
				break

			default:
				preventDef = false
				break
		}

		if (preventDef) {
			event.preventDefault()
		}
	}

	render() {
		return (
			<div className="app">
				<div className="lv-app-body">
					<SidebarWrap>
						<Sidebar {...this.props} />
					</SidebarWrap>
					<MainWrap>
						<Switch>
							<Route path="/dashboard" name="Dashboard" component={Dashboard} />
							<Route path="/movies/:id?" name="Filme" component={Movies} />
							<Route path="/clients/:id?" name="Kunden" component={Clients} />
							<Route path="/basket" name="Warenkorb" component={Basket} />
							<Route path="/cash-register" name="Kasse" component={CashRegister} />
							<Route path="/returns" name="Returns" component={Returns} />
							<Redirect from="/" to="/dashboard"/>
						</Switch>
					</MainWrap>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {}
}

const mapDispatchToProps = (dispatch) => {
	return {
		push: (url) => dispatch(push(url)),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Full)
