import React from "react"
import { Button, Grid } from "semantic-ui-react"

import { FormatMoney } from "../../components/Util"


const Footer = (props) => {
	if (props.mode === "ack") {
		return (
			<Grid>
				<Grid.Column width={8}>
					<Button
						size="huge"
						onClick={e => props.clearBasket()}
						icon="trash"
					/>
				</Grid.Column>
				<Grid.Column width={8} textAlign="right">
					<Button
						primary
						size="huge"
						onClick={ (e) => { props.clearBasket(true) } }
						icon="money"
						content="Zur Kasse"
					/>
				</Grid.Column>
			</Grid>
		);
	}
	return (
		<Grid verticalAlign="middle">
			<Grid.Column width={12}>
				<Button
					size="huge"
					onClick={e => props.clearBasket()}
					icon="trash"
				/>
			</Grid.Column>
			<Grid.Column width={2} textAlign="right">
				<strong>Total: {FormatMoney(props.calcTotals().total)}</strong>
			</Grid.Column>
			<Grid.Column width={2} textAlign="right">
				<Button
					primary
					size="huge"
					disabled={
						!props.client ||
							  props.sending ||
							  (!props.membership && !props.reservations.length && !props.rentals.length && !props.additionalCosts.length)
							 }
					onClick={e => props.handleCheckout()}
					loading={props.sending}
					icon="send"
				/>
			</Grid.Column>
		</Grid>
	)
}

export default Footer
