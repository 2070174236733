import { connect } from "react-redux"
import React, { Component } from "react"
import { Link } from "react-router-dom"
import styled from "styled-components"
import { Icon, Menu } from "semantic-ui-react"

import { requestLogout } from "../../actions/app"

const Wrapper = styled.div`
	background: lightness(#174e77, 20%);
	color: #2a256d;
	height: 100%;
	display: flex;
	flex-flow: column nowrap;
	justify-content: space-between;
	align-items: center;
	padding: 1em;
`;

const IconAddition = styled.div`
	position: absolute;
	${ props => props.position && props.position.indexOf("left") !== -1 ? "left" : "right" }: 0.2em;
	${ props => props.position && props.position.indexOf("top") !== -1 ? "top" : "bottom" }: 0.2em;

	> span {
		width: 1.6em;
		height: 1.6em;
		display: flex;
		justify-content: center;
		align-items: center;
		border: 2px solid currentColor;
		line-height: 1;
		border-radius: 0.8em;

		> i {
			margin: 0;
		}
	}
`;

const StyledMenu = styled(Menu)`
background: transparent!important;
box-shadow: none!important;
border: 0!important;
}
`

const MenuItem = styled(Menu.Item)`
border: 0!important;
display: block;
text-align: center;

&.active,
&:hover {
background: transparent!important;
}

&:focus {
box-shadow: 0 0 0.3em #ccc!important;
outline: none;
}

> i.icon {
display: block;
width: 2em;
margin: 0.2em auto;
font-size: 290%;
}

`

class Sidebar extends Component {

	render() {
		const pathSections = this.props.history.location.pathname.split("/")
		const currentSection = pathSections.length && pathSections.length > 1 ? pathSections[1] : ""
		return (
			<Wrapper>
				<StyledMenu
					compact
					icon="labeled"
					vertical
					color="blue"
				>
					<MenuItem
						name="dashboard" as={Link}
						active={currentSection === "dashboard"}
						to="/dashboard" tabIndex="0"
					>
						<Icon name="dashboard" size="large" />
						Dashboard
					</MenuItem>
					<MenuItem
						name="movies" as={Link}
						active={currentSection === "movies"}
						to="/movies" tabIndex="0"
					>
						<Icon name="film" size="large" />
						Filme
					</MenuItem>
					<MenuItem
						name="clients" as={Link}
						active={currentSection === "clients"}
						to="/clients" tabIndex="0"
					>
						<Icon name="users" size="large" />
						Kunden
					</MenuItem>
					<MenuItem
						name="basket" as={Link}
						active={currentSection === "basket"}
						to="/basket" tabIndex="0"
					>
						<Icon name="shopping basket" size="large" />
						{
							this.props.basketRentals.length ?
							<IconAddition position="top.right">
								<span>{this.props.basketRentals.length}</span>
							</IconAddition>
							: null
						}
						{
							this.props.activeClient &&
							<IconAddition position="top-left">
								<span><Icon name="user" /></span>
							</IconAddition>

						}
						Warenkorb
					</MenuItem>
					<MenuItem
						name="cash-register" as={Link}
						active={currentSection === "cash-register"}
						to="/cash-register" tabIndex="0"
					>
						<Icon name="money" size="large" />
						{
							this.props.activeClient &&
							<IconAddition position="top-left">
								<span><Icon name="user" /></span>
							</IconAddition>

						}
						Kasse
					</MenuItem>
					<MenuItem
						name="returns" as={Link}
						active={currentSection === "returns"}
						to="/returns" tabIndex="0"
					>
						<Icon name="reply" size="large" />
						{
							this.props.rentalsCount ?
							<IconAddition position="top.right">
								<span>{this.props.rentalsCount}</span>
							</IconAddition>
							: null
						}
						{
							this.props.activeClient &&
							<IconAddition position="top-left">
								<span><Icon name="user" /></span>
							</IconAddition>

						}
						Rücknahme
					</MenuItem>
				</StyledMenu>
				<StyledMenu
					compact
					icon="labeled"
					vertical
					color="blue"
				>
					<MenuItem
						name="userName"
					>
						{this.props.app.user.firstName} {this.props.app.user.lastName}
					</MenuItem>
					<MenuItem
						name="logout"
						onClick={this.props.requestLogout}
						tabIndex="0"
					>
						<Icon name="log out" size="large" />
						Logout
					</MenuItem>
				</StyledMenu>
			</Wrapper>
		)
	}
}

function mapStateToProps (state) {
	return {
		app: state.app,
		basketRentals: state.basket.rentals,
		basketReservations: state.basket.reservations,
		activeClient: state.app.client,
		rentalsCount: state.returns.total,
	};
}

const mapDispatchToProps = (dispatch) => {
	return {
		requestLogout: () => dispatch(requestLogout()),
	}
}

export default  connect(mapStateToProps, mapDispatchToProps)(Sidebar);
