import _ from "lodash"

import {
	SET_CLIENT_RESPONSE,
} from "../actions/app"

import {
	ADD_RETURN_REQUEST,
	ADD_RETURN_RESPONSE,
	RENTED_REQUEST,
	RENTED_RESPONSE,
	RENTAL_RECEIVE,
} from "../actions/returns"

import {
	CHECKOUT_RESPONSE,
} from "../actions/basket"

export default (state = {
	medias: [],
}, action) => {
	switch (action.type) {
		case ADD_RETURN_REQUEST:
			return _.merge({}, state, {
				medias: _.map(
					state.medias,
					(m) => {
						if (_.indexOf(action.rentalsIds, m.rental.id) !== -1) {
							m.requesting = true
						}
						return m
					}),
			});

		case ADD_RETURN_RESPONSE:
			// flatten results
			const _rentals = _.map(action.data.results, (r) => r.rental)

			return _.assign({}, state, {
				medias: _.map(state.medias, (m) => {
					const _rental = _.find(_rentals, { id: m.rental.id })
					if(_rental) {
						m.requesting = false
						m.rental = _.assign(m.rental, _rental)
					}
					return m
				})
			})

		case RENTAL_RECEIVE:
			return _.assign({}, state, {
				medias: _.map(state.medias, (m) => {
					return m.rental.id === action.data.id ? _.assign({}, m, {
						rental: action.data
					}) : m
				})
			})

		case RENTED_REQUEST:
			return _.assign({}, state, {
				searching: true,
				query: action.query,
				page: action.page,
			});

		case RENTED_RESPONSE:
			if (action.data && action.data.medias) {
				return _.assign({}, state, {
					needsReload: false,
					searching: false,
					medias: action.data.medias,
					total: action.data.total,
					page: action.data.page,
					maxPage: action.data.pages,
				});
			}
			return state

		case CHECKOUT_RESPONSE:
			return _.assign({}, state, {
				needsReload: true,
			});

		case SET_CLIENT_RESPONSE:
			return _.assign({}, state, {
				needsReload: false,
			});

		default:
			return state;
	}
}
