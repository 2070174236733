import React from "react";
import { Button, Card } from "semantic-ui-react"
import { Link } from "react-router-dom"
import CardFooterButtons from "../../components/CardFooterButtons"
import { FormatMoney } from "../../components/Util"


const UnsentInvoice = (props) => {
	const { inv } = props
	return (
		<Card key={inv.id}>
			<Card.Content>
				<Card.Header>
					{inv.invoiceNumber}
				</Card.Header>
				<Card.Content>
					<Link to={`/clients/${inv.client.id}`}>
						{inv.client.fullName}
					</Link>
				</Card.Content>
				<Card.Description>
					<strong>{FormatMoney(inv.balance * -1)}</strong> Total
				</Card.Description>
			</Card.Content>
			<CardFooterButtons>
				<Button
					compact
					onClick={e => props.handleToCashRegister(inv.client)}
					icon="money"
					content="Zur Kasse"
				/>
			</CardFooterButtons>
		</Card>
	)
}
export default UnsentInvoice
