import React from "react"

import { Label, Popup, Icon } from "semantic-ui-react"

import { MEDIA_TYPE_LABELS } from './MediaCard';

const MediaMetaInfos = props => {
	const media = props.media
	const borrowable = props.borrowable
	
	const lang = media.languages || borrowable.customLanguages
	const sub = media.subtitles || borrowable.customSubtitle
	
	let count = '';
	if (media.count > 1) {
		count = `${media.count} `;
	}
	
	return (
		<>
			{media.description ? ` – ${media.description} ` : " "}
			<Label content={`${count}${MEDIA_TYPE_LABELS[media.type]}`} />
			{(media.regionCode || "?") !== "?" ? <Label icon="world" content={media.regionCode} /> : undefined}
			{lang ? <Label icon="discussions" content={lang} /> : undefined}
			{sub ? <Label icon="content" content={sub} /> : undefined}
			{media.extras ? (
				<Popup
					content={media.extras}
					trigger={<Icon name="gift" />}
				/>
			) : null}
		</>
	)
}

export default MediaMetaInfos
