import React, { Component } from "react";
import {
  Tab,
  Message,
  Segment,
  Button,
  Header,
  Confirm,
  Dimmer,
  Loader,
  Form
} from "semantic-ui-react";

import DetailsPane from "./DetailsPane";
import TmdbPane from "./TmdbPane";
import MediasPane from "./MediasPane";

class BorrowableDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.handleChange = this.handleChange.bind(this);
    this.linkTmdb = this.linkTmdb.bind(this);
  }

  linkTmdb(borrowableId, tmdb) {
    this.props.motionPictureValidation(borrowableId, "post", tmdb);
  }

  handleChange(key, value) {
    this.setState({
      [key]: value
    });
  }

  handleDelete(mode = null) {
    if (!this.props.borrowable) return;
    if (!mode) {
      const alternates = this.props.borrowable.alternates;
      const replaceWith = alternates.length ? alternates[0].id : undefined;
      this.setState({ showDeleteModal: true, replaceWith: replaceWith});
    }
    else if (mode === "cancel") this.setState({ showDeleteModal: null });
    else if (mode === "ok") {
      const replaceWith = this.state.replaceWith;
      this.setState({ showDeleteModal: null, replaceWith: undefined });
      this.props.deleteBorrowable(this.props.borrowable.id, replaceWith);
    }
  }

  render() {
    const { mode, borrowable } = this.props;

    const panes = [
      {
        menuItem: { key: "tmdb", icon: "film", content: "TMDB" },
        render: () => (
          <TmdbPane
            mode={mode}
            borrowable={borrowable}
            searchTmdb={this.searchTmdb}
            linkTmdb={this.linkTmdb}
            apiUrl={this.props.apiUrl}
            apiKey={this.props.apiKey}
            motionPictureValidation={this.props.motionPictureValidation}
          />
        )
      },
      {
        menuItem: { key: "info", icon: "info", content: "Details" },
        render: () => <DetailsPane {...this.props} />
      }
    ];

    if (borrowable) {
      panes.push({
        menuItem: { key: "db", icon: "database", content: "Medien" },
        render: () => (
          <MediasPane
            mode={mode}
            borrowable={borrowable}
            state={this.state}
            handleChange={this.handleChange}
            langOptions={this.props.langOptions}
            editMedia={this.props.editMedia}
            createMedia={this.props.createMedia}
            deleteMedia={this.props.deleteMedia}
            addToBasket={this.props.addToBasket}
            basketRentals={this.props.basketRentals}
            loadAllRentals={this.props.loadAllRentals}
          />
        )
      });
    }

    const borrowableDisplayTitle = borrowable
    ? borrowable.customTitle
      ? borrowable.customTitle +
        (borrowable.discTitle ? " - " + borrowable.discTitle : "") +
        (borrowable.customOriginalTitle &&
        borrowable.customTitle !== borrowable.customOriginalTitle
          ? " (" + borrowable.customOriginalTitle + ")"
          : "")
      : "Ohne Titel"
    : "Neu";

    return (
      <Segment>
        <Dimmer inverted active={mode.requesting}>
          <Loader>Lade…</Loader>
        </Dimmer>
        <Confirm
          open={this.state.showDeleteModal}
          onCancel={e => {
            this.handleDelete("cancel");
          }}
          onConfirm={e => {
            this.handleDelete("ok");
          }}
          header="Vorsicht!"
          cancelButton="Abbrechen"
          confirmButton="Löschen"
          content={
            borrowable
            ?
            <div className='content'>
              <h4>{borrowableDisplayTitle} wirklich löschen?</h4>
              
              {borrowable.alternates.length ? <div>
                <Form.Select
                  label="Bestehende Medien verschieben zu  "
                  options={borrowable.alternates.map(({id, title, medias}) => ({key: id, value: id, text: `${id}: ${title} (${medias} Medien)`}))}
                  value={this.state.replaceWith || borrowable.alternates[0].id}
                  onChange={(_, data) => {
                    this.setState({replaceWith: data.value})
                  }}
                />
                <Message warning>
                  Importierte Tonspur- und Untertitelinfos gehen verloren. Bitte auf Medien erfassen!
                </Message>
              </div> : undefined}
            </div>
            :
            undefined
          }
        />
        {borrowable ? (
          <Button
            primary
            floated="right"
            circular
            icon="close"
            onClick={() => {
              this.props.closeBorrowable();
            }}
          />
        ) : null}
        {borrowable && (borrowable.alternates.length || !borrowable.medias.length) ? (
          <Button
            color="red"
            floated="right"
            circular
            icon="trash"
            onClick={e => {
              this.handleDelete();
            }}
          />
        ) : null}
        <Header style={{ marginTop: "0.75em" }}>
          {borrowableDisplayTitle}
        </Header>
        <Tab menu={{ secondary: true, pointing: true }} panes={panes} />
      </Segment>
    );
  }
}

export default BorrowableDetails;
