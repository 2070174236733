import React, { Component } from "react"
import _ from "lodash"
import { Button, Divider } from "semantic-ui-react"

import MediaCard from "./MediaCard"

class MediasPane extends Component {
	constructor(props) {
		super(props)

		this.state = {
			newItem: false,
		}

		this.handleAddButton = this.handleAddButton.bind(this)
		this.handleCancel = this.handleCancel.bind(this)
	}

	componentWillReceiveProps(nextProps) {
		// we want to know if a new item was created
		if (nextProps.borrowable.medias.length !== this.props.borrowable.medias.length) {
			this.setState({ newItem: false })
		}
	}

	handleAddButton() {
		this.setState({
			newItem: {}
		})
	}

	handleCancel() {
		this.setState({
			newItem: false,
		})
	}

	render() {
		return (
			<div>
				{
					this.props.borrowable ?
					_.map(this.props.borrowable.medias, (media) =>
						<MediaCard
							key={media.id}
							borrowable={this.props.borrowable}
							media={media}
							langOptions={this.props.langOptions}
							editMedia={this.props.editMedia}
							deleteMedia={this.props.deleteMedia}
							addToBasket={this.props.addToBasket}
							isInRentalBasket={_.find(_.map(this.props.basketRentals, r => r.media), {id: media.id})}
							loadAllRentals={this.props.loadAllRentals}
						/>
					)
					: null
				}
				<Divider />
				{
					this.state.newItem !== false ?
					<MediaCard
						media={this.state.newItem}
						borrowable={this.props.borrowable}
						langOptions={this.props.langOptions}
						editMedia={this.props.createMedia}
						handleCancel={this.handleCancel}
						deleteMedia={this.props.deleteMedia}
					/>
					:
					<Button basic fluid onClick={this.handleAddButton} icon="add" content="Neues Medium" />
				}
			</div>
		)
	}
}

export default MediasPane
