import React from 'react';
import { Card, Form, Button, Icon, Accordion, Label } from 'semantic-ui-react';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import _ from 'lodash';
import moment from 'moment';

import Note from '../Note/Note';
import MembershipInfo from '../Membership/MembershipInfo';
import DatepickerButton from '../../components/Util/DatepickerButton';

const getFieldValue = (field, client, changes) => {
  if (changes && typeof changes[field] !== 'undefined') return changes[field];

  if (client && client[field]) return client[field];

  return '';
};

const DetailField = props => (
  <Form.Input
    type={props.type}
    disabled={props.disabled ? true : false}
    label={props.label}
    name={props.field}
    value={getFieldValue(props.field, props.client, props.changes)}
    onChange={props.handleChange}
    onBlur={props.handleBlur}
  />
);

/*
 * details pane
 */
const DetailsPane = props => {
  const options = [
    { value: 'male', label: 'Herr' },
    { value: 'female', label: 'Frau' }
  ];

  const birthDatePlain = getFieldValue(
    'birthDate',
    props.client,
    props.changes
  );
  const birthDate = birthDatePlain
    ? moment(birthDatePlain)
    : moment('1980-01-01');
  return (
    <div>
      <Form>
        {props.client && (
          <div>
            <Card fluid style={{ marginBottom: '1em' }}>
              <Card.Content>
                <Card.Header>Nr. {props.client.code}</Card.Header>
                <MembershipInfo client={props.client} />
                {props.client &&
                props.client.memberships &&
                props.client.memberships.length ? (
                  <Accordion>
                    <Accordion.Title>
                      <Icon name="dropdown" />
                      Historie
                    </Accordion.Title>
                    <Accordion.Content>
                      {_.map(props.client.memberships, membership => (
                        <Card key={membership.id} fluid>
                          <Card.Content>
                            <Card.Header>
                              {membership.deleted ? (
                                <Label color="red" horizontal>
                                  Gelöscht
                                </Label>
                              ) : null}
                              {membership.title}
                            </Card.Header>
                            <Card.Meta>
                              {moment(membership.startDate).format(
                                'DD.MM.YYYY'
                              )}
                              {membership.endDate
                                ? `- ${moment(membership.endDate).format(
                                    'DD.MM.YYYY'
                                  )}`
                                : null}
                            </Card.Meta>
                            {membership.reminderSent ? (
                              <Card.Meta>
                                Reminder:{' '}
                                {moment(membership.reminderSent).format(
                                  'DD.MM.YYYY'
                                )}
                              </Card.Meta>
                            ) : null}
                            {membership.paid ? (
                              <Card.Meta>{membership.paid} CHF</Card.Meta>
                            ) : null}
                          </Card.Content>
                        </Card>
                      ))}
                    </Accordion.Content>
                  </Accordion>
                ) : null}
              </Card.Content>
              <Card.Content>
                <Form.Field>
                  <Form.Input
                    type="textarea"
                    name="newNote"
                    onChange={props.handleChange}
                    value={getFieldValue(
                      'newNote',
                      props.client,
                      props.changes
                    )}
                    placeholder="Neue Notiz"
                    loading={props.creatingClientNote}
                    action={
                      <Button
                        primary
                        floated="right"
                        disabled={
                          props.creatingClientNote || !props.changes.newNote
                        }
                        onClick={e => {
                          props.handleAddNote(
                            props.client.id,
                            props.changes['newNote']
                          );
                        }}
                        content="Ok"
                      />
                    }
                  />
                </Form.Field>
                {props.client.notes && props.client.notes.length
                  ? _.map(props.client.notes, (note, j) => (
                      <Note
                        key={note.id}
                        isLast={j === props.client.notes.length - 1}
                        note={note}
                        employee={
                          note.employeeId
                            ? _.find(props.users, { id: note.employeeId })
                            : null
                        }
                        handleDelete={e => {
                          props.handleDeleteNote(note.clientId, note.id);
                        }}
                      />
                    ))
                  : null}
              </Card.Content>
            </Card>
          </div>
        )}

        <div className="field">
          <label>Anrede</label>
          <Select
            name="client-salutation"
            value={getFieldValue('sex', props.client, props.changes)}
            placeholder="Ansprache"
            options={options}
            onChange={e => {
              const _target = {
                target: {
                  name: 'sex',
                  value: e ? e.value : ''
                }
              };
              props.handleChange(_target);
              setTimeout(() => {
                props.handleBlur(_target);
              }, 40);
            }}
          />
        </div>

        <DetailField {...props} label="Name" field="fullName" />
        <DetailField {...props} label="Adresse" field="address" />
        <DetailField {...props} label="PLZ" field="postalCode" />
        <DetailField {...props} label="Ort" field="town" />
        <DetailField {...props} label="Tel" field="phone" />
        <DetailField {...props} label="Mobile" field="mobile" />
        <DetailField {...props} label="E-Mail" field="email" />

        <div className="field">
          <label>Geburtsdatum</label>
          <DatePicker
            customInput={
              <DatepickerButton
                fluid
                value={
                  birthDatePlain
                    ? moment(birthDatePlain).format('DD.MM.YYYY')
                    : 'keine Angaben'
                }
              />
            }
            value={
              birthDatePlain
                ? moment(birthDatePlain).format('DD.MM.YYYY')
                : 'keine Angaben'
            }
            selected={birthDate}
            dateFormat="DD.MM.YYYY"
            showYearDropdown
            onChange={date => {
              const _target = {
                target: {
                  name: 'birthDate',
                  value: date.format('YYYY-MM-DD')
                }
              };
              props.handleChange(_target);
              setTimeout(function() {
                props.handleBlur(_target);
              }, 1);
            }}
          />
        </div>
        {!props.client && (
          <Button
            primary
            fluid
            disabled={
              props.mode === 'creating' || _.keys(props.changes).length === 0
            }
            onClick={props.handleCreate}
            loading={props.mode === 'creating'}
            content="Erstellen"
            icon="plus"
          />
        )}
      </Form>
    </div>
  );
};

export default DetailsPane;
