import React from "react";
import { Button, Card } from "semantic-ui-react"
import { Link } from "react-router-dom"
import CardFooterButtons from "../../components/CardFooterButtons"
import moment from "moment"
import _ from "lodash"


const ExpiringMemberships = (props) => (
		<Card.Group>
			{
				_.map(
					props.expiringMemberships,
					client => (
						<Card key={client.id}>
							<Card.Content>
								<Card.Header>
									<Link to={`/clients/${client.id}`}>
										{client.fullName}
									</Link>
								</Card.Header>
								<Card.Content>
									{client.membership.title}
								</Card.Content>
								<Card.Description>
									<strong>Läuft ab am
										{" "}
										{moment(client.membership.endDate)
											.format("DD.MM.YY")}
									</strong>
								</Card.Description>
								<Card.Description>
									<a
										href={`${props.baseHREF}pdf/membershipreminder/${client.id}`}
									>
										PDF herunterladen
									</a>
									<br/>
									{
										client.membership.reminderSent ?
										<div>
											<span style={{display: "block"}}>
												Reminder versendet am {
													moment(
														client.membership.reminderSent
													).format("DD.MM.YY")
																	  }
											</span>
											{
												client.membership.refNumber ?
													<span>Referenznummer: {("00000" + client.membership.refNumber).slice(-6)}</span>
												:
													<span>Es wurde noch kein PDF heruntergeladen</span>
											}
										</div>
										: null
									}
								</Card.Description>
							</Card.Content>
							<CardFooterButtons>
								<Button
									compact
									onClick={
										e => props.remindClientMembership(
											client.membership.id
										)
											}
									icon="alarm"
									content={
										`${client.membership.reminderSent ?
										  "Erneut" : "Reminder"} als versendet markieren`
									}
								/>
							</CardFooterButtons>
							<CardFooterButtons>
								<Button
									compact
									onClick={e => props.renewClientMembership(client)}
									icon="repeat"
									content="Abo verlängern"
								/>
							</CardFooterButtons>
						</Card>
					)
				)
			}
		</Card.Group>
)

export default ExpiringMemberships
