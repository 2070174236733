import request from "superagent";
import _ from "lodash";

import {
  REQUEST_TOKEN,
  RECEIVE_TOKEN,
  requestLogout,
  REQUEST_LOGOUT,
  RECEIVE_LOGOUT,
  SET_CLIENT,
  SET_CLIENT_RESPONSE
} from "../actions/app";

import {
  MEMBERSHIPS_EXPIRING_REQUEST,
  MEMBERSHIPS_EXPIRING_RESPONSE,
  MEDIA_RESERVATIONS_REQUEST,
  MEDIA_RESERVATIONS_RESPONSE,
  TRANSACTIONS_REQUEST,
  TRANSACTIONS_RESPONSE,
  OPENTRANSACTIONS_REQUEST,
  OPENTRANSACTIONS_RESPONSE,
  INVOICES_REQUEST,
  INVOICES_RESPONSE,
  RENTAL_DELETE_REQUEST,
  RENTAL_DELETE_RESPONSE,
  REMIND_MEMBERSHIP_REQUEST,
  REMIND_MEMBERSHIP_RESPONSE,
  REMIND_RESERVATION_REQUEST
} from "../actions/dashboard";

import {
  ADD_BORROWABLE_NOTE_REQUEST,
  ADD_BORROWABLE_NOTE_RESPONSE,
  REQUEST_BORROWABLE_CREATE,
  REQUEST_BORROWABLE_DELETE,
  REQUEST_BORROWABLE_DELETE_RESPONSE,
  REQUEST_BORROWABLE_EDIT,
  REQUEST_BORROWABLE,
  RECEIVE_BORROWABLE,
  REQUEST_BORROWABLES,
  RECEIVE_BORROWABLES,
  REQUEST_MEDIA_EDIT,
  RECEIVE_MEDIA_EDIT,
  REQUEST_MEDIA_CREATE,
  REQUEST_MEDIA_DELETE,
  RECEIVE_MEDIA_DELETE,
  REQUEST_MOTIONPICTURE_VALIDATION,
  REQUEST_BORROWABLE_DELETE_NOTE,
  RESPONSE_BORROWABLE_DELETE_NOTE,
  LOAD_ALL_MEDIA_RENTALS_REQUEST,
  LOAD_ALL_MEDIA_RENTALS_RESPONSE
} from "../actions/movies";

import {
  REQUEST_CLIENT,
  RECEIVE_CLIENT,
  REQUEST_CLIENTS,
  RECEIVE_CLIENTS,
  CREATE_CLIENT_REQUEST,
  CREATE_CLIENT_RESPONSE,
  UPDATE_CLIENT_REQUEST,
  UPDATE_CLIENT_RESPONSE,
  ADD_CLIENT_NOTE_REQUEST,
  ADD_CLIENT_NOTE_RESPONSE,
  DELETE_CLIENT_NOTE_REQUEST,
  DELETE_CLIENT_NOTE_RESPONSE,
  LOAD_ALL_CLIENT_RENTALS_REQUEST,
  LOAD_ALL_CLIENT_RENTALS_RESPONSE
} from "../actions/clients";

import { CHECKOUT, CHECKOUT_RESPONSE } from "../actions/basket";

import { INVOICE_REQUEST, INVOICE_RESPONSE } from "../actions/cashRegister";

import {
  ADD_RETURN_REQUEST,
  ADD_RETURN_RESPONSE,
  RENTED_REQUEST,
  RENTED_RESPONSE,
  SET_REMINDED_DATE_REQUEST,
  RENTAL_RECEIVE
} from "../actions/returns";

// this will be overwritten by the default state in index.js
let apiUrl = "";

const uri = encodeURIComponent;

// The callback used for all request types
const handleCallback = (dispatch, callback, errorCallback) => (err, res) => {
  if (err || !res.ok) {
    if (errorCallback) {
      errorCallback(err, res);
      return;
    }

    // Automatically logout if we get an unauthorized error
    if ((err && err.status === 403) || (res && res.status === 403)) {
      dispatch(requestLogout());
    }
    return;
  }
  callback(res.body);
};

const baseGet = (dispatch, url, callback, errorCallback) => {
  if (
    process.env &&
    process.env.NODE_ENV &&
    process.env.NODE_ENV !== "development"
  ) {
    request
      .get(apiUrl + url)
      .withCredentials()
      .set("Cache-Control", "no-cache,no-store,no-transform")
      .end(handleCallback(dispatch, callback, errorCallback));
  } else {
    request
      .get(apiUrl + url)
      .withCredentials()
      .end(handleCallback(dispatch, callback, errorCallback));
  }
};

const basePost = (dispatch, url, data, callback, errorCallback) => {
  request
    .post(apiUrl + url)
    .withCredentials()
    .send(data)
    .end(handleCallback(dispatch, callback, errorCallback));
};

const basePut = (dispatch, url, data, callback, errorCallback) => {
  request
    .put(apiUrl + url)
    .withCredentials()
    .send(data)
    .end(handleCallback(dispatch, callback, errorCallback));
};

const baseDel = (dispatch, url, callback, errorCallback) => {
  request
    .delete(apiUrl + url)
    .withCredentials()
    .end(handleCallback(dispatch, callback, errorCallback));
};

// Borrowable search function
const requestBorrowables = _.debounce((get, next, action) => {
  get(
    `borrowable?q=${uri(JSON.stringify(action.query))}&p=${uri(action.page)}`,
    data => {
      next({
        type: RECEIVE_BORROWABLES,
        query: action.query,
        page: action.page,
        data: data
      });
    }
  );
}, 200);

const api = ({ dispatch, getState }) => next => action => {
  apiUrl = getState().app.baseHREF + "api/";

  next(action);

  const get = baseGet.bind(this, dispatch);
  const post = basePost.bind(this, dispatch);
  const put = basePut.bind(this, dispatch);
  const del = baseDel.bind(this, dispatch);

  switch (action.type) {
    case REQUEST_BORROWABLES:
      requestBorrowables(get, next, action);
      break;

    case REQUEST_BORROWABLE:
      get(`borrowable/${uri(action.id)}`, data => {
        next({
          type: RECEIVE_BORROWABLE,
          id: action.id,
          data: data
        });
      });
      break;

    case REQUEST_BORROWABLE_CREATE:
      post("borrowable", action.data, data => {
        next({
          type: RECEIVE_BORROWABLE,
          id: data.id,
          data: data
        });
      });
      break;

    case REQUEST_BORROWABLE_DELETE:
      del(
        `borrowable/${uri(
          action.borrowableId
        )}?replaceWith=${uri(action.replaceWith || "")}`,
        data => {
          next({
            type: REQUEST_BORROWABLE_DELETE_RESPONSE,
            data: data
          });
          const {replaced} = data;
          if (replaced) {
            dispatch({
              type: RECEIVE_BORROWABLE,
              id: replaced.id,
              data: replaced
            });
          }
        }
      );
      break;

    case REQUEST_BORROWABLE_EDIT:
      put(`borrowable/${uri(action.borrowableId)}`, action.data, data => {
        next({
          type: RECEIVE_BORROWABLE,
          id: data.id,
          data: data
        });
      });
      break;

    case ADD_BORROWABLE_NOTE_REQUEST:
      if (!action.content) return;
      post(
        `borrowable/${uri(action.borrowableId)}/note`,
        {
          content: action.content
        },
        data => {
          next({
            type: ADD_BORROWABLE_NOTE_RESPONSE,
            data: data
          });
        }
      );
      break;

    case LOAD_ALL_MEDIA_RENTALS_REQUEST:
      get(`media/${uri(action.media.id)}`, data => {
        next({
          type: LOAD_ALL_MEDIA_RENTALS_RESPONSE,
          data: data
        });
      });
      break;

    // --- Clients -------------------------------------------------------
    case REQUEST_CLIENTS:
      get(
        `client?q=${uri(
          action.query
        )}&s=${uri(
          action.sorting
        )}&p=${uri(
          action.page
        )}`,
        data => {
          next({
            type: RECEIVE_CLIENTS,
            query: action.query,
            page: action.page,
            data: data
          });
        }
      );
      break;

    case REQUEST_CLIENT:
      get(`client/${uri(action.id)}`, data => {
        next({
          type: RECEIVE_CLIENT,
          data: data
        });
      });
      break;

    case CREATE_CLIENT_REQUEST:
      post("client", action.fields, data => {
        next({
          type: CREATE_CLIENT_RESPONSE,
          data: data
        });
      });
      break;

    case UPDATE_CLIENT_REQUEST:
      put(`client/${uri(action.clientId)}`, action.fields, data => {
        next({
          type: UPDATE_CLIENT_RESPONSE,
          data: data
        });
      });
      break;

    case ADD_CLIENT_NOTE_REQUEST:
      if (!action.content) return;

      post(
        `client/${uri(action.clientId)}/note`,
        {
          content: action.content
        },
        data => {
          next({
            type: ADD_CLIENT_NOTE_RESPONSE,
            data: data
          });
        }
      );
      break;

    case DELETE_CLIENT_NOTE_REQUEST:
      if (!action.noteId) return;

      del(`client/${uri(action.clientId)}/note/${uri(action.noteId)}`, data => {
        next({
          type: DELETE_CLIENT_NOTE_RESPONSE,
          data: data
        });
      });
      break;

    case LOAD_ALL_CLIENT_RENTALS_REQUEST:
      get(`client/${uri(action.client.id)}/rental`, data => {
        next({
          type: LOAD_ALL_CLIENT_RENTALS_RESPONSE,
          data: data
        });
      });
      break;

    case REQUEST_TOKEN:
      post(
        "login",
        {
          username: action.username,
          password: action.password
        },
        data => {
          next({
            type: RECEIVE_TOKEN,
            data: data
          });
        },
        (err, res) => {
          next({
            type: RECEIVE_TOKEN,
            isAuto: _.isEmpty(action.username) && _.isEmpty(action.password),
            error: err ? (err.status ? err.status : "err") : res.status
          });
        }
      );
      break;

    case REQUEST_LOGOUT:
      post(
        "logout",
        {},
        data => {
          next({
            type: RECEIVE_LOGOUT
          });
        },
        (err, res) => {
          // If the logout request failed we can assume we're already logged out
          next({
            type: RECEIVE_LOGOUT
          });
        }
      );
      break;

    case CHECKOUT:
      const basket = _.assign({}, getState().basket, {
        client: getState().app.client
      });
      post("checkout", basket, data => {
        next({
          type: CHECKOUT_RESPONSE,
          data: data
        });
      });
      break;

    case SET_CLIENT:
      if (action.client) {
        get(`client/${uri(action.client.id)}`, data => {
          next({
            type: SET_CLIENT_RESPONSE,
            data: data
          });
        });
      }
      break;

    case INVOICE_REQUEST:
      if (action.invoiceId) {
        if (action.data && action.data.delete) {
          del(
            `client/${uri(action.clientId)}/invoice/${uri(action.invoiceId)}`,
            data => {
              next({
                type: SET_CLIENT_RESPONSE,
                data: data
              });
              next({
                type: INVOICE_RESPONSE
              });
            }
          );
        } else {
          put(
            `client/${uri(action.clientId)}/invoice/${uri(action.invoiceId)}`,
            action.data,
            data => {
              next({
                type: SET_CLIENT_RESPONSE,
                data: data
              });
              next({
                type: INVOICE_RESPONSE
              });
            }
          );
        }
      } else {
        post(`client/${uri(action.clientId)}/invoice`, action.data, data => {
          next({
            type: SET_CLIENT_RESPONSE,
            data: data
          });
          next({
            type: INVOICE_RESPONSE
          });
        });
      }
      break;

    case ADD_RETURN_REQUEST:
      put(
        "mediaRental/return",
        {
          rentalsIds: action.rentalsIds,
          date: action.date,
          undo: action.undo
        },
        data => {
          next({
            type: ADD_RETURN_RESPONSE,
            data: data
          });
        }
      );
      break;

    case RENTED_REQUEST:
      get(
        `media/rented?client=${uri(
          action.clientId
        )}&p=${uri(
          action.page ? action.page : 0
        )}&q=${uri(
          action.query ? action.query : ""
        )}`,
        data => {
          next({
            type: RENTED_RESPONSE,
            data: data
          });
        }
      );
      break;

    case SET_REMINDED_DATE_REQUEST:
      put(`mediaRental/${uri(action.rentalId)}/remindedDate`, {}, data => {
        next({
          type: RENTAL_RECEIVE,
          data: data
        });
      });
      break;

    case REQUEST_MEDIA_EDIT:
      put(
        `media/${uri(action.mediaId)}`,
        {
          data: action.data,
          borrowableId: action.borrowableId
        },
        data => {
          next({
            type: RECEIVE_MEDIA_EDIT,
            data: data
          });
        }
      );
      break;

    case REQUEST_MEDIA_CREATE:
      post(
        "media",
        {
          data: action.data,
          borrowableId: action.borrowableId
        },
        data => {
          next({
            type: RECEIVE_MEDIA_EDIT,
            data: data
          });
        }
      );
      break;

    case REQUEST_MEDIA_DELETE:
      del(`media/${uri(action.id)}`, data => {
        next({
          type: RECEIVE_MEDIA_DELETE,
          data: data
        });
      });
      break;

    case REQUEST_MOTIONPICTURE_VALIDATION:
      if (action.method === "post") {
        if (!action.borrowableId) {
          // we want to create a new borrowable
          post("borrowable/motionPicture", { tmdb: action.data }, data => {
            next({
              type: RECEIVE_BORROWABLE,
              id: data.id,
              data: data
            });
          });
        } else {
          // we want to link a tmbd movie to an existing borrowable
          post(
            `borrowable/${uri(action.borrowableId)}/motionPicture`,
            { tmdb: action.data },
            data => {
              next({
                type: RECEIVE_BORROWABLE,
                id: data.id,
                data: data
              });
            }
          );
        }
      } else if (action.method === "put") {
        put(
          `borrowable/${uri(action.borrowableId)}/motionPicture/${uri(action.mpId)}`,
          {},
          data => {
            next({
              type: RECEIVE_BORROWABLE,
              id: data.id,
              data: data
            });
          }
        );
      } else if (action.method === "delete") {
        del(
          `borrowable/${uri(action.borrowableId)}/motionPicture/${uri(action.mpId)}`,
          data => {
            next({
              type: RECEIVE_BORROWABLE,
              id: data.id,
              data: data
            });
          }
        );
      }
      break;

    case REQUEST_BORROWABLE_DELETE_NOTE:
      if (!action.noteId || !action.borrowableId) return;

      del(
        `borrowable/${uri(action.borrowableId)}/note/${uri(action.noteId)}`,
        data => {
          next({
            type: RESPONSE_BORROWABLE_DELETE_NOTE,
            data: data
          });
        }
      );
      break;

    case MEMBERSHIPS_EXPIRING_REQUEST:
      get("membership", data => {
        next({
          type: MEMBERSHIPS_EXPIRING_RESPONSE,
          data: data
        });
      });
      break;

    case MEDIA_RESERVATIONS_REQUEST:
      get("mediaRental/reservations", data => {
        next({
          type: MEDIA_RESERVATIONS_RESPONSE,
          data: data
        });
      });
      break;

    case TRANSACTIONS_REQUEST:
      get(`transactions?date=${uri(action.date)}`, data => {
        next({
          type: TRANSACTIONS_RESPONSE,
          data: data
        });
      });
      break;

    case OPENTRANSACTIONS_REQUEST:
      get("openTransactions", data => {
        next({
          type: OPENTRANSACTIONS_RESPONSE,
          data: data
        });
      });
      break;

    case INVOICES_REQUEST:
      get("invoice", data => {
        next({
          type: INVOICES_RESPONSE,
          data: data
        });
      });
      break;

    case RENTAL_DELETE_REQUEST:
      del(`mediaRental/${uri(action.rentalId)}`, data => {
        next({
          type: RENTAL_DELETE_RESPONSE,
          data: data
        });
      });
      break;

    case REMIND_MEMBERSHIP_REQUEST:
      put(`remindmembership/${uri(action.membershipId)}`, {}, data => {
        next({
          type: REMIND_MEMBERSHIP_RESPONSE,
          data: data
        });
      });
      break;

    case REMIND_RESERVATION_REQUEST:
      put(`mediaRental/${uri(action.reservationId)}/remindedDate`, {}, data => {
        next({
          type: RENTAL_RECEIVE,
          data: data
        });
      });
      break;

    default:
      break;
  }
};

export default api;
