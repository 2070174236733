import { push, replace, LOCATION_CHANGE } from "react-router-redux"
import _ from "lodash"

import {
	RECEIVE_TOKEN,
	RECEIVE_LOGOUT,
	setClient,
} from "../actions/app"

import {
	CLEAR_BASKET,
} from "../actions/basket"

import {
	RECEIVE_CLIENT,
	RECEIVE_CLIENTS,
	SET_CLIENT_DETAILS,
	requestClient,
	setClientDetails,
} from "../actions/clients"

import {
	RECEIVE_BORROWABLES,
	RECEIVE_BORROWABLE,
	CLOSE_BORROWABLE,
	SET_MOVIE_DETAILS,
	REQUEST_BORROWABLE_DELETE_RESPONSE,
	requestBorrowable,
} from "../actions/movies"

import {
	requestRented,
} from "../actions/returns"

let prevPath = null;

const routing = ({ dispatch, getState }) => next => action => {
	next(action)

	const state = getState();

	switch (action.type) {
		case LOCATION_CHANGE:
			// If the url didn't change do nothing
			if (prevPath === action.payload.pathname)
				break;

			prevPath = action.payload.pathname
			const paths = _.slice(_.split(action.payload.pathname, "/"), 1);

			// Switch by page type
			if (paths[0] === "clients") {
				// Check the second argument
				if (paths.length > 0 && !_.isEmpty(paths[1])) {
					// We have an argument after /clients/, either "new" or a client id
					if (paths[1] === "new") {
						// If the state already shows the details as open and in new mode
						// then we have no reason to change it
						if (state.clients.details.open && state.clients.details.isNew)
							break;
						next(setClientDetails({
							open: true,
							isNew: true,
						}));
					} else {
						// Check if the client id in the url is different from the one
						// in the state. If yes then this is probably a page load with
						// a deep link, so restore the state by fetching the client
						const id = parseInt(paths[1], 10);
						if (state.clients.client && state.clients.client.id === id)
							break;
						dispatch(requestClient(id))
					}
				} else if (state.clients.client) {
					// We don't have an argument, but we already have a movie in the state
					// so we only have to update the url to restore the previous state
					next(replace("/clients/" + state.clients.client.id))
				} else if (state.clients.details.open && state.clients.details.isNew) {
					// If the details mode was open and in new mode, then restore
					// that url when going back to it
					next(replace("/clients/new"))
				}
			} else if (paths[0] === "movies") {
				// Check the second argument
				if (paths.length > 0 && !_.isEmpty(paths[1])) {
					// We have an argument after /movies/, the movie id
					// Check if the movie id in the url is different from the one
					// in the state. If yes then this is probably a page load with
					// a deep link, so restore the state by fetching the movie
					const id = parseInt(paths[1], 10);
					if (state.movies.borrowable && state.movies.borrowable.id === id)
						break;
					dispatch(requestBorrowable(id))
				} else if (state.movies.borrowable) {
					next(replace("/movies/" + state.movies.borrowable.id))
				}
			} else if (paths[0] === "returns") {
				if(
					!(state.returns.medias && state.returns.medias.length) ||
					state.returns.needsReload
				) {
					dispatch(requestRented(
						(state.app.client ? state.app.client.id : null),
						(state.returns.query ? state.returns.query : null),
						(state.returns.page ? state.returns.page : null)
					))
				}
			} else if (paths[0] === "cash-register") {
				if (state.app.client && state.cashRegister.needsReload) {
					dispatch(setClient(state.app.client))
				}
			}
			break;

		case RECEIVE_CLIENT:
			if (prevPath !== "/clients/" + action.data.id) {
				next(push("/clients/" + action.data.id))
			}
			break;

		case RECEIVE_CLIENTS:
			if (!_.startsWith(prevPath, "/clients")) {
				next(push("/clients"))
			}
			break;

		case SET_CLIENT_DETAILS:
			if (action.details.isNew) {
				next(push("/clients/new"))
			}
			break;

		case RECEIVE_BORROWABLE:
			if(!action.error) {
				if (prevPath !== "/movies/" + action.id) {
					next(push("/movies/" + action.id))
				}
			}
			break;

		case CLOSE_BORROWABLE:
			next(push("/movies"))
			break;

		case REQUEST_BORROWABLE_DELETE_RESPONSE:
			next(push("/movies"))
			break;

		case RECEIVE_BORROWABLES:
			if (!_.startsWith(prevPath, "/movies")) {
				next(push("/movies"))
			}
			break;

		case SET_MOVIE_DETAILS:
			if (!action.details.open) {
				next(push("/movies"))
				return;
			}
			break;

		case RECEIVE_TOKEN:
			if (action.error) {
				next(replace("/login"))
			} else {
				// Trigger a re-render of the route after we received the base data
				next(replace(prevPath))
			}
			break;

		// Trigger a re-render of the route after we received the base data
		case RECEIVE_LOGOUT:
			next(push("/login"))
			break;

		// change to cash register when clearing basket
		case CLEAR_BASKET:
			if (action.gotoCashRegister) next(push("/cash-register"))
			break;

		default:
			break;
	}
}

export default routing
