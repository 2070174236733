import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux"
import { createStore, applyMiddleware, compose } from "redux"
import { Route, Switch, Redirect } from "react-router-dom"
import { ConnectedRouter, routerMiddleware } from "react-router-redux"
import { createBrowserHistory } from "history"
import "moment/locale/de"
import { injectGlobal } from "styled-components"

import "./semantic/public/css/semantic.css"


// Reducers
import reducers from "./reducers"

// Containers
import Full from "./containers/Full/Full"
import Login from "./containers/Login/Login"

// Services
import app from "./services/app"
import api from "./services/api"
import routing from "./services/routing"

import { requestToken } from "./actions/app"

// Styles
import "react-select/dist/react-select.css"
import "react-datepicker/dist/react-datepicker.css"

// History
const history = createBrowserHistory({
	basename: "/app",
});

// Store
const defaultState = {
	app: {
		baseHREF:
				 window.location.hostname.indexOf("localhost") === 0 ||
				 window.location.hostname.indexOf("192.168.1.0") === 0 ?
				 "http://lesvideos.localhost/" : "/",
	}
}
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(reducers, defaultState, composeEnhancers(
	applyMiddleware(api, app, routing, routerMiddleware(history)),
));

store.dispatch(requestToken());

// global styled css
injectGlobal`
body {
	overflow: hidden;
}
#root {
	height: 100%;
}
.react-datepicker__input-container {
	display: block;
}
.Select {

	& .Select-control {
		border-radius: 0!important;

		.Select-input > input {
			border: none!important;
			padding: 0.6em 0.1em!important;
		}
	}

	& .Select-option {
		&.is-selected, &.is-focused {
			background-color: #96c8da;
		}
	}

	&.is-focused:not(.is-open) > .Select-control {
		box-shadow: none;
		border-color: #96c8da;
	}

	&.is-open {
		& > .Select-control {
			box-shadow: none;
			border-color: #96c8da;
		}

		& > .Select-menu-outer {
			box-shadow: none;
			border-color: #96c8da;
			border-radius: 0;
		}
	}

	&.tight {
		margin: -1px;
	}
}

a {
	&.link-on-dark {
		background: rgba(255, 255, 255, 0.4);
		border-radius: 7px;
		padding: 0.2em;
	}
}
.react-datepicker__week {
	white-space: nowrap;
}
`

ReactDOM.render((
	<React.StrictMode>
		<Provider store={store}>
			<ConnectedRouter history={history} basename="/app">
				<Switch>
					<Route path="/login" name="Login" component={Login} />
					<Route path="/" name="Home" render={props => {
							if (store.getState().app.user) {
								return <Full {...props} />
							} else if (store.getState().app.ready) {
								return <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
							} else {
								// TODO: Show some loading container here
								return null
							}
					}} />
				</Switch>
			</ConnectedRouter>
		</Provider>
	</React.StrictMode>
), document.getElementById("root"))
