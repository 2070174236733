import React from "react"
import { Card, Grid, Radio } from "semantic-ui-react"

import { FormatMoney, FormatDateTime } from "../../components/Util"

const Transaction = (props) => {
	const { transaction, onTransactionSelection } = props;
	const checkboxId = `transaction-checkbox-${transaction.id}`

	return <Card.Content>
		<Grid verticalAlign="middle">
			<Grid.Column width={4}>
				<FormatDateTime rawDate={transaction.created} />
			</Grid.Column>
			<Grid.Column width={8}>
				{transaction.note}
			</Grid.Column>
			<Grid.Column width={2} textAlign="right">
				{FormatMoney(-transaction.amount)}
			</Grid.Column>
			<Grid.Column width={2} textAlign="right">
				<Radio
					size="tiny"
					toggle
					name={checkboxId}
					checked={transaction.checked}
					onChange={(e, { checked }) => onTransactionSelection(checked, transaction.id)}
				/>
			</Grid.Column>
		</Grid>
	</Card.Content>
}

export default Transaction
