export const CLOSE_BORROWABLE = "CLOSE_BORROWABLE"
export const REQUEST_BORROWABLE = "REQUEST_BORROWABLE"
export const RECEIVE_BORROWABLE = "RECEIVE_BORROWABLE"
export const REQUEST_BORROWABLES = "REQUEST_BORROWABLES"
export const RECEIVE_BORROWABLES = "RECEIVE_BORROWABLES"
export const REQUEST_BORROWABLE_CREATE = "REQUEST_BORROWABLE_CREATE"
export const REQUEST_BORROWABLE_DELETE = "REQUEST_BORROWABLE_DELETE"
export const REQUEST_BORROWABLE_DELETE_RESPONSE = "REQUEST_BORROWABLE_DELETE_RESPONSE"
export const REQUEST_BORROWABLE_EDIT = "REQUEST_BORROWABLE_EDIT"

export function requestBorrowable(id) {
	return {
		type: REQUEST_BORROWABLE,
		id: id,
	}
}
export function requestBorrowables(query = {}, page = 0) {
	return {
		type: REQUEST_BORROWABLES,
		query: query,
		page: page,
	}
}
export function closeBorrowable() {
	return {
		type: CLOSE_BORROWABLE,
	}
}
export function createBorrowable(data) {
	return {
		type: REQUEST_BORROWABLE_CREATE,
		data: data,
	}
}
export function deleteBorrowable(borrowableId, replaceWith) {
	return {
		type: REQUEST_BORROWABLE_DELETE,
		borrowableId: borrowableId,
		replaceWith: replaceWith
	}
}
export function editBorrowable(data, borrowableId) {
	return {
		type: REQUEST_BORROWABLE_EDIT,
		data: data,
		borrowableId: borrowableId,
	}
}

export const ADD_BORROWABLE_NOTE_REQUEST = "ADD_BORROWABLE_NOTE_REQUEST"
export const ADD_BORROWABLE_NOTE_RESPONSE = "ADD_BORROWABLE_NOTE_RESPONSE"
export function requestAddBorrowableNote(borrowableId, content) {
	return {
		type: ADD_BORROWABLE_NOTE_REQUEST,
		borrowableId: borrowableId,
		content: content,
	}
}

export const SET_MOVIE_DETAILS = "SET_MOVIE_DETAILS"
export function setMovieDetails(details) {
	return {
		type: SET_MOVIE_DETAILS,
		details,
	}
}

export const REQUEST_MEDIA_EDIT = "REQUEST_MEDIA_EDIT"
export const RECEIVE_MEDIA_EDIT = "RECEIVE_MEDIA_EDIT"
export function editMedia(data, borrowableId, mediaId) {
	return {
		type: REQUEST_MEDIA_EDIT,
		mediaId: mediaId,
		data: data,
		borrowableId: borrowableId,
	}
}

export const REQUEST_MEDIA_CREATE = "RECEIVE_MEDIA_CREATE"
export function createMedia(data, borrowableId) {
	return {
		type: REQUEST_MEDIA_CREATE,
		data: data,
		borrowableId: borrowableId,
	}
}

export const REQUEST_MEDIA_DELETE = "REQUEST_MEDIA_DELETE"
export const RECEIVE_MEDIA_DELETE = "RECEIVE_MEDIA_DELETE"
export function deleteMedia(mediaId) {
	return {
		type: REQUEST_MEDIA_DELETE,
		id: mediaId,
	}
}

export const REQUEST_MOTIONPICTURE_VALIDATION = "REQUEST_MOTIONPICTURE_VALIDATION"
export function motionPictureValidation(borrowableId, method, data, mpId) {
	return {
		type: REQUEST_MOTIONPICTURE_VALIDATION,
		borrowableId: borrowableId,
		method: method,
		data: data,
		mpId: mpId,
	}
}

export const REQUEST_BORROWABLE_DELETE_NOTE = "REQUEST_BORROWABLE_DELETE_NOTE"
export const RESPONSE_BORROWABLE_DELETE_NOTE = "RESPONSE_BORROWABLE_DELETE_NOTE"
export function deleteBorrowableNote(borrowableId, noteId) {
	return {
		type: REQUEST_BORROWABLE_DELETE_NOTE,
		borrowableId: borrowableId,
		noteId: noteId,
	}
}

export const LOAD_ALL_MEDIA_RENTALS_REQUEST = "LOAD_ALL_MEDIA_RENTALS_REQUEST"
export const LOAD_ALL_MEDIA_RENTALS_RESPONSE = "LOAD_ALL_MEDIA_RENTALS_RESPONSE"
export function loadAllRentals(media) {
	return {
		type: LOAD_ALL_MEDIA_RENTALS_REQUEST,
		media: media,
	}
}
