import React from "react"
import { Card, Grid, Button, Icon, Label, Form } from "semantic-ui-react"
import DatePicker from "react-datepicker"
import { Link } from "react-router-dom"
import _ from "lodash"

import Status from "../../components/Util/Status"
import { FormatMoney, FormatDate } from "../../components/Util"
import DatepickerButton from "../../components/Util/DatepickerButton"
import MediaMetaInfos from "../../components/BorrowableDetails/MediaMetaInfos"
const Rental = (props) => {
	const {
		mode, alreadyRented, rental, priceCategories,
		onSetEntryDate, onSetRentalDuration, onPriceCatChange, calcRentalPrice, onRemove
	} = props;

	return <Card.Content>
		{alreadyRented > 0 ?
			<Label ribbon color="pink" content={`Bereits ${alreadyRented} mal ausgeliehen`} />
		: null}

		<Grid columns="equal">
			<Grid.Column width={6}>
				<Link to={`/movies/${rental.borrowable.id}`}>
					<strong><Icon name="film" /> {rental.media.filmcode}</strong>
				</Link>
				<MediaMetaInfos media={rental.media} borrowable={rental.borrowable} />
				<br/>
				{rental.borrowable.customTitle}
			</Grid.Column>
			<Grid.Column width={2}>
				{mode === "ack" ?
					<FormatDate rawDate={rental.date}/>
				:
					<DatePicker
						customInput={<DatepickerButton fluid />}
						dateFormat="DD.MM.YYYY"
						selected={rental.date}
						onChange={(date) => onSetEntryDate(date, rental)}
					/>
				}
			</Grid.Column>
			<Grid.Column width={2}>
				{mode === "ack" ?
					<span>{rental.days} Tag{rental.days > 1 ? "(e)" : null}</span>
				:
					<Form.Select
						fluid
						options={_.map(_.range(rental.priceCat.duration, 40),
							n => ({key: n, value: n, text: `${n} Tag${n > 1 ? "e" : ""}`})
						)}
						value={rental.duration}
						onChange={(e, select) => onSetRentalDuration(e, select, rental)}
					/>
				}
			</Grid.Column>
			<Grid.Column width={2}>
				{mode === "ack" ?
					<span>
						{rental.priceCat.title} {rental.priceCatGroup}
					</span>
				:
					<Form.Select
						fluid
						value={`${rental.priceCat.id}-${rental.priceCatGroup ? rental.priceCatGroup : ""}`}
						options={priceCategories}
						onChange={(e, select) => {onPriceCatChange(e, select, rental)}}
					/>
				}
			</Grid.Column>
			<Grid.Column textAlign="right" verticalAlign="middle" width={2}>
				{mode === "ack" ?
					FormatMoney(rental.price)
					: rental.isSlave ?
					<span>-.--</span>
				:
					FormatMoney(calcRentalPrice(rental))
				}
			</Grid.Column>
			<Grid.Column textAlign="right" verticalAlign="middle" width={2}>
				{mode === "ack" ?
					<Status
						status={rental.status}
						message={rental.errorMessage}
					/>
				:
					<div>
						<Button
							as={Link}
							to={`/movies/${rental.borrowable.id}`}
							icon="info"
							circular
						/>
						<Button
							onClick={e => onRemove(rental.media)}
							icon="trash"
							circular
						/>
					</div>
				}
			</Grid.Column>
		</Grid>
	</Card.Content>
}

export default Rental
