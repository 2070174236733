import React from "react"
import { Input, Grid, Form, } from "semantic-ui-react"

const SearchHeader = (props) => {

	return <Grid columns="equal">
		<Grid.Row>
			<Grid.Column width={16}>
				<Form.Field>
					<label>Suche nach Name, Adresse, PLZ, Nr, etc.</label>
					<Input
						fluid
						onChange={props.handleQueryChange}
						value={props.query}
						name="query"
					/>
				</Form.Field>
			</Grid.Column>
		</Grid.Row>
	</Grid>
}

/*
THIS MAY BE NEEDED SOMEWHEN
<Grid.Column width={8}>
<Form.Field>
<label>Sortierung</label>
<Form
	.Select
fluid
options={[
	{key: "alpha", text: "Alphabetisch", value: "alpha"},
	{key: "membership", text: "Mitgliedschaft", value: "membership"},
	{key: "balance", text: "Kontostand", value: "balance"},
]}
value={props.sorting}
onChange={ (e, select) => {
	props.handleQueryChange()}
}
name="sorting"
/>
</Form.Field>
</Grid.Column>
*/

export default SearchHeader
