import React from "react"
import moment from "moment"
import { Card, Button, Grid, Form, Input } from "semantic-ui-react"
import DatePicker from "react-datepicker"

import { FormatMoney, FormatDate } from "../../components/Util"
import DatepickerButton from "../../components/Util/DatepickerButton"

const Invoice = (props) => {
	const {
		invoice,
		baseHREF,
		onPdfSentClick,
		onPdfOverdueSentClick,
		onInvoiceChange,
		onInvoiceCheckout,
		onInvoiceDelete
	} = props;
	const amountInputId = `invoice-amount-${invoice.id}`
	const overdues = invoice.overdueSent ? invoice.overdueSent.split(", ") : []

	return <Card.Content>
		<Grid verticalAlign="middle">
			<Grid.Column width={2}>
				<strong>{invoice.invoiceNumber}</strong>
			</Grid.Column>
			<Grid.Column width={2} textAlign="center">
				{invoice.sent ?
					<FormatDate rawDate={invoice.invoiceDate}/>
				:
					<DatePicker
						customInput={<DatepickerButton fluid size="small"/>}
						dateFormat="DD.MM.YYYY"
						selected={
							invoice.date ?
								  invoice.date :
								  invoice.invoiceDate ?
								  moment(invoice.invoiceDate) :
								  moment()
								 }
						onChange={(date) => {onInvoiceChange("date", date, invoice.id)}}
					/>
				}
			</Grid.Column>
			<Grid.Column width={2}>
				<a
					href={`${baseHREF}pdf/invoice/${invoice.id}`}
					rel="noopener noreferrer"
					target="_blank"
				>
					Download PDF
				</a>
				<br/>
				{
					invoice.sent ?
						<strong>Versendet am <FormatDate rawDate={invoice.sent} /></strong>
					:
					<Button
						content="Als versendet markieren"
						compact
						onClick={(e) => {onPdfSentClick(invoice.id)}}
					/>
				}
			</Grid.Column>
			<Grid.Column width={2}>
				{
					invoice.sent ?
						<div>
							<div>
								{
									invoice.overdueSent ?
									invoice.overdueSent :
									"keine Mahnung gesendet"
								}
								<br/>
								<Button
									icon="minus"
									compact
									disabled={!overdues.length}
									onClick={(e) => {onPdfOverdueSentClick(invoice.id, true)}}
								/>
								<Button
									icon="plus"
									compact
									disabled={overdues.length >= 3}
									onClick={(e) => {onPdfOverdueSentClick(invoice.id)}}
								/>
							</div>
							{
								overdues.length < 3 ?
									<a
										href={`${baseHREF}pdf/overdue/${invoice.clientId}?overdueCount=${overdues.length + 1}`}
										rel="noopener noreferrer"
										target="_blank"
									>
										{overdues.length + 1}. Mahnungs PDF
									</a>
								:
									null
							}
						</div>
					:
					null
				}
			</Grid.Column>
			<Grid.Column width={2} textAlign="right">
				{
					invoice.sent ?
					<Button
						onClick={(e) => {
								onInvoiceChange(
									"amount",
									-invoice.balance,
									invoice.id,
									amountInputId
								)
								}}
						icon="arrow right"
						labelPosition='right'
						content={FormatMoney(-invoice.balance)}
					/>
					:
					FormatMoney(-invoice.balance)
				}
			</Grid.Column>
			<Grid.Column width={1} textAlign="right">
				{
					invoice.sent &&
					<Input
						id={amountInputId}
						size="small"
						name="amount"
						fluid
						defaultValue="0.00"
						onBlur={(e) => {
								onInvoiceChange(
									"amount",
									e.target.value,
									invoice.id,
									e.target
								)
							}}
					/>
				}
			</Grid.Column>
			<Grid.Column width={2}>
				{
					invoice.sent &&
					<DatePicker
						customInput={<DatepickerButton fluid size="small"/>}
						dateFormat="DD.MM.YYYY"
						selected={invoice.paymentDate ? invoice.paymentDate : moment()}
						onChange={(date) => {onInvoiceChange("paymentDate", date, invoice.id)}}
					/>
				}
			</Grid.Column>
			<Grid.Column width={2} textAlign="right">
				{
					invoice.sent &&
					<Form
						.Select
						fluid
						value={invoice.account ? invoice.account : 0}
						onChange={(e, select) => {
								onInvoiceChange("account", select.value, invoice.id)
							}}
						options={[{
								key: 0,
								text: "Bitte wählen",
								value: 0,
							},{
								key: 1,
								text: "Kasse",
								value: "Kasse",
							},{
								key: 2,
								text: "Bankkonto",
								value: "Bankkonto",
							},]}
					/>
				}
			</Grid.Column>
			{invoice.sent ?
				<Grid.Column width={1} textAlign="right">
					<Button
						primary
						circular
						icon="send"
						loading={invoice.sending}
						disabled={invoice.sending || !invoice.amount || !invoice.account }
						onClick={e => onInvoiceCheckout(e, invoice.id)}
					/>
				</Grid.Column>
				:
				<Grid.Column width={1} textAlign="right">
					<Button
						primary
						circular
						icon="trash"
						loading={invoice.sending}
						disabled={invoice.sending}
						onClick={e => onInvoiceDelete(e, invoice.id)}
					/>
				</Grid.Column>
			}
		</Grid>
	</Card.Content>
}

export default Invoice
