import React from "react"
import _ from "lodash"
import moment from "moment"
import { Link } from "react-router-dom"
import { Card, Button, Grid } from "semantic-ui-react"

import { FormatMoney, FormatDate } from "../../components/Util"
import MediaMetaInfos from "../../components/BorrowableDetails/MediaMetaInfos"
import BorrowableFullTitle from "../../components/BorrowableDetails/BorrowableFullTitle"

const Media = (props) => {
	const {
		media, clientActive, returnDate, rentalInfo, onSetClient,
		onSetRemindedDate, onReturnClick, onReturnUndoClick
	} = props;

	return <Card fluid>
		<Card.Content>
			<Grid verticalAlign="middle">
				<Grid.Column width={3}>
					<Link to={`/movies/${media.borrowableId}`}>
						<strong>{media.filmcode}</strong>
					</Link>
					{" "}
					<MediaMetaInfos
							media={media}
							borrowable={media.borrowable}
						/>
					<br/>
					<BorrowableFullTitle borrowable={media.borrowable} />
				</Grid.Column>
				<Grid.Column width={3}>
					<Button
						onClick={(e) => onSetClient(media.rental.client)}
						disabled={clientActive ? true : false}
						icon="shopping basket"
						circular
					/>
					{" "}
					<Link to={`/clients/${media.rental.client.id}`}>
						{media.rental.client && media.rental.client.fullName}
					</Link>
					{
						(media.rental.client.membership && media.rental.client.membership.discount) ?
						<span> ({(media.rental.client.membership.discount * 100)}%) </span>
						:
						null
					}
				</Grid.Column>
				<Grid.Column width={2}>
					<Button
						fluid
						onClick={(e) => onSetRemindedDate(media.rental.id)}
						content={media.rental.remindedDate ?
							"kontaktiert " + moment(media.rental.remindedDate).format("DD.MM.YY")
						:
							"noch nicht kontaktiert"
						}
					/>
				</Grid.Column>
				{media.reservations.length ? <Grid.Column width={3} color="orange">
					Reservationen: {media.reservations.length}
					{_.map(media.reservations, reservation =>
						<div key={reservation.id}>
							<FormatDate rawDate={reservation.reservationDate}/>
							{" "}
							<Link to={`/clients/${reservation.client.id}`} className="link-on-dark">
								{reservation.client.fullName}
							</Link>
						</div>
					)}
				</Grid.Column> : undefined}
				<Grid.Column width={media.reservations.length ? 3 : 6} textAlign="right">
					{media.rental.startDate && <FormatDate rawDate={media.rental.startDate}/>}
					{(media.rental.startDate && media.rental.endDate) && " - "}
					{media.rental.endDate && <FormatDate rawDate={media.rental.endDate}/>}
					{
						rentalInfo &&
						<div style={{ display: "inline" }}>
							{rentalInfo.priceCat ? `,  ${rentalInfo.priceCat.title}` : ""}
							{
								(rentalInfo.delay) ?
								<span>
									, überfällig seit {rentalInfo.delay} Tag{rentalInfo.delay > 1 ? "en" : ""}
								</span>
								: null
							}
							<br/>
							CHF {FormatMoney(rentalInfo.amount)}
						</div>
					}
				</Grid.Column>
				<Grid.Column width={2} textAlign="right">
					{media.rental.endDate ?
						<Button
							fluid
							onClick={(e) => {onReturnUndoClick(media.rental.id, returnDate)}}
							disabled={media.requesting}
							loading={media.requesting}
							content="Rückgängig"
							icon="undo"
						/>
					:
						<Button
							fluid
							primary
							onClick={(e) => {onReturnClick(media.rental.id, returnDate)}}
							disabled={media.requesting}
							loading={media.requesting}
							content="Ok"
							icon="reply"
						/>
					}
				</Grid.Column>
			</Grid>
		</Card.Content>
	</Card>
}

export default Media
