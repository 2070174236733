import React, { Component } from "react";
import { Card, Popup, Icon } from "semantic-ui-react";
import _ from "lodash";
import moment from "moment";
import {
  Form,
  FormInput,
  Table,
  Confirm,
  Label,
  Button,
  Tab,
} from "semantic-ui-react";
import Select from "react-select";
import { Link } from "react-router-dom";

import CardFooterButtons from "../CardFooterButtons";
import { STATUS_OPTIONS } from "../../views/Movies/Borrowable";

const mediaTypeOptions = [
  {
    value: "VHS",
    label: "VHS",
  },
  {
    value: "DVD",
    label: "DVD",
  },
  {
    value: "BluRay",
    label: "Blu-Ray",
  },
  {
    value: "4K",
    label: "4K UHD Blu-Ray",
  },
  {
    value: "BluRay3D",
    label: "Blu-Ray 3D",
  },
];

export const MEDIA_TYPE_LABELS = Object.fromEntries(
  mediaTypeOptions.map(({ value, label }) => [value, label])
);

const mediaFormatOptions = [
  {
    value: "PAL",
    label: "PAL",
  },
  {
    value: "NTSC",
    label: "NTSC",
  },
];

const mediaRegionCodeOptions = [
  {
    value: "?",
    label: "Unbekannt",
  },
  {
    value: "0",
    label: "0",
  },
  {
    value: "1",
    label: "1",
  },
  {
    value: "2",
    label: "2",
  },
  {
    value: "3",
    label: "3",
  },
  {
    value: "4",
    label: "4",
  },
  {
    value: "5",
    label: "5",
  },
  {
    value: "6",
    label: "6",
  },
  {
    value: "7",
    label: "7",
  },
  {
    value: "8",
    label: "8",
  },
];

class MediaCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      changes: [],
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.loadAllRentals = this.loadAllRentals.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.media !== this.props.media) {
      this.setState({ changes: [] });
    }
  }

  handleChange(field, value) {
    const changes = this.state.changes;
    let change = _.find(changes, { field: field });
    if (!change) changes.push({ field: field, value: value });
    else change.value = value;
    this.setState({
      changes: changes,
    });
  }

  handleDelete(mode = null) {
    if (!this.props.media) return;
    if (!mode) {
      this.setState({ showDeleteModal: true });
    } else if (mode === "cancel") {
      this.setState({ showDeleteModal: false });
    } else if (mode === "ok") {
      this.props.deleteMedia(this.props.media.id);
      this.setState({ showDeleteModal: false });
    }
  }

  getFieldValue(field) {
    const change = _.find(this.state.changes, { field: field });
    if (change) return change.value;
    if (this.props.media && this.props.media[field])
      return this.props.media[field];
    return "";
  }

  handleCancel() {
    // if we have a media, simply unset changes
    this.setState({ changes: [] });
    // if we are in create mode, tell parent component
    if (this.props.media && !this.props.media.id) {
      this.props.handleCancel();
    }
  }

  handleSave() {
    this.props.editMedia(
      this.state.changes,
      this.props.borrowable.id,
      this.props.media ? this.props.media.id : null
    );
  }

  loadAllRentals() {
    this.setState({
      rentalsLoading: true,
    });
    this.props.loadAllRentals(this.props.media);
  }

  render() {
    const { media, langOptions } = this.props;

    const langs = _.filter(langOptions, (opt) => opt.value !== "withoutSub");
    const subs = _.filter(langOptions, (opt) => opt.value !== "silent");

    const panes = [
      {
        menuItem: {
          key: media.id ? media.id : "new",
          icon: "info",
          content: media.id
            ? media.filmcode
              ? media.filmcode
              : "Code unbekannt"
            : "Neues Medium",
        },
        render: () => (
          <Form>
            {media.id ? undefined : (
              <FormInput
                label="Mediencode"
                type="text"
                value={this.getFieldValue("barcode")}
                onChange={(e) => this.handleChange("barcode", e.target.value)}
              />
            )}
            <Form.Field>
              <label>Status</label>
              <Select
                placeholder="Wählen…"
                noResultsText="Nichts gefunden"
                onChange={(value) => this.handleChange("status", value)}
                options={STATUS_OPTIONS}
                value={this.getFieldValue("status")}
              />
            </Form.Field>
            <Form.Field>
              <label>Typ</label>
              <Select
                placeholder="Wählen…"
                noResultsText="Nichts gefunden"
                onChange={(value) => this.handleChange("type", value)}
                options={mediaTypeOptions}
                value={this.getFieldValue("type")}
              />
            </Form.Field>
            <Form.Field>
              <label>Format</label>
              <Select
                placeholder="Wählen…"
                noResultsText="Nichts gefunden"
                onChange={(value) => this.handleChange("format", value)}
                options={mediaFormatOptions}
                value={this.getFieldValue("format")}
              />
            </Form.Field>
            <Form.Field>
              <label>Region</label>
              <Select
                placeholder="Wählen…"
                noResultsText="Nichts gefunden"
                onChange={(value) => this.handleChange("regionCode", value)}
                options={mediaRegionCodeOptions}
                value={this.getFieldValue("regionCode")}
              />
            </Form.Field>
            <FormInput
              label="Anzahl Discs"
              type="number"
              value={this.getFieldValue("count")}
              onChange={(e) =>
                this.handleChange("count", Number(e.target.value))
              }
            />
            <FormInput
              label="Zusatzbeschreibung"
              type="text"
              value={this.getFieldValue("description")}
              onChange={(e) => this.handleChange("description", e.target.value)}
            />
            <FormInput
              label="Extras"
              type="text"
              value={this.getFieldValue("extras")}
              onChange={(e) => this.handleChange("extras", e.target.value)}
            />
            <Form.Field>
              <label>
                <span>Sprachen</span>
                {this.props.borrowable.customLanguages ? (
                  <span style={{ float: "right" }}>
                    <Popup
                      content={`Import: ${this.props.borrowable.customLanguages}`}
                      trigger={<Icon name="sticky note" />}
                    />
                  </span>
                ) : null}
              </label>
              <Select
                multi={true}
                delimeter=","
                simpleValue
                placeholder="Wählen…"
                noResultsText="Nichts gefunden"
                onChange={(value) => this.handleChange("languages", value)}
                options={langs}
                value={this.getFieldValue("languages")}
              />
            </Form.Field>
            <Form.Field>
              <label>
                <span>Untertitel</span>
                {this.props.borrowable.customSubtitle ? (
                  <span style={{ float: "right" }}>
                    <Popup
                      content={`Import: ${this.props.borrowable.customSubtitle}`}
                      trigger={<Icon name="sticky note" />}
                    />
                  </span>
                ) : null}
              </label>
              <Select
                multi={true}
                delimeter=","
                simpleValue
                placeholder="Wählen…"
                noResultsText="Nichts gefunden"
                onChange={(value) => this.handleChange("subtitles", value)}
                options={subs}
                value={this.getFieldValue("subtitles")}
              />
            </Form.Field>
          </Form>
        ),
      },
    ];

    const rentals = _.reverse(
      _.sortBy(
        _.map(media.mediaRentals, (mr) => {
          mr.sortDate = mr.startDate ? mr.startDate : mr.reservationDate;
          return mr;
        }),
        "sortDate"
      )
    );

    if (rentals && rentals.length)
      panes.push({
        menuItem: {
          key: "history",
          content: `Verlauf (${media.mediaRentalsCount})`,
        },
        render: () => (
          <Table selectable>
            <Table.Body>
              {_.map(rentals, (rental) => {
                let startDate = rental.startDate
                  ? moment(rental.startDate).format("DD.MM.YYYY")
                  : null;
                let endDate = rental.endDate
                  ? moment(rental.endDate).format("DD.MM.YYYY")
                  : null;
                let details = null;
                let isRented = false;
                let isBack = false;
                if (!rental.startDate) {
                  details =
                    "reserviert am " +
                    moment(rental.reservationDate).format("DD.MM.YYYY");
                } else {
                  details = endDate
                    ? startDate + " - " + endDate
                    : "ausgeliehen am " + startDate;
                  isRented = endDate ? false : true;
                  isBack = endDate ? true : false;
                }

                return (
                  <Table.Row key={rental.id} error={isRented} positive={isBack}>
                    <Table.Cell>
                      <Link to={`/clients/${rental.client.id}`}>
                        {rental.client.fullName}
                      </Link>
                    </Table.Cell>
                    <Table.Cell textAlign="right">{details}</Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
            {media.mediaRentalsCount > rentals.length && (
              <Table.Footer>
                <Table.Row>
                  <Table.Cell colSpan="2" textAlign="center">
                    <Button
                      content="Alle laden"
                      onClick={this.loadAllRentals}
                      loading={this.state.rentalsLoading}
                      disabled={this.state.rentalsLoading}
                    />
                  </Table.Cell>
                </Table.Row>
              </Table.Footer>
            )}
          </Table>
        ),
      });

    return (
      <Card fluid>
        <Confirm
          open={this.state.showDeleteModal}
          onCancel={(e) => {
            this.handleDelete("cancel");
          }}
          onConfirm={(e) => {
            this.handleDelete("ok");
          }}
          header="Vorsicht!"
          content="Diesen Eintrag wirklich löschen?"
          cancelButton="Abbrechen"
          confirmButton="Löschen"
        />
        <Card.Content>
          {media.id && !media.available ? (
            <Label color="red" ribbon>
              Ausgeliehen
            </Label>
          ) : media.id ? (
            <Button
              primary={this.props.isInRentalBasket ? true : false}
              floated="right"
              circular
              icon="shopping basket"
              onClick={(e) => {
                this.props.addToBasket(this.props.borrowable, media);
              }}
            />
          ) : null}
          {media.reservations && media.reservations.length ? (
            <Label color="pink" ribbon={media.available}>
              {`${media.reservations.length} Reservation${
                media.reservations.length > 1 ? "en" : ""
              }`}
            </Label>
          ) : null}
          <Tab menu={{ secondary: true, pointing: true }} panes={panes} />
        </Card.Content>
        {_.keys(this.state.changes).length || !this.props.media ? (
          <CardFooterButtons>
            <Button
              color="red"
              onClick={this.handleCancel}
              icon="cancel"
              content="Abbrechen"
            />
            <Button
              color="green"
              disabled={!this.state.changes.length}
              onClick={this.handleSave}
              icon="save"
              content="Ok"
            />
          </CardFooterButtons>
        ) : rentals && !rentals.length ? (
          <CardFooterButtons>
            <Button
              color="red"
              onClick={(e) => {
                this.handleDelete(null);
              }}
              icon="trash"
              content="Löschen"
            />
          </CardFooterButtons>
        ) : null}
      </Card>
    );
  }
}

export default MediaCard;
