import _ from "lodash"

import {
	MEMBERSHIPS_EXPIRING_RESPONSE,
	MEDIA_RESERVATIONS_RESPONSE,
	TRANSACTIONS_RESPONSE,
	OPENTRANSACTIONS_RESPONSE,
	INVOICES_RESPONSE,
	RENTAL_DELETE_RESPONSE,
	REMIND_MEMBERSHIP_RESPONSE,
} from "../actions/dashboard"

import {
	RENTAL_RECEIVE,
} from "../actions/returns"

const defaultState = {
	expiringMemberships: [],
	reservations: [],
	transactions: [],
	openTransactionClients: [],
	accounts: [],
}

export default (state = defaultState, action) => {
	switch (action.type) {
		case MEMBERSHIPS_EXPIRING_RESPONSE:
			const clients = _.sortBy(
				_.map(action.data.clients,(c) => {
					if (!c.membership) {
						// we get the first in the membership history
						// in case the membership already expired
						c.membership = _.head(c.memberships)
					}
					// we set the endDate onto the client to sort it
					c.endDate = c.membership.endDate
					// and sort by remindDate
					c.reminderSent = c.membership.reminderSent ? c.membership.reminderSent : "0"
					return c
				}),
				["reminderSent", "endDate"]
			)
			return _.assign({}, state, {
				expiringMemberships: clients,
			})

		case MEDIA_RESERVATIONS_RESPONSE:
			return _.assign({}, state, {
				reservations: action.data.reservations,
			})

		case TRANSACTIONS_RESPONSE:
			return _.assign({}, state, {
				transactions: _.map(action.data.transactions, (t) => {
					t.amount = parseFloat(t.amount)
					return t
				}),
				accounts: _.map(_.uniqBy(action.data.transactions, "account"), "account")
			})

		case OPENTRANSACTIONS_RESPONSE:
			return _.assign({}, state, {
				openTransactionClients: _.map(
					action.data.clients,
					c => {
						c.amount = parseFloat(c.amount)
						return c
					}
				),
			})

		case INVOICES_RESPONSE:
			const invoices = _.filter(action.data.invoices, i => i.balance < 0)
			return _.assign({}, state, {
				unpaidInvoices: _.filter(invoices, i => i.sent),
				unsentInvoices: _.filter(invoices, i => !i.sent),
			})

		case RENTAL_DELETE_RESPONSE:
			if (action.error) {
				return state
			}
			return _.assign({}, state, {
				reservations: _.filter(
					_.clone(state.reservations),
					res => res.id !== action.data.id
				),
			});

		case REMIND_MEMBERSHIP_RESPONSE:
			if (action.error) {
				return state
			}
			return _.assign({}, state, {
				expiringMemberships: _.map(
					state.expiringMemberships,
					client => {
						if (client.membership.id === action.data.id) {
							return _.assign({}, client, {
								membership: action.data,
							})
						}
						return client
					}
				),
			});

		case RENTAL_RECEIVE:
			if (action.error) {
				return state
			}
			return _.assign({}, state, {
				reservations: _.map(
					state.reservations,
					reservation => {
						if (reservation.id === action.data.id) {
							return action.data
						}
						return reservation
					}
				),
			});

		default:
			return state;
	}
}
