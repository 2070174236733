import React from "react"
import { Label, Icon } from "semantic-ui-react"

const Status = ({status, message, ...props}) => {
	if (status) {
		const color = status === "ok" ? "green" : "red"
		return (
			<div>
				<Label
					basic
					color={color}
					pointing="left"
				>
					{message}
					{" "}
					<Icon
						fitted
						size="big"
						color={color}
						name={status === "ok" ? "check circle" : "attention"}
						content={message}
					/>
				</Label>
			</div>
		)
	}
	return null
}

export default Status
