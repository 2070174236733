
export const INVOICE_REQUEST = "INVOICE_REQUEST"
export const INVOICE_RESPONSE = "INVOICE_RESPONSE"
export function invoiceRequest(data, clientId, invoiceId=false) {
	return {
		type: INVOICE_REQUEST,
		data: data,
		clientId: clientId,
		invoiceId: invoiceId,
	}
}

export const CLOSE_ERROR = "CLOSE_ERROR"
export function closeError() {
	return {
		type: CLOSE_ERROR,
	}
}
