export const ADD_RETURN_REQUEST = "ADD_RETURN_REQUEST"
export const ADD_RETURN_RESPONSE = "ADD_RETURN_RESPONSE"
export function addReturnRequest(rentalsIds, date=false, undo) {
	return {
		type: ADD_RETURN_REQUEST,
		rentalsIds: rentalsIds,
		date: date,
		undo: undo,
	}
}

export const RENTED_REQUEST = "RENTED_REQUEST"
export const RENTED_RESPONSE = "RENTED_RESPONSE"
export function requestRented(clientId = null, query = "", page = 0) {
	return {
		type: RENTED_REQUEST,
		clientId: clientId,
		query: query,
		page: (page ? page : 0),
	}
}

export const RENTAL_RECEIVE = "RENTAL_RECEIVE"
export const SET_REMINDED_DATE_REQUEST = "SET_REMINDED_DATE_REQUEST"
export function setRemindedDate(rentalId) {
	return {
		type: SET_REMINDED_DATE_REQUEST,
		rentalId: rentalId,
	}
}
