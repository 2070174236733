import React, { Component } from "react"
import _ from "lodash"
import request from "superagent"
import { Message, Divider, Header, Form, Button } from "semantic-ui-react"
import Select from "react-select"

import MotionPicture from "./MotionPicture"
import TmdbItem from "./TmdbItem"

const searchTypeOptions = [
	{ label: "Alle", value: "multi" },
	{ label: "Filme", value: "movie" },
	{ label: "Serien", value: "tv" },
];

class TmdbPane extends Component {

	constructor(props) {
		super(props)

		this.state = {
			searchMode: "multi",
			searchQuery: "",
			searchResults: [],
			currentPage: 1,
			maxPage: 1,
			searchError: false,
			searching: false,
		}

		this.searchTmdb = _.debounce(this.searchTmdb.bind(this), 200)
		this.loadPage = this.loadPage.bind(this)
		this.setSearchMode = this.setSearchMode.bind(this)
	}

	getTmdbQuery(mode, query, page) {
		return this.props.apiUrl +
			   "search/" + this.state.searchMode +
			   "?include_adult=true&query=" +
			   encodeURIComponent(this.state.searchQuery) +
			   "&api_key=" + this.props.apiKey +
			   "&page=" + page + "&language=de"
	}

	searchTmdb(input, mode=null) {
		if (!mode) mode = this.state.searchMode
		if (!input) {
			this.setState({
				currentPage: 1,
				maxPage: 1,
				searchQuery: "",
				searching: false,
				searchResults: []
			})
			return
		}

		this.setState({currentPage: 1, maxPage: 1, searchQuery: input, searching: true})
		request
			.get(this.getTmdbQuery(mode, input, 1))
			.end((err, res) => {
				if (err || !res.ok) {
					this.setState({
						searching: false,
						searchError: err ? err : res.status,
						searchResults: []
					})
					return
				}
				const data = res.body.results
				if (mode !== "multi")
					_.each(data, d => d.media_type = mode)
				this.setState({
					searching: false,
					currentPage: res.body.page,
					maxPage: res.body.total_pages,
					searchError: false,
					searchResults: data
				})
			});
	}

	loadPage(n) {
		const mode = this.state.searchMode
		if (n > this.state.maxPage) return

		request
			.get(this.getTmdbQuery(mode, this.state.searchQuery, n))
			.end((err, res) => {
				if (err || !res.ok) {
					return
				}
				const data = res.body.results
				if (mode !== "multi")
					_.each(data, d => d.media_type = mode)
				this.setState({
					searching: false,
					currentPage: res.body.page,
					maxPage: res.body.total_pages,
					searchError: false,
					searchResults: this.state.searchResults.concat(data)
				})
			});
	}

	setSearchMode(value) {
		this.setState({
			searchMode: value,
		})
		this.searchTmdb(this.state.searchQuery, value)
	}

	render() {
		return (
			<div>
				{
					this.props.borrowable &&
					this.props.borrowable.motionPictures &&
					!this.props.borrowable.motionPictures.length ?
					<Message negative>
						Es sind noch keine TMDB Einträge verlinkt
					</Message>
					: null
				}
				{
					this.props.borrowable ?
					_.map(this.props.borrowable.motionPictures, (motionPicture) =>
						<MotionPicture
							key={motionPicture.id}
							borrowable={this.props.borrowable}
							motionPicture={motionPicture}
							motionPictureValidation={this.props.motionPictureValidation}
						/>
					)
					: null
				}

				<Divider />

				<Header as="h3">
					TMDB Einträge hinzufügen
				</Header>
				<Form>
					<Form.Group>
						<Form
							.Input
							width={5}
							control={Select}
							label="Art"
							options={searchTypeOptions}
							onChange={obj => this.setSearchMode(obj.value)}
							value={this.state.searchMode}
							clearable={false}
						/>
						<Form
							.Input
							width={11}
							label="Suche"
							defaultValue={this.state.searchQuery}
							onChange={(e) => { this.searchTmdb(e.target.value) }}
						/>
					</Form.Group>
				</Form>
				{
					this.state.searchError ?
					<Message negative>
						<Message.Header>Es ist ein Fehler aufgetreten</Message.Header>
						<p>{this.state.searchError}</p>
					</Message>
					: null
				}
				{
					this.state.searchResults.length ?
					<div>
						{
							_.map(this.state.searchResults, (item) => {
								if (item.media_type === "movie" || item.media_type === "tv")
									return <TmdbItem key={item.id} {...this.props} item={item} />
								return null
							})
						}
						{
							this.state.currentPage < this.state.maxPage ?
							<Button
								compact
								fluid
								basic
								onClick={e => this.loadPage(this.state.currentPage+1)}
								content={`Mehr laden (${this.state.currentPage} / ${this.state.maxPage})`}
							/>
							: null
						}
					</div>
					: !this.state.searching && this.state.searchQuery ?
						<Message negative>
							<Message.Header>Keine Einträge gefunden</Message.Header>
						</Message>
						: null
				}
			</div>
		)
	}
}

export default TmdbPane
