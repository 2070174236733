import React, { Component } from "react"
import _ from "lodash"
import moment from "moment"
import request from "superagent"
import { Card, Image, Icon, Accordion, Button, List } from "semantic-ui-react"

import CardFooterButtons from "../CardFooterButtons"

class TmdbItem extends Component {

	constructor(props) {
		super(props)
		this.state = {
			open: false,
			detailInfos: false,
		}
		this.toggle = this.toggle.bind(this)
	}

	toggle() {
		if (!this.state.open) {
			if (!this.state.detailInfos) {
				request
					.get(this.props.apiUrl + this.props.item.media_type + "/" + this.props.item.id + "?include_adult=true" +
						 "&api_key=" + this.props.apiKey + "&append_to_response=credits")
					.end((err, res) => {
						if (err || !res.ok) {
							return
						}
						// add more info
						const data = res.body
						if (this.props.item.media_type === "tv") {
							// add season info
							data.seasons = _.map(data.seasons, (season) => {
								request
									.get(this.props.apiUrl + "tv/" + data.id + "/season/" + season.season_number +
										 "?api_key=" + this.props.apiKey)
									.end((err, res) => {
										if (err || !res.ok) {
											return
										}
										this.setState((prevState, props) => {
											const infos = _.clone(prevState.detailInfos)
											infos.seasons = _.map(infos.seasons, (s) => {
												if (s.id === season.id) {
													return _.merge(s, res.body)
												}
												return s
											})
											return {
												detailInfos: infos
											}
										});
									})
								return season
							})
						}
						this.setState({open: true, detailInfos: data})
					});
			} else {
				this.setState({open: true})
			}
		} else {
			this.setState({open: false})
		}
	}

	render() {
		const item = this.props.item

		let date = "";
		if (item.release_date)
			date = `(${moment(item.release_date).format("Y")})`
		else if (item.first_air_date) {
			date = `(${moment(item.first_air_date).format("Y")}`
			if (this.state.detailInfos && this.state.detailInfos.last_air_date) date = `${date} – ${moment(this.state.detailInfos.last_air_date).format("Y")}`
			date = `${date})`
		}
		const href = `https://www.themoviedb.org/${item.media_type}/${item.id}`
		return (
			<Card fluid>
				<Card.Content>
					{
						item.poster_path ?
						<Image
							floated="right"
							size="tiny"
							src={`https://image.tmdb.org/t/p/w300_and_h450_bestv2${item.poster_path}`}
						/>
						: null
					}
					<Card.Header>
						{
							item.media_type === "movie" ?
							<span>
								{item.original_title}
								{item.original_title !== item.title ? ` (${item.title})` : null}
							</span>
							: item.media_type === "tv" ?
							<span>
								{item.original_name}
								{item.original_name !== item.name ? ` (${item.name})` : null}
							</span>
							: null
						}
					</Card.Header>
					<Card.Meta>
						{item.media_type}, {date}
						<br/>
						<a href={href} target="_blank" rel="noopener noreferrer">
							<Icon name="linkify" /> TMDB Link
						</a>
					</Card.Meta>
				</Card.Content>
				{
					(item.media_type === "tv" && this.state.open && this.state.detailInfos) ?
					<Card.Content>
						<Accordion
							panels={
								_.map(this.state.detailInfos.seasons, season => ({
									key: `s_${season.id}`,
									title: <div>
												<Button
													compact
													onClick={
														(e) => {
															this.props.linkTmdb((this.props.borrowable ? this.props.borrowable.id : null ), {...item, season_number: season.season_number})
														}
													}
													icon="linkify"
													content="Verlinken"
													floated="right"
												/>
												Season {season.season_number} "{season.name}", {moment(season.air_date).format("Y")}
										</div>,
									content: season.episodes ?
										_.map(season.episodes, (e) => (
											<p key={e.id}>{e.episode_number} {e.name}</p>
										))
									:
										<span>{season.episode_count} Episoden</span>,
								}))
							}
						/>
					</Card.Content>
					: null
				}
				{
					(this.state.open && this.state.detailInfos) ?
					<Card.Content>
						{
							this.state.detailInfos.overview ?
							<Card.Description>
								{this.state.detailInfos.overview}
							</Card.Description>
							: null
						}
						<List>
							{
								this.state.detailInfos.genres ?
								<List.Item>
									<List.Header>
										Genres
									</List.Header>
									<List.Description>
										{_.join(_.map(this.state.detailInfos.genres, c => c.name), ", ")}
									</List.Description>
								</List.Item>
								: null
							}
							{
								this.state.detailInfos.production_countries ?
								<List.Item>
									<List.Header>
										Land
									</List.Header>
									<List.Description>
										{_.join(_.map(this.state.detailInfos.production_countries, c => c.name), ", ")}
									</List.Description>
								</List.Item>
								: null
							}
							{
								this.state.detailInfos.production_companies ?
								<List.Item>
									<List.Header>
										Produzent
									</List.Header>
									<List.Description>
										{_.join(_.map(this.state.detailInfos.production_companies, c => c.name), ", ")}
									</List.Description>
								</List.Item>
								: null
							}
							{
								this.state.detailInfos.networks ?
								<List.Item>
									<List.Header>
										Networks
									</List.Header>
									<List.Description>
										{_.join(_.map(this.state.detailInfos.networks, c => c.name), ", ")}
									</List.Description>
								</List.Item>
								: null
							}
							{
								this.state.detailInfos.languages ?
								<List.Item>
									<List.Header>
										Originalsprache
									</List.Header>
									<List.Description>
										{_.join(this.state.detailInfos.languages, ", ")}
									</List.Description>
								</List.Item>
								: null
							}
							{
								this.state.detailInfos.spoken_languages ?
								<List.Item>
									<List.Header>
										Originalsprache
									</List.Header>
									<List.Description>
										{_.join(_.map(this.state.detailInfos.spoken_languages, c => c.name), ", ")}
									</List.Description>
								</List.Item>
								: null
							}
							{
								(this.state.detailInfos.credits &&
								this.state.detailInfos.credits.crew && this.state.detailInfos.credits.crew.length) ?
								<List.Item>
									<List.Header>
										Crew
									</List.Header>
									<List.Description>
										{_.join(_.map(this.state.detailInfos.credits.crew, c => c.name ? c.name : c.job), ", ")}
									</List.Description>
								</List.Item>
								: null
							}
							{
								(this.state.detailInfos.credits &&
								this.state.detailInfos.credits.cast && this.state.detailInfos.credits.cast.length) ?
								<List.Item>
									<List.Header>
										Cast
									</List.Header>
									<List.Description>
										{_.join(_.map(this.state.detailInfos.credits.cast, c => c.name ? c.name : c.character), ", ")}
									</List.Description>
								</List.Item>
								: null
							}
						</List>
					</Card.Content>
					: null
				}
				<CardFooterButtons>
					<Button
						compact
						onClick={this.toggle}
						icon="info"
						content={this.state.open ? "Schliessen" : "Details"}
					/>

					{
						(item.media_type === "movie") ?
						<Button
							compact
							onClick={
								(e) => {
									this.props.linkTmdb( (this.props.borrowable ? this.props.borrowable.id : null ), item)
								}
							}
							icon="linkify"
							content="Verlinken"
						/>
						: null
					}
				</CardFooterButtons>
			</Card>
		)
	}
}

export default TmdbItem
