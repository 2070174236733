import React, { Component } from "react"
import { connect } from "react-redux"
import _ from "lodash"
import { Grid, Dimmer } from "semantic-ui-react"

import ScrollView from "../../components/ScrollView"
import Pagination from "../../components/Pagination"

import ClientDetails from "../../components/ClientDetails/ClientDetails"

import Client from "./Client"
import SearchHeader from "./SearchHeader"

import {
	requestClient,
	requestClients,
	requestCreateClient,
	changeClientField,
	requestUpdateClient,
	requestAddClientNote,
	requestDeleteClientNote,
	setClientDetails,
	loadAllRentals,
} from "../../actions/clients"
import { setClient } from "../../actions/app"


class ClientList extends Component {
	constructor(props) {
		super(props);

		this.state = {
			query: this.props.query,
			sorting: "alpha",
		}

		this.handleBlur = _.bind(this.handleBlur, this);
		this.handleChange = _.bind(this.handleChange, this);
		this.handleQueryChange = _.bind(this.handleQueryChange, this);
		this.handleSearch = _.debounce(_.bind(this.handleSearch, this), 200);
		this.handleCreate = _.bind(this.handleCreate, this);
		this.selectClient = _.bind(this.selectClient, this);
		this.setClient = this.setClient.bind(this)
		this.loadAllRentals = this.loadAllRentals.bind(this);
	}

	componentWillReceiveProps(nextProps) {
		if (!this.state.query && nextProps.query) {
			this.setState({
				query: nextProps.query,
			})
		}
	}

	handleBlur(event) {
		if (!this.props.client) return

		let name = event.target.name;
		if (!name) name = event.target.id;
		// return if no changes for this field
		if (typeof this.props.changes[name] === "undefined") return;
		if (typeof this.props.changes[name] === this.props.client[name]) return;
		// we want to send the change to the api
		this.props.updateClient(
			this.props.client.id,
			this.props.changes,
		);
	}

	handleChange(event) {
		let name = event.target.name;
		if (!name) name = event.target.id;
		// we want to update the state to temporary store the changed fields
		this.props.changeClientField(name, event.target.value);
	}

	handleQueryChange(event) {
		if (event.target.name === "query") {
			const query = event.target.value;
			this.setState({
				query: query,
			});
			this.handleSearch(query, this.props.sorting);
		} else {
			const sorting = event.target.value;
			this.setState({
				sorting: sorting,
			});
			this.handleSearch(this.state.query, sorting);
		}
	}

	handleSearch(query, sorting) {
		this.props.requestClients(query, sorting);
	}

	handleCreate() {
		this.props.createClient(
			this.props.changes
		);
	}

	changePage(event, page) {
		event.preventDefault()
		this.props.requestClients(this.props.query, this.props.sorting, page);
	}

	// add client to detail
	selectClient(client) {
		if (!client) {
			this.props.setClientDetails({
				open: false,
			})
		}
		if (this.props.details.open && !this.props.details.isNew &&
				this.props.client && this.props.client.id === client.id) {
			this.props.setClientDetails({
				open: false,
			})
		} else {
			this.props.requestClient(client.id)
		}
	}

	// add client to basket
	setClient(event, client) {
		if (event) event.stopPropagation()
		if (
			this.props.activeClient &&
			this.props.activeClient.id &&
			this.props.activeClient.id === client.id
		) this.props.setClient(null)
		else this.props.setClient(client)
	}

	loadAllRentals() {
		this.props.loadAllRentals(this.props.client)
	}

	render() {
		return (
			<ScrollView
				headerHeight="6em"
				footerHeight="5em"
				detailsWidth="30em"
				header={(
						<SearchHeader
							handleQueryChange={this.handleQueryChange}
							query={this.state.query}
							sorting={this.state.sorting}
						/>
					)}
				footer={(
						<Pagination
							page={this.props.page}
							maxPage={this.props.maxPage}
							handlePageChange={(e, p) => { this.changePage(e, p) }}
						/>
					)}
				details={(
						<ClientDetails
							mode={this.props.details}
							requesting={this.props.details &&this.props.details.requesting}
							creatingClientNote={this.props.creatingClientNote}
							changes={this.props.changes}
							client={this.props.client}
							handleBlur={this.handleBlur}
							handleChange={this.handleChange}
							handleCreate={this.handleCreate}
							handleAddNote={this.props.addClientNote}
							handleDeleteNote={this.props.deleteClientNote}
							memberships={this.props.memberships}
							selectClient={this.selectClient}
							users={this.props.users}
							baseHREF={this.props.baseHREF}
							setClient={this.props.setClient}
							activeClient={this.props.activeClient}
							loadAllRentals={this.loadAllRentals}
						/>
					)}
			>
				<div>
					<Dimmer active={this.props.searching} inverted />
					{
						this.props.clients.length === 0 ?
						<div>
							{
								this.state.query &&
								<div>
									Es konnten keine Kunden gefunden werden.
								</div>
							}
						</div>
					:
						<Grid columns={1} stretched>
							{_.map(this.props.clients, client =>
								<Client
									key={client.id}
									client={client}
									isActive={(
										this.props.activeClient &&
										this.props.activeClient.id === client.id
									)}
									isDetail={(
										this.props.client &&
										client.id === this.props.client.id
									)}
									setClient={this.setClient}
									selectClient={this.selectClient}
								/>
							)}
						</Grid>
					}
				</div>
			</ScrollView>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		...state.clients,
		memberships: state.app.memberships,
		activeClient: state.app.client,
		users: state.app.users,
		baseHREF: state.app.baseHREF,
	};
}
const mapDispatchToProps = (dispatch) => {
	return {
		requestClient: (id) => dispatch(
			requestClient(id)
		),
		requestClients: (query, sorting, page) => dispatch(
			requestClients(query, sorting, page)
		),
		createClient: (fields) => dispatch(
			requestCreateClient(fields)
		),
		changeClientField: (fieldName, value) => dispatch(
			changeClientField(fieldName, value)
		),
		updateClient: (clientId, fields) => dispatch(
			requestUpdateClient(clientId, fields)
		),
		setClient: client => {
			dispatch(setClient(client))
		},
		addClientNote: (clientId, content) => dispatch(
			requestAddClientNote(clientId, content)
		),
		deleteClientNote: (clientId, noteId) => dispatch(
			requestDeleteClientNote(clientId, noteId)
		),
		setClientDetails: (details) => dispatch(
			setClientDetails(details)
		),
		loadAllRentals: (client) => dispatch(
			loadAllRentals(client)
		),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientList)
