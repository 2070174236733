import React, { Component } from "react"
import { Card, Form, Button } from "semantic-ui-react"
import _ from "lodash"

import Note from "../Note/Note"


class DetailsPane extends Component {
	state = { changes: {} }
	submittedChanges = {}

	componentWillReceiveProps(nextProps) {
		if (nextProps.borrowable !== this.props.borrowable) {
			const diff = {}
			_.each(_.keys(this.state.changes),
				k => { if (!this.submittedChanges[k]) diff[k] = this.state.changes[k]; }
			)
			this.submittedChanges = {}
			this.setState({ changes: diff })
		}
	}

	getFieldValue(field) {
		if (this.state &&
			 this.state.changes &&
			 this.state.changes[field] !== null &&
			 typeof this.state.changes[field] !== "undefined")
			return this.state.changes[field];

		if (this.props.borrowable && this.props.borrowable[field])
			return this.props.borrowable[field];

		return ""
	}

	handleChange(e) {
		const name = e.target.name
		const value = e.target.value
		this.setState((prevState, props) => {
			return {
				changes: _.assign(prevState.changes, { [name]: value })
			}
		})
	}

	handleCreate() {
		if(this.props.borrowable) return
		this.setState({
			needsCreate: true
		})
	}

	handleBlur() {
		if(!this.props.borrowable) return
		this.setState({
			needsWrite: true
		})
	}

	shouldComponentUpdate(nextProps, nextState) {
		if (nextState.needsWrite && nextState.changes && _.keys(nextState.changes).length) {
			// we have a write request, go send it to the api
			this.props.editBorrowable(nextState.changes, nextProps.borrowable.id)
			this.setState({ needsWrite: false })
			this.submittedChanges = _.assign(this.submittedChanges, nextState.changes)
		}
		if (nextState.needsCreate && nextState.changes && _.keys(nextState.changes).length) {
			// we have a create request, go send it to the api
			this.props.createBorrowable(nextState.changes)
			this.setState({ needsCreate: false })
			this.submittedChanges = _.assign(this.submittedChanges, nextState.changes)
		}
		return true;
	}

	render() {
		const disabled = this.props.borrowable && this.props.borrowable.motionPictures.length !== 0
		return (
			<div>
				<Form>
					<Form.Input
						label="Keywords"
						name="customKeywords"
						value={this.getFieldValue("customKeywords")}
						onChange={ (e) => {this.handleChange(e)} }
						onBlur={ (e) => {this.handleBlur()} }
					/>
					<Form.Input
						disabled={disabled}
						label="Titel"
						name="customTitle"
						value={this.getFieldValue("customTitle")}
						onChange={ (e) => {this.handleChange(e)} }
						onBlur={ (e) => {this.handleBlur()} }
					/>
					<Form.Input
						label="Disc Titel"
						name="discTitle"
						value={this.getFieldValue("discTitle")}
						onChange={ (e) => {this.handleChange(e)} }
						onBlur={ (e) => {this.handleBlur()} }
					/>
					<Form.Input
						disabled={disabled}
						label="Original Titel"
						name="customOriginalTitle"
						value={this.getFieldValue("customOriginalTitle")}
						onChange={ (e) => {this.handleChange(e)} }
						onBlur={ (e) => {this.handleBlur()} }
					/>
					<Form.Input
						disabled={disabled}
						label="Regie"
						name="customDirector"
						value={this.getFieldValue("customDirector")}
						onChange={ (e) => {this.handleChange(e)} }
						onBlur={ (e) => {this.handleBlur()} }
					/>
					<Form.Input
						disabled={disabled}
						label="Schauspieler"
						name="customActor"
						value={this.getFieldValue("customActor")}
						onChange={ (e) => {this.handleChange(e)} }
						onBlur={ (e) => {this.handleBlur()} }
					/>
					<Form.Input
						disabled={disabled}
						label="Untertitel"
						name="customSubtitle"
						value={this.getFieldValue("customSubtitle")}
						onChange={ (e) => {this.handleChange(e)} }
						onBlur={ (e) => {this.handleBlur()} }
					/>
					<Form.Input
						disabled={disabled}
						label="Sprachen"
						name="customLanguages"
						value={this.getFieldValue("customLanguages")}
						onChange={ (e) => {this.handleChange(e)} }
						onBlur={ (e) => {this.handleBlur()} }
					/>
					<Form.Input
						disabled={disabled}
						label="Beschreibung"
						name="customDescription"
						value={this.getFieldValue("customDescription")}
						onChange={ (e) => {this.handleChange(e)} }
						onBlur={ (e) => {this.handleBlur()} }
					/>
					<Form.Input
						disabled={disabled}
						label="Zusätzliche Infos"
						name="customAdditionalInfo"
						value={this.getFieldValue("customAdditionalInfo")}
						onChange={ (e) => {this.handleChange(e)} }
						onBlur={ (e) => {this.handleBlur()} }
					/>

					{!this.props.borrowable ?
						<Button
							primary
							fluid
							disabled={
								this.props.mode.requesting ||
								_.keys(this.state.changes).length === 0
							}
							onClick={ (e) => {this.handleCreate()} }
							content="Erstellen"
							icon="plus"
						/>
					:
						<div>
							<Card fluid>
								<Card.Content>
									<Card.Header>Notizen</Card.Header>
								</Card.Content>
								<Card.Content>
									<Form.Field>
										<Form.Input
											type="textarea"
											name="newNote"
											onChange={ (e) => {this.handleChange(e)} }
											value={this.getFieldValue("newNote")}
											placeholder="Neu"
											loading={this.props.creatingMovieNote}
											action={
												<Button
													primary
													floated="right"
													disabled={
														this.props.creatingMovieNote ||
														(this.state.changes && !this.state.changes.newNote)
													}
													onClick={(e) => {
														this.props.handleAddNote(
															this.props.borrowable.id, this.state.changes["newNote"]
														)
														this.setState({
															newNote: ""
														})
													}}
													content="Ok"
												/>
											}
										/>
									</Form.Field>
								</Card.Content>
								{
									(this.props.borrowable.notes && this.props.borrowable.notes.length) ?
										this.props.borrowable.notes.map((note) => (
											<Note
											key={note.id}
											note={note}
											employee={note.employeeId ? _.find(this.props.users) : null}
											handleDelete={(e) => {this.props.handleDeleteNote(note.borrowableId, note.id)}}
											/>
										))
									:
										null
								}
							</Card>
						</div>
					}
				</Form>
			</div>
		)
	}
}

export default DetailsPane
