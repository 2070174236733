import React from "react"

const BorrowableFullTitle = props => {
	const borrowable = props.borrowable
	return (
		<div style={{ display: "inline" }}>
			{borrowable.customTitle}
			{
				(
					borrowable.customOriginalTitle &&
					borrowable.customOriginalTitle.trim() &&
					borrowable.customOriginalTitle !== borrowable.customTitle
				)
				?
				` - ${borrowable.customOriginalTitle}`
				: null
			}
			{borrowable.discTitle && " - " + borrowable.discTitle}
		</div>
	)
}

export default BorrowableFullTitle
