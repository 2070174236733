import React from "react"
import { Card, Grid, Button } from "semantic-ui-react"
import styled from "styled-components"

import Status from "../../components/Util/Status"
import { FormatMoney } from "../../components/Util"

const StyledButtonGroup = styled.div`
margin: -0.5em;
`


const AdditionalCost = (props) => {
	const { mode, cost, onEdit, onRemove } = props;

	return <Card.Content>
		<Grid>
			<Grid.Column width={12}>
				{cost.note}
			</Grid.Column>
			<Grid.Column width={2} textAlign="right">
				{FormatMoney(cost.amount)}
			</Grid.Column>
			<Grid.Column width={2} verticalAlign="middle" textAlign="right">
				{mode === "ack" ?
					<Status
						status={cost.status}
						message={cost.errorMessage}
					/>
				:
					<StyledButtonGroup>
						<Button
							compact
							onClick={e => onEdit(cost.id)}
							disabled={cost.isPostRental ? true : false}
							icon="pencil"
							circular
						/>
						<Button
							compact
							onClick={e => onRemove(cost.id)}
							icon="trash"
							circular
						/>
					</StyledButtonGroup>
				}
			</Grid.Column>
		</Grid>
	</Card.Content>
}

export default AdditionalCost
