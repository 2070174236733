import React, { Component } from "react";
import { connect } from "react-redux"
import styled from "styled-components"
import { Card, Header,
		 Table, TableHeader,
		 TableBody, TableFooter } from "semantic-ui-react"
import { Link } from "react-router-dom"
import { push } from "react-router-redux"
import DatePicker from "react-datepicker"
import DatepickerButton from "../../components/Util/DatepickerButton"
import moment from "moment"
import _ from "lodash"

import Reservation from "./Reservation"
import ExpiringMemberships from "./ExpiringMemberships"
import UnpaidInvoice from "./UnpaidInvoice"
import UnsentInvoice from "./UnsentInvoice"
import OpenItem from "./OpenItem"

import {
	FormatMoney
} from "../../components/Util"

import ScrollView from "../../components/ScrollView"

import {
	requestExpiringMemberships,
	requestMediaReservations,
	requestTransactions,
	requestOpenTransactions,
	requestInvoices,
	deleteRental,
	remindMembership,
	remindReservation,
} from "../../actions/dashboard"

import {
	setClient
} from "../../actions/app"

import {
	addToBasket, addMembership
} from "../../actions/basket"

const StyledDiv = styled.div`
position: relative;
height: calc(100% + 2em);
overflow: auto;
margin: -1em;

& > .inner {
    position: relative;
    min-width: 2000px;
    width: 100%;
    height: 100%;
}

& .ui.header {
    margin-bottom: 0.7em;
    opacity: 0.5;
    text-transform: uppercase;
    position: relative;
    font-size: 80%;
}
`

const CustomTableHeader = styled(TableHeader)`
position: absolute;
overflow: hidden;
left: 1em;
top: 1em;
right: 1em;
height: 2em;

& th {
background: transparent!important;
border: 0!important;
padding-bottom: 0!important;
padding-top: 0!important;
width: 10%;
&:not(.note):not(.client) {
text-align: right;
}
}

& th:client {
width: 25%;
}
& th:note {
width: 60%;
}
`

const CustomTableBody = styled(TableBody)`
position: absolute;
overflow-x: hidden;
overflow-y: auto;
left: 1em;
top: 3em;
right: 1em;
bottom: 4em;

& td {
width: 10%;

&:not(.note):not(.client) {
text-align: right;
}
}

& td.client {
width: 25%;
}
& td.note {
width: 15%;
}
`

const CustomTableFooter = styled(TableFooter)`
position: absolute;
overflow: hidden;
left: 1em;
height: 4em;
right: 1em;
bottom: 0;

& td {
width: 10%;

&:not(.note):not(.client) {
text-align: right;
}
}

& td:first-child {
width: 25%;
}
& td:last-child {
width: 15%;
}
`

class Dashboard extends Component {

	constructor(props) {
		super(props)

		this.state = {
			date: null,
		}
		this.handleDeleteRental = this.handleDeleteRental.bind(this)
		this.handleRentalToBasket = this.handleRentalToBasket.bind(this)
		this.clientToCashRegister = this.clientToCashRegister.bind(this)
		this.renewClientMembership = this.renewClientMembership.bind(this)
		this.remindClientMembership = this.remindClientMembership.bind(this)
		this.remindMovieReservation = this.remindMovieReservation.bind(this)
	}

	componentDidMount() {
		this.props.requestExpiringMemberships()
		this.props.requestMediaReservations()
		this.props.requestInvoices()
		this.props.requestOpenTransactions()
		this.requestTransactions(moment())
	}

	renewClientMembership(client) {
		this.props.setClient(client)
		const mem = _.find(this.props.memberships, { id: client.membership.membershipId })
		this.props.addMembership(mem)
		this.props.push("/basket")
	}

	remindClientMembership(membershipId) {
		this.props.remindMembership(membershipId)
	}

	remindMovieReservation(reservationId) {
		this.props.remindReservation(reservationId)
	}

	requestTransactions(date) {
		this.setState({
			date: date,
		});
		this.props.requestTransactions(date.format("DD.MM.YYYY"))
	}

	clientToCashRegister(client) {
		this.props.setClient(client)
		this.props.push("/cash-register")
	}

	handleDeleteRental(rentalId) {
		this.props.deleteRental(rentalId)
	}

	handleRentalToBasket(rental) {
		this.props.setClient(rental.client)
		this.props.addToBasket(rental.borrowable, rental.media, false)
		this.props.push("/basket")
	}

	render() {
		const scrollDivCount = 8
		return (
			<StyledDiv>
				<div className="inner">
					<ScrollView
						headerHeight="2em"
						footerHeight="0"
						left="0"
						right={`${(100 - 100 / scrollDivCount * 1)}%`}
						header={(<Header>Ablaufende Abos</Header>)}
					>
						<ExpiringMemberships
						renewClientMembership={this.renewClientMembership}
						remindClientMembership={this.remindClientMembership}
						expiringMemberships={this.props.expiringMemberships}
						baseHREF={this.props.baseHREF}
						/>
					</ScrollView>

					<ScrollView
						headerHeight="2em"
						footerHeight="0"
						left={`${(100 / scrollDivCount * 1)}%`}
						right={`${(100 - 100 / scrollDivCount * 2)}%`}
						header={(<Header>Aktuelle Reservationen</Header>)}
					>
						<Card.Group>
							{
								_.map(
									_.concat(
										_.sortBy(
											_.filter(
												this.props.reservations,
												r => r.media.available,
											),
											[ "reservationDate" ]
										),
										_.sortBy(
											_.filter(
												this.props.reservations,
												r => !r.media.available,
											),
											[ "reservationDate" ]
										)
									),
									rental => (
										<Reservation
										key={rental.id}
										rental={rental}
										handleDelete={this.handleDeleteRental}
										handleToBasket={this.handleRentalToBasket}
										remindMovieReservation={this.remindMovieReservation}
										/>
									)
								)
							}
						</Card.Group>
					</ScrollView>

					<ScrollView
						headerHeight="2em"
						footerHeight="0"
						left={`${(100 / scrollDivCount * 2)}%`}
						right={`${(100 - 100 / scrollDivCount * 3)}%`}
						top="0"
						bottom="0"
						header={(
								<Header>Unbeglichene Rechnungen</Header>
						)}
					>
						<Card.Group>
							{_.map(this.props.unpaidInvoices, inv => (
								<UnpaidInvoice
								key={inv.invoiceNumber}
								inv={inv}
								handleToCashRegister={this.clientToCashRegister}
								/>
							))}
						</Card.Group>
					</ScrollView>

					<ScrollView
						headerHeight="2em"
						footerHeight="0"
						left={`${(100 / scrollDivCount * 3)}%`}
						right={`${(100 - 100 / scrollDivCount * 4)}%`}
						top="0"
						bottom="0"
						header={(
								<Header>Unversendete Rechnungen</Header>
						)}
					>
						<Card.Group>
							{_.map(this.props.unsentInvoices, inv => (
								<UnsentInvoice
								key={inv.invoiceNumber}
								inv={inv}
								handleToCashRegister={this.clientToCashRegister}
								/>
							))}
						</Card.Group>
					</ScrollView>

					<ScrollView
						headerHeight="2em"
						footerHeight="0"
						left={`${(100 / scrollDivCount * 4)}%`}
						right={`${(100 - 100 / scrollDivCount * 5)}%`}
						top="0"
						bottom="0"
						header={(
								<Header>Offene Posten</Header>
						)}
					>
						<Card.Group>
							{
								_.map(
									_.sortBy(
										this.props.openTransactionClients,
										[ "amount" ]
									),
									c => (
										<OpenItem
										key={c.id}
										client={c}
										handleToCashRegister={this.clientToCashRegister}
										/>
									)
								)
							}
						</Card.Group>
					</ScrollView>

					<ScrollView
						headerHeight="2em"
						footerHeight="0"
						left={`${(100 / scrollDivCount * 5)}%`}
						right="0"
						header={(
								<div>
									<Header>Umsatz</Header>
									<div style={{
										position: "absolute",
										left: "1em",
										top: "2.3em",
										width: "20em"
									}}>
										<DatePicker
										maxDate={moment()}
										todayButton={"Heute"}
										customInput={<DatepickerButton size="small"/>}
										dateFormat="DD.MM.YYYY"
										selected={this.state.date}
										onChange={date => this.requestTransactions(moment(date))}
										/>
									</div>
								</div>
						)}
					>
						<Table style={{ border: 0 }}>
							<CustomTableHeader>
								<Table.Row>
									<Table.HeaderCell className="client">
										Kunde
									</Table.HeaderCell>
									{
										_.map(this.props.accounts, acc =>
											<Table.HeaderCell key={acc} className="account">
												{acc}
											</Table.HeaderCell>
										)
									}
									<Table.HeaderCell className="note">
										Notiz
									</Table.HeaderCell>
								</Table.Row>
							</CustomTableHeader>
							<CustomTableBody>
								{_.map(this.props.transactions, trans =>
									<Table
										.Row
										key={trans.id}
										positive={trans.amount > 0}
										negative={trans.amount < 0}
										>
										<Table.Cell className="client">
											<Link to={`/clients/${trans.client.id}`}>
												{trans.client.fullName}
											</Link>
										</Table.Cell>
										{_.map(this.props.accounts, acc =>
											<Table.Cell key={acc} className="account">
												{acc === trans.account ?
												 FormatMoney(trans.amount) : null}
											</Table.Cell>
										)}
										<Table.Cell className="note">{trans.note}</Table.Cell>
									</Table.Row>
								)}
							</CustomTableBody>
							<CustomTableFooter>
								<Table.Row>
									<Table.Cell className="client">
										<strong>Total</strong>
									</Table.Cell>
									{_.map(this.props.accounts, acc =>
										<Table.Cell key={acc} className="account">
											{FormatMoney(_.sumBy(
												 _.filter(
													 this.props.transactions, { account: acc }
												 ),
												 "amount"
											))}
										</Table.Cell>
									)}
									<Table.Cell className="note" style={{ textAlign: "right" }}>
										<strong>
											{FormatMoney(_.sumBy(
												 this.props.transactions,
												 "amount"
											))}
										</strong>
									</Table.Cell>
								</Table.Row>
							</CustomTableFooter>
						</Table>
					</ScrollView>
				</div>
			</StyledDiv>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		...state.dashboard,
		memberships: state.app.memberships,
		baseHREF: state.app.baseHREF,
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		requestExpiringMemberships: () =>
			dispatch(requestExpiringMemberships()),
		requestMediaReservations: () =>
			dispatch(requestMediaReservations()),
		requestTransactions: (date) =>
			dispatch(requestTransactions(date)),
		requestOpenTransactions: () =>
			dispatch(requestOpenTransactions()),
		requestInvoices: () =>
			dispatch(requestInvoices()),
		deleteRental: (rentalId) =>
			dispatch(deleteRental(rentalId)),
		setClient: (client) =>
			dispatch(setClient(client)),
		addMembership: (membership) =>
			dispatch(addMembership(membership)),
		remindMembership: (membershipId) =>
			dispatch(remindMembership(membershipId)),
		remindReservation: (reservationId) =>
			dispatch(remindReservation(reservationId)),
		push: (url) => dispatch(push(url)),
		addToBasket: (borrowable, media, isReservation = false) =>
			dispatch(addToBasket(borrowable, media, isReservation)),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
