import React, { Component } from "react"
import { connect } from "react-redux"
import { Redirect } from "react-router-dom"
import styled from "styled-components"
import { Grid, Form, Segment, Header, Input, Button, Message } from "semantic-ui-react"
import _ from "lodash"

import { requestToken } from "../../actions/app"

const StyledGrid = styled(Grid)`
height: 100%;
`
const StyledColumn = styled(Grid.Column)`
max-width: 30em;
`

class Login extends Component {
	constructor(props) {
		super(props)

		this.state = {
			username: "",
			password: ""
		}

		this.handleChange = this.handleChange.bind(this)
		this.handleLogin = this.handleLogin.bind(this)
	}

	handleChange(event) {
		this.setState({
			[event.target.name]: event.target.value,
		})
	}

	handleLogin(event) {
		event.preventDefault();
		this.props.onLoginClick(this.state.username, this.state.password)
	}

	isValidForm() {
		return !_.isEmpty(this.state.password) && !_.isEmpty(this.state.username)
	}

	render() {
		if (this.props.ok) {
			return <Redirect to={{ pathname: "/", state: { from: this.props.location} }} />
		}

		return (
			<StyledGrid centered verticalAlign="middle" stretched>
				<StyledColumn>
					<Form size="large" onSubmit={this.handleLogin} loading={this.props.requesting}>
						<Segment>
							<Header>
								Login
							</Header>
							<Form.Field>
								<Input
									icon="users"
									iconPosition="left"
									name="username"
									placeholder="Username"
									value={this.state.username}
									onChange={this.handleChange}
								/>
							</Form.Field>
							<Form.Field>
								<Input
									icon="lock"
									iconPosition="left"
									type="password"
									name="password"
									placeholder="Password"
									value={this.state.password}
									onChange={this.handleChange}
								/>
							</Form.Field>
							<Message
								error visible={this.props.error ? true : false}
								header="Loginfehler"
								content={this.props.error}
							/>
							<Button
								fluid
								disabled={!this.isValidForm()}
								type="submit"
							>
								Login
							</Button>
						</Segment>
					</Form>
				</StyledColumn>
			</StyledGrid>
		);
	}
}

const mapStateToProps = (_state) => {
	const state = _state.app;

	return {
		ok: state.user ? true : false,
		requesting: state.requesting,
		error: state.error,
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		onLoginClick: (username, password) => {
			dispatch(requestToken(username, password))
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
