
import _ from "lodash"

import {
	RECEIVE_CLIENT,
	REQUEST_CLIENT,
	RECEIVE_CLIENTS,
	REQUEST_CLIENTS,
	CREATE_CLIENT_REQUEST,
	CREATE_CLIENT_RESPONSE,
	CHANGE_CLIENT_FIELD,
	UPDATE_CLIENT_RESPONSE,
	ADD_CLIENT_NOTE_REQUEST,
	ADD_CLIENT_NOTE_RESPONSE,
	DELETE_CLIENT_NOTE_RESPONSE,
	SET_CLIENT_DETAILS,
	LOAD_ALL_CLIENT_RENTALS_RESPONSE,
} from "../actions/clients"

import {
	CHECKOUT_RESPONSE,
} from "../actions/basket"

import {
	SET_CLIENT_RESPONSE,
} from "../actions/app"

import {
	RENTAL_DELETE_RESPONSE,
} from "../actions/dashboard"

import {
	ADD_RETURN_RESPONSE,
} from "../actions/returns"

const extractErrorMessage = (action) => {
	return action.errorMessage ? action.errorMessage : action.error ? action.error : "Error!"
};

export default (state = {
	query: "",
	page: 0,
	maxPage: 0,
	clients: [],
	client: null,
	changes: {},
	details: {
		open: false,
		isNew: false,
		requesting: false,
	},
	error: false,
}, action) => {

	let newState = {};

	switch (action.type) {

		case SET_CLIENT_RESPONSE:
			// after picking a basket client or manipuplating invoices and transactions
			if (state.client && action.data && (action.data.id || action.data.client)) {
				return _.assign({}, state, {
					client: (action.data.id ? action.data : action.data.client),
					changes: {},
				})
			}
			return state

		case REQUEST_CLIENT:
			return _.assign({}, state, {
				details: {
					...state.details,
					requesting: true
				},
			});

		case RECEIVE_CLIENT:
			newState = _.assign({}, state, {
				details: {
					...state.details,
					isNew: false,
					open: true,
					requesting: false,
				},
				client: null,
				clients: [],
				query: "",
				changes: {},
			});
			if (action.error) {
				newState.error = extractErrorMessage(action);
			} else {
				newState.client = action.data;
				if(_.find(state.clients, {id: action.data.id})) {
					newState.clients = _.clone(state.clients)
					newState.query = _.clone(state.query)
				}
			}
			return newState;

		case REQUEST_CLIENTS:
			return _.assign({}, state, {
				searching: true,
				query: action.query,
			});

		case RECEIVE_CLIENTS:
			newState = _.assign({}, state, {
				searching: false,
				page: 0,
				maxPage: 0,
				clients: [],
			});
			if (action.error) {
				newState.error = extractErrorMessage(action);
			} else {
				newState.page = action.page;
				newState.maxPage = action.data.pages;
				newState.clients = action.data.clients;
			}
			return newState;

		case CREATE_CLIENT_REQUEST:
			return _.assign({}, state, {
				details: {
					...state.details,
					requesting: true,
				},
			});

		case CREATE_CLIENT_RESPONSE:
			newState = _.assign({}, state);
			newState.details.requesting = false;

			if (action.error) {
				newState.error = extractErrorMessage(action);
			} else {
				newState.details.isNew = false;
				// we set the new client for detail view
				newState.client = action.data;
				// and add it to the results list
				newState.clients = _.concat(newState.clients, action.data);
			}
			return newState;

		case CHANGE_CLIENT_FIELD:
			return _.merge({}, state, {
				changes: {[action.fieldName]: action.value},
			});

		case UPDATE_CLIENT_RESPONSE:
			if (!action.error) {
				newState = _.assign({}, state, {
					clients: _.map(state.clients, (c) => {
						if(c.id !== state.client.id) return c;
						return _.assign({}, state.client, action.data);
					}),
					client: _.merge({}, state.client, action.data),
				});
				_.each(action.data, (val, key) => {
					delete newState.changes[key];
				});
				return newState;
			} else {
				// TODO: error output
				return state;
			}

		case ADD_CLIENT_NOTE_REQUEST:
			return _.assign({}, state, {
				creatingClientNote: true
			});

		case ADD_CLIENT_NOTE_RESPONSE:
			if (!action.error) {
				if(action.data.clientId === state.client.id) {
					const notes = state.client.notes ?
								  _.concat([action.data], state.client.notes) :
								  [ action.data ]

					return _.assign({}, state, {
						creatingClientNote: false,
						client: {
							...state.client,
							notes: notes,
						},
						changes: _.assign({}, state.client.changes, {
							newNote: ""
						}),
					});
				}
			}
			// TODO: error output
			return _.assign({}, state, {
				creatingClientNote: false
			});

		case DELETE_CLIENT_NOTE_RESPONSE:
			if (action.error)
				return state

			return _.assign({}, state, {
				client: _.assign({}, state.client, {
					notes: _.filter(state.client.notes, note => note.id !== action.data.id),
				})
			});

		case SET_CLIENT_DETAILS:
			return _.assign({}, state, {
				details: {
					...state.details,
					...action.details,
				},
				client: action.details.open && !action.details.isNew ? state.client : null,
			});

		case CHECKOUT_RESPONSE:
			if (action.error) {
				return state;
			}

			newState = _.clone(state)
			if (state.client && state.client.id === action.data.client.id) {
				newState.client = action.data.client
			}
			newState.clients = _.map(newState.clients, (c) => {
				if (c.id === action.data.client.id)
					return action.data.client
				return c
			})
			return newState

		case RENTAL_DELETE_RESPONSE:
			if (action.error) {
				return state
			}
			if (state.client && state.client.mediaRentals) {
				return _.assign({}, state, {
					client: _.assign({}, state.client, {
						mediaRentals: _.filter(
							state.client.mediaRentals,
							medRent => medRent.id !== action.data.id
						)
					})
				})
			}
			return state

		case ADD_RETURN_RESPONSE:
			if (action.error || !state.client) {
				return state
			}

			//
			const _state = _.assign({}, state)
			_.each(action.data.results, (res) => {
				if (_state.client && _state.client.id === res.rental.client.id) {
					const _mediaRentals = _.map(
						_state.client.mediaRentals,
						r => r.id === res.rental.id ? res.rental : r
					)
					_state.client = _.assign(
						{},
						_state.client,
						res.rental.client,
						{ mediaRentals: _mediaRentals }
					)
				}
			})
			return _state

		case LOAD_ALL_CLIENT_RENTALS_RESPONSE:
			return _.merge({}, state, {
				client: {
					mediaRentals: action.data.rentals,
				},
			});

		default:
			return state
	}
}
