export const REQUEST_CLIENT = "REQUEST_CLIENT"
export const RECEIVE_CLIENT = "RECEIVE_CLIENT"
export function requestClient(id = 0) {
	return {
		type: REQUEST_CLIENT,
		id: id,
	}
}

export const REQUEST_CLIENTS = "REQUEST_CLIENTS"
export const RECEIVE_CLIENTS = "RECEIVE_CLIENTS"
export function requestClients(query = "", sorting="alpha", page = 0) {
	return {
		type: REQUEST_CLIENTS,
		query: query,
		sorting: sorting,
		page: page,
	}
}

export const CREATE_CLIENT_REQUEST = "CREATE_CLIENT_REQUEST"
export const CREATE_CLIENT_RESPONSE = "CREATE_CLIENT_RESPONSE"
export function requestCreateClient(fields) {
	return {
		type: CREATE_CLIENT_REQUEST,
		fields: fields,
	}
}

export const CHANGE_CLIENT_FIELD = "CHANGE_CLIENT_FIELD"
export function changeClientField(fieldName, value) {
	return {
		type: CHANGE_CLIENT_FIELD,
		fieldName: fieldName,
		value: value,
	}
}

export const UPDATE_CLIENT_REQUEST = "UPDATE_CLIENT_REQUEST"
export const UPDATE_CLIENT_RESPONSE = "UPDATE_CLIENT_RESPONSE"
export function requestUpdateClient(clientId, fields) {
	return {
		type: UPDATE_CLIENT_REQUEST,
		clientId: clientId,
		fields: fields,
	}
}
export const ADD_CLIENT_NOTE_REQUEST = "ADD_CLIENT_NOTE_REQUEST"
export const ADD_CLIENT_NOTE_RESPONSE = "ADD_CLIENT_NOTE_RESPONSE"
export function requestAddClientNote(clientId, content) {
	return {
		type: ADD_CLIENT_NOTE_REQUEST,
		clientId: clientId,
		content: content,
	}
}
export const DELETE_CLIENT_NOTE_REQUEST = "DELETE_CLIENT_NOTE_REQUEST"
export const DELETE_CLIENT_NOTE_RESPONSE = "DELETE_CLIENT_NOTE_RESPONSE"
export function requestDeleteClientNote(clientId, noteId) {
	return {
		type: DELETE_CLIENT_NOTE_REQUEST,
		clientId: clientId,
		noteId: noteId,
	}
}

export const SET_CLIENT_DETAILS = "SET_CLIENT_DETAILS"
export function setClientDetails(details) {
	return {
		type: SET_CLIENT_DETAILS,
		details: details,
	}
}

export const LOAD_ALL_CLIENT_RENTALS_REQUEST = "LOAD_ALL_CLIENT_RENTALS_REQUEST"
export const LOAD_ALL_CLIENT_RENTALS_RESPONSE = "LOAD_ALL_CLIENT_RENTALS_RESPONSE"
export function loadAllRentals(client) {
	return {
		type: LOAD_ALL_CLIENT_RENTALS_REQUEST,
		client: client,
	}
}
