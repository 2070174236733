import React, { Component } from "react"
import _ from "lodash"
import moment from "moment"
import { connect } from "react-redux"
import { Card, Button, Grid, Input, Loader } from "semantic-ui-react"
import DatePicker from "react-datepicker"

import Pagination from "../../components/Pagination"
import ScrollView from "../../components/ScrollView"
import DatepickerButton from "../../components/Util/DatepickerButton"
import LinkedClient from "../../components/LinkedClient/LinkedClient"

import Media from "./Media"

import {
	setClient,
	removeClient,
} from "../../actions/app"

import {
	requestRented,
	addReturnRequest,
	setRemindedDate,
} from "../../actions/returns"

class ReturnList extends Component {
	constructor(props) {
		super(props);

		this.state = {
			returnDate: moment(),
			rentals: [],
		}

		this.handleQueryChange = _.bind(this.handleQueryChange, this);
		this.handleSearch = _.debounce(_.bind(this.handleSearch, this), 500);
		this.handleReturnDateChange = this.handleReturnDateChange.bind(this);
		this.changePage = _.bind(this.changePage, this);
		this.handleReturnClick = this.handleReturnClick.bind(this);
		this.handleReturnUndoClick = this.handleReturnUndoClick.bind(this);
	}

	componentDidMount(){
		this.calcPostPrices(this.props.medias, this.state.returnDate)
	}

	componentWillReceiveProps(nextProps) {
		if (!this.state.query && nextProps.query) {
			this.setState({
				query: nextProps.query,
			})
		}
		if (
			(nextProps.medias && this.props.medias !== nextProps.medias) ||
			(nextProps.priceCategories && this.props.priceCategories !== nextProps.priceCategories)
		) {
			this.calcPostPrices(nextProps.medias, this.state.returnDate)
		}
	}

	calcPostPrices(medias, date) {
		// we calculate the post price depending on user discount and price cat
		const rentals = []
		_.each(medias, (m) => {
			rentals.push(_.assign({
				id: m.rental.id,
				priceCat: _.find(
					this.props.priceCategories,
					{ id: m.rental.priceCategoryId }
				),
			}, this.calcPostPrice(m.rental, date)))
		})
		this.setState({
			rentals: rentals,
		})
	}

	calcPostPrice(rental, date) {
		const pCat = _.find(this.props.priceCategories, { id: rental.priceCategoryId })
		if (pCat) {
			// calc price for delay
			const now = date
			const start = moment(rental.startDate)
			const rentalEnd = start.add(pCat.duration, "d");
			const delay = Math.max(0, Math.floor(moment.duration(now.diff(rentalEnd)).asDays()))

			return {
				delay: delay,
				amount: rental.isSlave ? 0 : delay *
				pCat.postPrice *
				(
					1 - (rental.client && rental.client.membership ?
						 rental.client.membership.discount :
						 0
					)
				),
			}
		}
	}

	handleQueryChange(event) {
		const query = event.target.value;

		this.setState({
			query: query,
		});

		this.handleSearch(query)
	}

	handleSearch(query) {
		this.props.requestRented((this.props.client ? this.props.client.id : null), query);
	}

	handleReturnDateChange(date) {
		this.calcPostPrices(this.props.medias, date)
		this.setState({
			returnDate: date,
		});
	}

	handleReturnClick(rentalId, date) {
		const rentalsIds = [rentalId]
		this.props.addReturnRequest(rentalsIds, date)
	}

	handleReturnAllClick(medias, date) {
		const rentalsIds = _.map(medias, (media) => {
			if(!media.rental.endDate)
				return media.rental.id
		})
		this.props.addReturnRequest(rentalsIds, date)
	}

	handleReturnUndoClick(rentalId) {
		const rentalsIds = [rentalId]
		this.props.addReturnRequest(rentalsIds, false, true)
	}

	changePage(event, page) {
		event.preventDefault();
		this.props.requestRented(
			(this.props.client ? this.props.client.id : null),
			this.state.query,
			page
		);
	}

	render() {
		const mediaIsRequesting = this.props.medias ? (_.filter(
			this.props.medias,
			{ "requesting": true }
		).length ? true : false) : false
		const openRentalsLeft = this.props.medias ? (_.filter(
			_.map(this.props.medias, m => {
				return m.rental
			}),
			{ "endDate": null }
		).length ? true : false) : false

		return (
			<ScrollView
				headerHeight="11em"
				footerHeight="5em"
				header={(
					<LinkedClient
					   client={this.props.client}
					   baseHREF={this.props.baseHREF}
					   setClient={this.props.setClient}
					   removeClient={this.props.removeClient}
					   performSearch={this.props.performClientSearch}
					/>
				)}
				footer={(
					<Pagination
						page={this.props.page}
						maxPage={this.props.maxPage}
						handlePageChange={this.changePage}
					/>
				)}
			>
				<div>
					<Card fluid>
						<Card.Content>
							<Grid verticalAlign="middle">
								<Grid.Column width={8}>
									<Input
										fluid
										placeholder="Suche nach Filmcode"
										onChange={this.handleQueryChange}
										value={(
												this.state.query ?
												this.state.query :
												this.props.query ?
												this.props.query :
												""
										)}
									>
										<input accessKey="c" />
									</Input>
								</Grid.Column>
								<Grid.Column width={3} textAlign="right">
									Rückgabedatum:
								</Grid.Column>
								<Grid.Column width={3} textAlign="right">
									<DatePicker
										customInput={<DatepickerButton fluid/>}
										dateFormat="DD.MM.YYYY"
										selected={this.state.returnDate}
										onChange={(date) => {this.handleReturnDateChange(date)}}
									/>
								</Grid.Column>
								<Grid.Column width={2} textAlign="right">
									<Button
										fluid
										primary
										onClick={(e) => {
												this.handleReturnAllClick(
													this.props.medias,
													this.state.returnDate
												)
										}}
										content="Alle zurück"
										icon="reply"
										disabled={mediaIsRequesting || !openRentalsLeft}
										loading={mediaIsRequesting}
									/>
								</Grid.Column>
							</Grid>
						</Card.Content>
					</Card>

					{!this.props.searching ?
						_.map(this.props.medias, (media) =>
							<Media
								key={media.id}
								media={media}
								clientActive={this.props.client ? true : false }
								returnDate={this.state.returnDate}
								rentalInfo={_.find(this.state.rentals, { id: media.rental.id })}
								onSetClient={this.props.setClient}
								onSetRemindedDate={this.props.setRemindedDate}
								onReturnClick={this.handleReturnClick}
								onReturnUndoClick={this.handleReturnUndoClick}
							/>
						)
					 :<Loader active={true}>Lade…</Loader>}
				</div>
			</ScrollView>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		...state.returns,
		priceCategories: state.app.priceCategories,
		client: state.app.client,
		baseHREF: state.app.baseHREF,
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		requestRented: (clientId, query, page) => dispatch(requestRented(clientId, query, page)),
		addReturnRequest: (rentalsIds, date=false, undo=false) => dispatch(
			addReturnRequest(rentalsIds, date, undo)
		),
		setClient: (client) => dispatch(setClient(client)),
		setRemindedDate: (rentalId) => dispatch(setRemindedDate(rentalId)),
		removeClient: () => dispatch(removeClient()),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ReturnList)
