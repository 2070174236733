import _ from "lodash"

import {
	ADD_BORROWABLE_NOTE_REQUEST,
	ADD_BORROWABLE_NOTE_RESPONSE,
	RECEIVE_BORROWABLE,
	REQUEST_BORROWABLE,
	CLOSE_BORROWABLE,
	RECEIVE_BORROWABLES,
	REQUEST_BORROWABLES,
	REQUEST_BORROWABLE_CREATE,
	REQUEST_BORROWABLE_DELETE_RESPONSE,
	REQUEST_BORROWABLE_EDIT,
	REQUEST_MOTIONPICTURE_VALIDATION,
	RECEIVE_MEDIA_EDIT,
	RECEIVE_MEDIA_DELETE,
	SET_MOVIE_DETAILS,
	RESPONSE_BORROWABLE_DELETE_NOTE,
	LOAD_ALL_MEDIA_RENTALS_RESPONSE,
} from "../actions/movies"

import {
	CHECKOUT_RESPONSE,
} from "../actions/basket"

import {
	RENTAL_DELETE_RESPONSE,
} from "../actions/dashboard"

import {
	ADD_RETURN_RESPONSE,
} from "../actions/returns"

export default (state = {
	query: {
		code: "",
		title: "",
		director: "",
		actor: "",
		country: "",
	},
	page: 0,
	maxPage: 0,
	changes: {},
	borrowables: [],
	borrowable: null,
	details: {
		requesting: false,
	}
}, action) => {
	switch (action.type) {
		case ADD_BORROWABLE_NOTE_REQUEST:
			return _.assign({}, state, {
				creatingMovieNote: true
			});

		case ADD_BORROWABLE_NOTE_RESPONSE:
			if (!action.error) {
				if(action.data.borrowableId === state.borrowable.id) {
					const notes = state.borrowable.notes;
					return _.assign({}, state, {
						creatingMovieNote: false,
						borrowable: _.merge({}, state.borrowable, {
							notes: _.reverse(_.sortBy(_.concat(notes, action.data), ["date"])),
						}),
					});
				}
			}
			// TODO: error output
			return _.assign({}, state, {
				creatingMovieNote: false
			});


		case CLOSE_BORROWABLE:
			return _.assign({}, state, {
				borrowable: null,
				details: {
					requesting: false,
				}
			});

		case REQUEST_BORROWABLE_CREATE:
			return _.assign({}, state, {
				details: {
					requesting: true,
				}
			});

		case REQUEST_BORROWABLE_DELETE_RESPONSE:
			if (action.error) {
				return state
			}
			return _.assign({}, state, {
				borrowables: _.filter(state.borrowables, b => b.id !== action.data.id),
				borrowable: null,
			});

		case REQUEST_BORROWABLE_EDIT:
			return _.assign({}, state, {
				details: {
					requesting: true,
				}
			});

		case REQUEST_MOTIONPICTURE_VALIDATION:
			return _.assign({}, state, {
				details: {
					requesting: true,
				}
			});

		case REQUEST_BORROWABLE:
			return _.assign({}, state, {
				details: {
					requesting: true,
				}
			});

		case RECEIVE_BORROWABLE:
			if (action.error) {
				return _.assign({}, state, {
					error: action.error,
					borrowable: null,
					details: _.merge({}, state.details, {
						requesting: false,
					})
				});
			} else {
				let newBorrowables = _.map(
					state.borrowables,
					b => b.id === action.data.id ? _.assign({}, b, action.data) : b
				)
				let newQuery = _.clone(state.query)
				if(!_.find(newBorrowables, {id: action.data.id})) {
					newBorrowables = []
					newQuery = {
						code: "",
						title: "",
						director: "",
						actor: "",
						all: "",
					}
				}

				return _.assign({}, state, {
					error: undefined,
					borrowable: action.data,
					details: {
						requesting: false,
					},
					borrowables: newBorrowables,
					query: newQuery
				});
			}

		case REQUEST_BORROWABLES:
			return _.assign({}, state, {
				searching: true,
				query: action.query,
			});

		case RECEIVE_BORROWABLES:
			if (action.error) {
				return _.assign({}, state, {
					searching: false,
					error: action.error,
					page: 0,
					maxPage: 0,
					borrowables: [],
				});
			} else {
				return _.assign({}, state, {
					searching: false,
					error: undefined,
					page: action.page,
					maxPage: action.data.pages,
					borrowables: action.data.borrowables,
				});
			}

		case SET_MOVIE_DETAILS:
			return _.assign({}, state, {
				borrowable: null,
				details: {
					...state.details,
					...action.details,
				},
			});

		case RECEIVE_MEDIA_EDIT:
			if (action.error) {
				return state;
			}
			// update medias after checkout
			const borrowable = _.assign({}, state.borrowable)
			let media = _.find(borrowable.medias, {id: action.data.id})
			if (!media) {
				// we want to add a new
				borrowable.medias = borrowable.medias.concat(action.data)
			} else {
				// we want to update the existing one
				borrowable.medias = _.map(
					borrowable.medias,
					m => m.id === action.data.id ? _.merge(m, action.data) : m
				)
			}
			return _.assign({}, state, {
				borrowables: _.map(
					state.borrowables,
					b => b.id === action.data.borrowableId ? _.assign({}, b, borrowable) : b
				),
				borrowable: borrowable,
			});

		case RECEIVE_MEDIA_DELETE:
			if (action.error) {
				return state;
			}
			// update medias after delete
			const _borrowable = _.assign({}, state.borrowable, {
				medias: _.filter(state.borrowable.medias, m => m.id !== action.data.id)
			})
			return _.assign({}, state, {
				borrowables:
					_.map(state.borrowables, b => b.id === _borrowable.id ? _borrowable : b),
				borrowable: _borrowable,
			});

		case CHECKOUT_RESPONSE:
			if (action.error) {
				return state;
			}

			const newState = _.clone(state)
			newState.movie = null
			// update medias after checkout
			if (action.data.client) {
				newState.movies = _.map(
					newState.movies,
					(m) => {
						m.medias = _.map(
							m.medias,
							(media) => {
								const clientMedia = _.find(
									action.data.client.mediaRentals,
									{ mediaId: media.id }
								)
								if (clientMedia && clientMedia.isReservation) {
									media.reservations.push(clientMedia)
								} else if (clientMedia) {
									media.available = false
								}
								return media
							}
						)
						return m
					}
				)
			}
			return newState

		case RENTAL_DELETE_RESPONSE:
			if (action.error) {
				return state
			}

			const borrowables = _.map(state.borrowables, borrowable => {
				return _.assign({}, borrowable, {
					medias: _.map(borrowable.medias, media => {
						return _.assign({}, media, {
							reservations: _.filter(
								media.reservations,
								res => res.id !== action.data.id
							)
						})
					})
				})
			})

			return _.assign({}, state, {
				borrowables: borrowables,
				borrowable: state.borrowable ? _.assign({}, state.borrowable, {
					medias: _.map(state.borrowable.medias, media => {
						return _.assign({}, media, {
							reservations: _.filter(
								media.reservations,
								res => res.id !== action.data.id
							),
							mediaRentals: _.filter(
								media.mediaRentals,
								medRent => medRent.id !== action.data.id
							),
						})
					})
				}) : state.borrowable,
			})

		case RESPONSE_BORROWABLE_DELETE_NOTE:
			if (action.error)
				return state

			return _.assign({}, state, {
				borrowable: _.assign({}, state.borrowable, {
					notes: _.filter(state.borrowable.notes, note => note.id !== action.data.id),
				})
			});

		case ADD_RETURN_RESPONSE:
			if (action.error) {
				return state
			}

			// flatten results
			let _medias = [], _rentals = []
			_.each(action.data.results, (res) => {
				_medias.push(res.rental.media)
				_rentals.push(res.rental)
			})
			const _borrowables = _.map(state.borrowables, borrowable => {
				return _.assign({}, borrowable, {
					medias: _.map(borrowable.medias, media => {
						const _media = _.find(_medias, { id: media.id })
						if(_media) return _media
						return media
					})
				})
			})

			return _.assign({}, state, {
				borrowables: _borrowables,
				borrowable: state.borrowable ? _.assign({}, state.borrowable, {
					medias: _.map(state.borrowable.medias, media => {
						const _media = _.find(_medias, { id: media.id })
						if (_media) {
							// we update rentals info for detail view
							const _mediaRentals = _.map(
								media.mediaRentals,
								mR => {
									const _rental = _.find(_rentals, { id: mR.id })
									if (_rental) return _rental
									return mR
								}
							)
							return _.assign(
								{},
								media,
								_media,
								{ mediaRentals: _mediaRentals }
							)
						}
						return _.assign({}, media)
					})
				}) : null
			})

		case LOAD_ALL_MEDIA_RENTALS_RESPONSE:
			return _.assign({}, state, {
				borrowable: _.assign({}, state.borrowable, {
					medias: _.map(state.borrowable.medias, m => {
						if (m.id !== action.data.media.id) return m;

						return _.assign({}, m, action.data.media)
					})
				}),
			});

		default:
			return state;
	}
}
