import React from "react"
import { Card } from "semantic-ui-react"
import _ from "lodash"
import moment from "moment"
import { List, Image, Accordion, Button } from "semantic-ui-react"

import CardFooterButtons from "../CardFooterButtons"

import { formatDuration } from "../Util/formatDuration";

const MotionPicture = (props) => {

	const href = `https://www.themoviedb.org/${props.motionPicture.className === "Movie" ? "movie" : "tv"}/${props.motionPicture.tmdbId}`

	const panels = [{
		title: "Beschrieb",
		content: <p>{props.motionPicture.descriptionDe ? props.motionPicture.descriptionDe : props.motionPicture.descriptionEn}</p>,
	}]
	if (props.motionPicture.crew.length) {
		panels.push({
			title: "Crew",
			content:
					<List>
						{
							_.map(props.motionPicture.crew, c =>
								<List.Item key={c.id}>
									<Image
										avatar
										src={(c.tmdbPosterPath ? `https://image.tmdb.org/t/p/w132_and_h132_bestv2${c.tmdbPosterPath}` : null)}
									/>
									<List.Content>
										<List.Header>{c.title}</List.Header>
										<List.Description>{c.job}</List.Description>
									</List.Content>
								</List.Item>
							)
						}
					</List>
		})
	}
	if (props.motionPicture.cast.length) {
		panels.push({
			title: "Cast",
			content:
					<List>
						{
							_.map(props.motionPicture.cast, c =>
								<List.Item key={c.id}>
									<Image
										avatar
										src={(c.tmdbPosterPath ? `https://image.tmdb.org/t/p/w132_and_h132_bestv2${c.tmdbPosterPath}` : null)}
									/>
									<List.Content>
										<List.Header>{c.title}</List.Header>
										<List.Description>{c.character}</List.Description>
									</List.Content>
								</List.Item>
							)
						}
					</List>
		})
	}

	const title = (
		props.motionPicture.titleDe &&
		props.motionPicture.titleDe.trim()
	) ? props.motionPicture.titleDe.trim() : "" +
	(
		props.motionPicture.originalTitle &&
		props.motionPicture.originalTitle.trim() &&
		props.motionPicture.originalTitle !== props.motionPicture.titleDe
	) ? " - " + props.motionPicture.originalTitle.trim() : ""

	return (
		<Card fluid>
			<Card.Content>
				{
					props.motionPicture.tmdbPosterPath ?
					<Image
						floated="right"
						size="mini"
						src={`https://image.tmdb.org/t/p/w300_and_h450_bestv2${props.motionPicture.tmdbPosterPath}`}
					/>
					: null
				}
			<Card.Header>
				{title}
			</Card.Header>
			<Card.Meta>
				{
					props.motionPicture.season ?
					<span>
						{
							props.motionPicture.seasonTitle ?
							props.motionPicture.seasonTitle
							: `Staffel ${props.motionPicture.season}`
						}
						, {" "}
					</span>
					: null
				}
				{props.motionPicture.originCountry}, {moment(props.motionPicture.releaseDate).format("Y")}
				{formatDuration(props.borrowable.duration)}
			</Card.Meta>
			</Card.Content>
			<Card.Content>
				<Card.Description>
					<a href={href} target="_blank" rel="noopener noreferrer">{href}</a>
				</Card.Description>
			</Card.Content>
			<Card.Content>
				<Accordion panels={panels} fluid />
			</Card.Content>
			<CardFooterButtons>
				<Button
					compact
					color="red"
					onClick={
						(e) => {
							props.motionPictureValidation(
								props.borrowable.id ?? null,
								"delete",
								{},
								props.motionPicture.id
							)
						}
					}
					icon="trash"
					content="Entfernen"
				/>
				<Button
					compact
					color="green"
					disabled={props.motionPicture.tmdbIDValidated}
					onClick={
						(e) => {
							props.motionPictureValidation(
								props.borrowable.id ?? null,
								"put",
								{},
								props.motionPicture.id
							)
						}
					}
					icon="check"
					content={props.motionPicture.tmdbIDValidated ? "Bestätigt" : "Bestätigen"}
				/>
			</CardFooterButtons>
		</Card>
	)
}

export default MotionPicture
