import React, { Component } from 'react';
import {
  Button,
  Tab,
  Header,
  Segment,
  Table,
  Dimmer,
  Loader
} from 'semantic-ui-react';
import _ from 'lodash';
import moment from 'moment';
import { Link } from 'react-router-dom';
import InvoiceHistoryPane from './InvoiceHistoryPane';
import DetailsPane from './DetailsPane';

class ClientDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      rentalsLoading: false
    };

    this.loadAllRentals = this.loadAllRentals.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.client &&
      nextProps.client &&
      this.props.client.mediaRentals &&
      this.props.client.mediaRentals.length !==
        nextProps.client.mediaRentals.length
    ) {
      this.setState({
        rentalsLoading: false
      });
    }
  }

  loadAllRentals() {
    this.setState({
      rentalsLoading: true
    });
    this.props.loadAllRentals();
  }

  render() {
    const panes = [
      {
        menuItem: { key: 'details', icon: 'info', content: 'Details' },
        render: () => <DetailsPane {...this.props} />
      }
    ];
    if (this.props.client) {
      if (
        this.props.client.mediaRentals &&
        this.props.client.mediaRentals.length
      ) {
        // sort
        const rentals = _.reverse(
          _.sortBy(
            _.map(this.props.client.mediaRentals, mr => {
              mr.sortDate = mr.startDate ? mr.startDate : mr.reservationDate;
              return mr;
            }),
            'sortDate'
          )
        );

        panes.push({
          menuItem: {
            key: 'rentals',
            icon: 'database',
            content: 'Ausleihen (' + this.props.client.mediaRentalsCount + ')'
          },
          render: () => (
            <Table selectable>
              <Table.Body>
                {_.map(rentals, rental => (
                  <Table.Row
                    key={rental.id}
                    error={rental.startDate && !rental.endDate}
                    positive={
                      !rental.startDate || rental.endDate ? true : false
                    }
                  >
                    <Table.Cell>
                      <Link to={`/movies/${rental.media.borrowableId}`}>
                        {rental.media.filmcode}
                        <br />
                        {rental.media.title}
                      </Link>
                    </Table.Cell>
                    <Table.Cell textAlign="right">
                      {rental.startDate && rental.endDate ? (
                        <div>
                          {moment(rental.startDate).format('DD.MM.YYYY')}
                          <br />
                          {moment(rental.endDate).format('DD.MM.YYYY')}
                        </div>
                      ) : rental.startDate ? (
                        <div>
                          ausgeliehen
                          <br />
                          {moment(rental.startDate).format('DD.MM.YYYY')}
                        </div>
                      ) : (
                        <div>
                          reserviert am{' '}
                          {moment(rental.reservationDate).format('DD.MM.YYYY')}
                        </div>
                      )}
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
              {this.props.client.mediaRentalsCount > rentals.length && (
                <Table.Footer>
                  <Table.Row>
                    <Table.Cell colSpan="2" textAlign="center">
                      <Button
                        content="Alle laden"
                        onClick={this.loadAllRentals}
                        loading={this.state.rentalsLoading}
                        disabled={this.state.rentalsLoading}
                      />
                    </Table.Cell>
                  </Table.Row>
                </Table.Footer>
              )}
            </Table>
          )
        });
      }

      if (this.props.client.invoices && this.props.client.invoices.length) {
        panes.push({
          menuItem: { key: 'invoices', icon: 'money', content: 'Finanzen' },
          render: () => (
            <InvoiceHistoryPane
              invoices={this.props.client.invoices}
              baseHREF={this.props.baseHREF}
            />
          )
        });
      }
    }
    return (
      <Segment>
        <Dimmer active={this.props.requesting} inverted>
          <Loader active={true}>Lade…</Loader>
        </Dimmer>
        {this.props.client ? (
          <Button
            primary
            floated="right"
            circular
            icon="close"
            onClick={e => {
              this.props.selectClient(this.props.client);
            }}
          />
        ) : null}
        {this.props.client ? (
          <Button
            primary={
              this.props.activeClient &&
              this.props.client &&
              this.props.activeClient.id === this.props.client.id
            }
            floated="right"
            circular
            icon="shopping basket"
            onClick={e => {
              this.props.setClient(this.props.client);
            }}
          />
        ) : null}
        <Header style={{ marginTop: '0.75em' }}>
          {this.props.client ? this.props.client.fullName : 'Neu'}
        </Header>
        <Tab menu={{ secondary: true, pointing: true }} panes={panes} />
      </Segment>
    );
  }
}

export default ClientDetails;
