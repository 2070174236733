import React from "react";
import moment from "moment";

import DpButton from "./DatepickerButton"

export const DatepickerButton = DpButton

export const FormatDate = props => (
	<span className="formatted-date">
		{moment(props.rawDate).format("DD.MM.YY")}
	</span>
);

export const FormatDateTime = props => (
	<span className="formatted-date">
		{moment(props.rawDate).format("DD.MM.YY HH:mm")}
	</span>
);

export const FormatMoney = props => {
	const formatted = props ?
					  parseFloat(props)
						  .toFixed(2)
						  .replace(/\B(?=(\d{3})+(?!\d))/g, "'")
					: "0.00"
	return formatted;
}
