import React from "react";
import { Button, Card } from "semantic-ui-react"
import { Link } from "react-router-dom"
import CardFooterButtons from "../../components/CardFooterButtons"
import { FormatMoney } from "../../components/Util"


const OpenItem = (props) => {
	const { client } = props
	return (
		<Card>
			<Card.Content>
				<Card.Header>
					<Link to={`/clients/${client.id}`}>
						{client.fullName}
					</Link>
				</Card.Header>
				<strong>{FormatMoney(-client.amount)}</strong> Total
			</Card.Content>
			<CardFooterButtons>
				<Button
					compact
					onClick={e => props.handleToCashRegister(client)}
					icon="money"
					content="Zur Kasse"
				/>
			</CardFooterButtons>
		</Card>
	)
}
export default OpenItem
