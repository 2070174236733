import _ from "lodash"

import {
	ADD_RETURN_RESPONSE,
} from "../actions/returns"

import {
	SET_CLIENT_RESPONSE,
} from "../actions/app"

import {
	INVOICE_REQUEST,
	INVOICE_RESPONSE,
	CLOSE_ERROR,
} from "../actions/cashRegister"

export default (state = {
	query: "",
	requesting: false,
}, action) => {
	switch (action.type) {

		case INVOICE_REQUEST:
			return _.assign({}, state, {
				sendingInvoice: true,
			});

		case INVOICE_RESPONSE:
			if (action.error) {
				if (
					action.error.response &&
					action.error.response.body &&
					action.error.response.body.error
				) {
					return _.assign({}, state, {
						sendingInvoice: null,
						error: action.error.response.body.error,
					});
				}
				return _.assign({}, state, {
					sendingInvoice: null,
					error: "Es ist ein unbekannter Fehler aufgetreten",
				});
			}
			//
			return _.assign({}, state, {
				sendingInvoice: null,
			})

		case CLOSE_ERROR:
			return _.assign({}, state, {
				error: null,
			});

		case ADD_RETURN_RESPONSE:
			return _.assign({}, state, {
				needsReload: true,
			});

		case SET_CLIENT_RESPONSE:
			return _.assign({}, state, {
				needsReload: false,
			});

		default:
			return state;
	}
}
