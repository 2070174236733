export const MEMBERSHIPS_EXPIRING_REQUEST = "MEMBERSHIPS_EXPIRING_REQUEST"
export const MEMBERSHIPS_EXPIRING_RESPONSE = "MEMBERSHIPS_EXPIRING_RESPONSE"
export function requestExpiringMemberships() {
	return {
		type: MEMBERSHIPS_EXPIRING_REQUEST,
	}
}

export const MEDIA_RESERVATIONS_REQUEST = "MEDIA_RESERVATIONS_REQUEST"
export const MEDIA_RESERVATIONS_RESPONSE = "MEDIA_RESERVATIONS_RESPONSE"
export function requestMediaReservations() {
	return {
		type: MEDIA_RESERVATIONS_REQUEST,
	}
}

export const TRANSACTIONS_REQUEST = "TRANSACTIONS_REQUEST"
export const TRANSACTIONS_RESPONSE = "TRANSACTIONS_RESPONSE"
export function requestTransactions(date) {
	return {
		type: TRANSACTIONS_REQUEST,
		date: date,
	}
}

export const OPENTRANSACTIONS_REQUEST = "OPENTRANSACTIONS_REQUEST"
export const OPENTRANSACTIONS_RESPONSE = "OPENTRANSACTIONS_RESPONSE"
export function requestOpenTransactions(date) {
	return {
		type: OPENTRANSACTIONS_REQUEST,
	}
}

export const INVOICES_REQUEST = "INVOICES_REQUEST"
export const INVOICES_RESPONSE = "INVOICES_RESPONSE"
export function requestInvoices() {
	return {
		type: INVOICES_REQUEST,
	}
}

export const RENTAL_DELETE_REQUEST = "RENTAL_DELETE_REQUEST"
export const RENTAL_DELETE_RESPONSE = "RENTAL_DELETE_RESPONSE"
export function deleteRental(rentalId) {
	return {
		type: RENTAL_DELETE_REQUEST,
		rentalId: rentalId,
	}
}

export const REMIND_MEMBERSHIP_REQUEST = "REMIND_MEMBERSHIP_REQUEST"
export const REMIND_MEMBERSHIP_RESPONSE = "REMIND_MEMBERSHIP_RESPONSE"
export function remindMembership(membershipId) {
	return {
		type: REMIND_MEMBERSHIP_REQUEST,
		membershipId: membershipId,
	}
}

export const REMIND_RESERVATION_REQUEST = "REMIND_RESERVATION_REQUEST"
export function remindReservation(reservationId) {
	return {
		type: REMIND_RESERVATION_REQUEST,
		reservationId: reservationId,
	}
}
