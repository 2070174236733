import _ from "lodash"

import {
	REQUEST_TOKEN, RECEIVE_TOKEN,
	RECEIVE_LOGOUT,
	SET_CLIENT, SET_CLIENT_RESPONSE,
	REMOVE_CLIENT,
} from "../actions/app"

import {
	CHECKOUT_RESPONSE,
} from "../actions/basket"

import {
	ADD_RETURN_RESPONSE,
} from "../actions/returns"

import {
	UPDATE_CLIENT_RESPONSE,
	ADD_CLIENT_NOTE_RESPONSE,
} from "../actions/clients"

import {
	INVOICE_RESPONSE,
} from "../actions/cashRegister"

const stickyLangs = ["deu", "eng", "fra", "ita", "silent", "withoutSub"];

const defaultState = {
	ready: false,
	user: false,
	requesting: false,
	memberships: [],
	priceCategories: [],
}

export default (state = defaultState, action) => {
	switch (action.type) {
		case REQUEST_TOKEN:
			return _.assign({}, state, {
				requesting: true,
			})

		case RECEIVE_TOKEN:
			if (action.error) {
				let msg = "Fehler beim einloggen"
				if (action.error === "err") {
					msg = "Es konnte keine Verbindung zum Server hergestellt werden. " +
						"Bitte überprüfen Sie Ihre Netzwerkverbindung und versuchen Sie es erneut."
				} else if (action.error === 403) {
					msg = "Ihr Benutzername und/oder Passwort ist inkorrekt! " +
						"Bitte überprüfen Sie Ihre Eingabe."
				}

				return _.assign({}, state, {
					ready: false,
					requesting: false,
					user: null,
					error: action.isAuto ? null : msg,
				});
			} else {
				const langOptions = _.sortBy(_.map(action.data.langs, (lang, code) => ({
					label: `${lang} (${code})`,
					value: code,
					sticky: _.indexOf(stickyLangs, code) >= 0 ? 0 : 1,
				})), "sticky")

				return _.assign({}, state, {
					ready: true,
					requesting: false,
					user: action.data.user,
					error: null,
					...action.data,
					langOptions,
				});
			}

		case RECEIVE_LOGOUT:
			return _.assign({}, state, defaultState);

		case CHECKOUT_RESPONSE:
			if (action.error) {
				return state;
			}
			let newState = _.clone(state)
			// update rentals / reservations count after checkout
			if (action.data.baseData) {
				newState = _.assign(newState, { baseData: action.data.baseData })
			}
			// update client
			if (action.data.client) {
				newState = _.assign(newState, { client: action.data.client })
			}
			return newState

		case SET_CLIENT:
			if (action.client)
				return _.assign({}, state, {
					client: _.assign(action.client, {
						invoices: null,
						moneyTransactions: null,
						requesting: true
					}),
				})
			return _.assign({}, state, {
				client: null,
			})

		case SET_CLIENT_RESPONSE:
			return _.assign({}, state, {
				client: _.assign(
					{},
					state.client,
					action.data.id ? action.data : action.data.client,
					{
						requesting: false,
						notes: _.map(
							action.data.notes,
							(n) => {
								if (n.employeeId) {
									const _user = _.find(state.users, { id: n.employeeId })
									n.employeeName = (_user.firstName ?
													  _user.firstName + " " : "") +
												 (_user.lastName ? _user.lastName : "")
								} else {
									n.employeeName = "System"
								}
								return n
							}
						)
					}
				),
			})

		case REMOVE_CLIENT:
			return _.assign({}, state, {
				client: null,
			})

		case UPDATE_CLIENT_RESPONSE:
			// update client after changing detail infos
			if (!action.error && state.client && state.client.id === action.data.id) {
				return _.assign({}, state, {
					client: _.merge(state.client, action.data),
				})
			}
			return state

		case ADD_CLIENT_NOTE_RESPONSE:
			if(action.error) {
				// TODO: error output
				return _.assign({}, state, {
					creatingClientNote: false
				});
			}
				
			if (state.client && action.data.clientId === state.client.id) {
				const notes = state.client.notes ?
							  _.concat([action.data], state.client.notes) :
							  [ action.data ]

				return _.assign({}, state, {
					client: {
						...state.client,
						notes: notes,
					},
				});
			}
			return state;
			

		case INVOICE_RESPONSE:
			if (action.data && action.data.client) {
				return _.assign({}, state, {
					client: _.assign({}, state.client, action.data.client),
				})
			}
			return state

		case ADD_RETURN_RESPONSE:
			if (action.error || !state.client) {
				return state
			}

			if (state.client) {
				// flatten results
				const _clients = _.map(action.data.results, (r) => r.rental.client)
				const _client = _.find(_clients, { id: state.client.id })
				if(_client)
					return _.assign({}, state, {
						client: _.assign({}, state.client, _client),
					})
			}
			return state

		default:
			return state;
	}
}
