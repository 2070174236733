import React from "react"
import { Card, Button, Grid } from "semantic-ui-react"

import MembershipInfo from "../../components/Membership/MembershipInfo"
import { FormatMoney } from "../../components/Util"

const Client = (props) => {
	const { client, isActive, isDetail, setClient, selectClient } = props;

	return <Grid.Column>
		<Card fluid>
			<Card.Content>
				<Card.Header>
					<Grid verticalAlign="middle">
						<Grid.Column width={11}>
							{client.fullName}
						</Grid.Column>
						<Grid.Column width={2}>
							{client.code}
						</Grid.Column>
						<Grid.Column width={3} textAlign="right">
							<Button
								circular
								onClick={e => selectClient(client)}
								primary={isDetail}
								icon={isDetail ? "close" : "info"}
							/>
						</Grid.Column>
					</Grid>
				</Card.Header>
				<Card.Content>
					<Grid>
						<Grid.Column width={6}>
							{client.address}<br/>{client.postalCode} {client.town}
						</Grid.Column>
						<Grid.Column width={5}>
							{client.phone}<br/>{client.email}
						</Grid.Column>
						<Grid.Column width={3}>
							<MembershipInfo client={client} />
							{FormatMoney(client.balance)}
						</Grid.Column>
						<Grid.Column width={2} textAlign="right">
							<Button
								primary={isActive}
								onClick={e => setClient(e, client)}
								icon="shopping basket"
								circular
							/>
						</Grid.Column>
					</Grid>
				</Card.Content>
			</Card.Content>
		</Card>
	</Grid.Column>
}

export default Client
