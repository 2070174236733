import _ from "lodash"
import moment from "moment"

import {
	ADD_TO_BASKET,
	REMOVE_FROM_BASKET,
	SET_PRICE_CATEGORY,
	ADD_MEMBERSHIP,
	ADD_ADDITIONAL_COST,
	REMOVE_ADDITIONAL_COST,
	CLEAR_BASKET,
	CHECKOUT,
	CHECKOUT_RESPONSE,
	SET_RENTAL_DURATION,
	SET_ENTRY_DATE,
	CLOSE_BASKET_ERROR,
} from "../actions/basket"

export default (state = { rentals: [], reservations: [], additionalCosts: [] }, action) => {
	switch (action.type) {
		case ADD_TO_BASKET:
			const keyAdd = (action.isReservation ? "reservations" : "rentals")
			let _borrowable
			if(!action.borrowable) {
				_borrowable = {
					...action.media.borrowable,
					medias: null,
				}
			} else {
				_borrowable = {
					...action.borrowable,
					medias: null,
				}
			}

			return _.assign({}, state, {
				[keyAdd]: _.concat(state[keyAdd], {
					borrowable: _borrowable,
					media: action.media,
					duration: action.priceCat.duration,
					priceCat: action.priceCat,
					date: moment(),
					isReservation: action.isReservation,
				}),
				mode: null,
			});

		case REMOVE_FROM_BASKET:
			const keyRem = (action.isReservation ? "reservations" : "rentals")
			return _.assign({}, state, {
				[keyRem]: _.filter(state[keyRem], r => r.media.id !== action.media.id),
			})

		case SET_PRICE_CATEGORY:
			const groups = []
			const rentals = _.map(_.clone(state.rentals), rental => {
				rental.isSlave = false
				if (rental.media.id === action.rental.media.id) {

					rental.priceCat = action.priceCat
					rental.priceCatGroup = action.group
					rental.duration = Math.max(rental.duration, action.priceCat.duration)
				}
				if (
					rental.priceCat.isGroup &&
					typeof rental.priceCatGroup !== "undefined" &&
					rental.priceCatGroup !== false
				) {
					if(_.indexOf(groups, rental.priceCatGroup) === -1) {
						groups.push(rental.priceCatGroup)
					} else {
						rental.isSlave = true
					}
				}
				return rental;
			})

			// we set group idxs from 1 upcounting
			_.each(rentals, (r) => {
				if(r.priceCatGroup !== false) {
					r.priceCatGroup = _.indexOf(groups, r.priceCatGroup) + 1;
				}
			});

			return _.assign({}, state, {
				rentals: rentals
			})

		case ADD_MEMBERSHIP:
			if (action.membership === null) {
				return _.assign({}, state, {
					membership: null,
				})
			}
			if (!action.membership.startDate)
				action.membership.startDate = moment().format();
			return _.assign({}, state, {
				membership: _.clone(action.membership),
			})

		case ADD_ADDITIONAL_COST:
			let nextId = _.maxBy(state.additionalCosts, "id");
			if (nextId) nextId = nextId.id + 1
			else nextId = 1
			const costs = _.clone(state.additionalCosts);
			costs.push({
				id: nextId,
				amount: action.amount,
				note: action.note,
			})
			return _.assign({}, state, {additionalCosts: costs})

		case REMOVE_ADDITIONAL_COST:
			const costsRem = _.filter(state.additionalCosts, c => c.id !== action.id);
			return _.assign({}, state, {additionalCosts: costsRem})

		case CLEAR_BASKET:
			return _.assign({}, state, {
				rentals: [],
				reservations: [],
				additionalCosts: [],
				membership: null,
				mode: null,
			});

		case CHECKOUT:
			return _.assign({}, state, {
				sending: true,
			});

		case CHECKOUT_RESPONSE:
			if (action.error) {
				if (
					action.error.response &&
					action.error.response.body &&
					action.error.response.body.error
				) {
					return _.assign({}, state, {
						sending: null,
						error: action.error.response.body.error,
					});
				}
				return _.assign({}, state, {
					sending: null,
					error: "Es ist ein unbekannter Fehler aufgetreten",
				});
			}

			// we append returned states to ordered items
			// to be reflected on the frontend
			const ackBasket = {
				rentals: [],
				reservations: [],
				additionalCosts: [],
			}
			if (action.data.membership) {
				ackBasket.membership = _.merge(
					state.membership,
					action.data.membership
				)
			}
			if (action.data.rentals) {
				ackBasket.rentals = _.merge(
					state.rentals,
					action.data.rentals
				)
			}
			if (action.data.reservations) {
				ackBasket.reservations = _.merge(
					state.reservations,
					action.data.reservations
				)
			}
			if (action.data.additionalCosts) {
				ackBasket.additionalCosts = _.merge(
					state.additionalCosts,
					action.data.additionalCosts
				)
			}
			return _.assign({}, state, {
				sending: null,
				rentals: [],
				reservations: [],
				additionalCosts: [],
				membership: null,
				ackBasket: ackBasket,
				mode: "ack",
			})

		case SET_RENTAL_DURATION:
			return _.assign({}, state, {
				rentals: _.map(state.rentals, (r) => {
					if (r.media.id === action.entry.media.id) {
						r.duration = action.duration
					}
					return r;
				}),
			});

		case SET_ENTRY_DATE:
			if (action.entry.isReservation) {
				return _.assign({}, state, {
					reservations: _.map(state.reservations, (r) => {
						if (r.media.id === action.entry.media.id) {
							r.date = action.date
						}
						return r;
					}),
				});
			}
			return _.assign({}, state, {
				rentals: _.map(state.rentals, (r) => {
					if (r.media.id === action.entry.media.id) {
						r.date = action.date
					}
					return r;
				}),
			});

		case CLOSE_BASKET_ERROR:
			return _.assign({}, state, {
				error: null,
			});

		default:
			return state;
	}
}
