import { combineReducers } from "redux"
import { routerReducer } from "react-router-redux"

import app from "./app"
import dashboard from "./dashboard"
import movies from "./movies"
import clients from "./clients"
import basket from "./basket"
import returns from "./returns"
import cashRegister from "./cashRegister"

const main = combineReducers({
	movies,
	clients,
	app,
	dashboard,
	basket,
	returns,
	cashRegister,
	router: routerReducer,
})

export default main
