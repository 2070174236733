import React from 'react';
import {FormatDate} from "../Util"

const MembershipInfo = props => (
	<div>
	{
		props.client.membership ?
		<div>
			<strong>{props.client.membership.title}</strong><br />
			{
				props.client.membership.startDate && props.client.membership.endDate &&
				<div>
					<FormatDate rawDate={props.client.membership.startDate} />
					-
					<FormatDate rawDate={props.client.membership.endDate} />
				</div>
			}
		{
			props.client.membership.startDate && !props.client.membership.endDate &&
			<FormatDate rawDate={props.client.membership.startDate} />
		}
		</div>
		:
		<div>
			Keine Mitgliedschaft vorhanden
		</div>
	}
	</div>
);

export default MembershipInfo;
