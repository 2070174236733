import React from "react"

export function formatDuration(duration) {
  if (!duration) {
  return undefined;
  }
  const hours = Math.floor(duration/60);
  const minutes = duration % 60;
  return <small style={{display: 'block'}}>{hours ? `${hours}h` : ''}{minutes}min</small>;
}
