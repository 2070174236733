import React, { Component } from "react";
import { Link } from "react-router-dom"
import { Card, Button, Grid, Header } from "semantic-ui-react"
import Select from "react-select"
import request from "superagent"
import styled from "styled-components"
import moment from "moment";
import _ from "lodash"

import MembershipInfo from "../../components/Membership/MembershipInfo"
import { FormatMoney } from "../../components/Util"
import ClientOption from "./ClientOption"

const StyledDiv = styled.div`

.notes {
	height: 2.7em;
	position: relative;

	& .inner {
		overflow: hidden;
		bottom: -4px;
		width: 100%;
		position: absolute;
		left: -0.5em;
		right: -0.5em;
		top: -0.5em;
		padding: 0.5em;

		&:hover {
			bottom: auto;
			background-color: white;
		}
	}

	& .formatted-date {
		font-size: 80%;
		text-transform: uppercase;
	}

	& .note:not(:last-child) {
		border-bottom: 1px solid #ccc;
	}
}
`

class LinkedClient extends Component {
	constructor(props) {
		super(props)
		this.state = {}
		this.handleChange = this.handleChange.bind(this)
		this.searchClients = this.searchClients.bind(this)
	}

	handleChange({ value }) {
		this.props.setClient(value)
	}

	searchClients(input, callback) {
		request.get(this.props.baseHREF + "api/client?q=" + input + "&p=0")
			   .withCredentials()
			   .end((err, res) => {
				   if (err || !res.ok) {
					   return callback(err ? err : res.status)
				   }

				   callback(null, {
					   options: _.map(res.body.clients, client => ({
						   value: client,
						   label: client.fullName,
					   }))
				   })
			   });
	}

	render() {
		const openAmountSum = this.props.client ?
							  _.sumBy(
								  _.filter(
									  this.props.client.moneyTransactions,
									  { invoiceId: 0, type: "Booking" }
								  ),
								  "amount"
							  ) +
							  _.sumBy(
								  _.filter(
									  this.props.client.invoices,
									  (i) => i.balance !== 0
								  ),
								  "balance"
							  )
							  :
							  0
		return (
			<StyledDiv>
				<Header as="h3">Kunde</Header>

				<Card fluid>
					{this.props.client ?
						<Card.Content>
							<Grid columns="equal">
								<Grid.Row>
								<Grid.Column>
									<Link to={`/clients/${this.props.client.id}`}>
										{this.props.client.code} <i className="icon-pencil"></i>
									</Link>
									<br/>
									{this.props.client.fullName}
								</Grid.Column>
								<Grid.Column>
									{this.props.client.address}
									<br/>
									{this.props.client.postalCode}&nbsp;
									{this.props.client.town}
								</Grid.Column>
								<Grid.Column>
									{(this.props.client && this.props.client.notes && this.props.client.notes.length) ?
										<div className="notes">
											<div className="inner">
												{_.map(this.props.client.notes, note => {
													return <div key={note.id} className="note">
														<span className="formatted-date">
															{moment(note.date).format("DD.MM.YY, H:m")}
															{", "}
															{note.employeeName}
														</span>
														<p>{note.content}</p>
													</div>
												})}
											</div>
										</div>
									: null}
								</Grid.Column>
								<Grid.Column>
									<MembershipInfo client={this.props.client} />
								</Grid.Column>
								<Grid.Column textAlign="right" width={1}>
									Offen:
									<br/>
									{FormatMoney(-openAmountSum)}
								</Grid.Column>
								<Grid.Column textAlign="right" width={2}>
									Konto:
									<br/>
									{FormatMoney(this.props.client.balance)}
								</Grid.Column>
								<Grid.Column textAlign="right" width={2}>
									<div>
										<Button
											circular
											as={Link}
											to={`/clients/${this.props.client.id}`}
											icon="edit"
										/>
										<Button
											circular
											color="red"
											onClick={e => this.props.removeClient(null)}
											icon="delete"
										/>
									</div>
								</Grid.Column>
								</Grid.Row>
							</Grid>
						</Card.Content>
					: null}
				</Card>

				{!this.props.client ?
					<Select.Async
						name="client-search"
						placeholder="Kundensuche"
						loadingPlaceholder="Laden…"
						loadOptions={this.searchClients}
						onChange={this.handleChange}
						cache={false}
						autoload={false}
						ignoreAccents={false}
						filterOption={() => true}
						optionComponent={ClientOption}
						searchPromptText={"Suchbegriff eingeben"}
					/>
				: null}
			</StyledDiv>
		)
	}
}

export default LinkedClient;
