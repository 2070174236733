import React, { Component } from "react"
import { connect } from "react-redux"
import _ from "lodash"
import request from "superagent"
import moment from "moment"
import DatePicker from "react-datepicker"
import Select from "react-select"
import {
	Card, Button, Grid, Form,
	Input, Label, Modal, Header, Divider
} from "semantic-ui-react"

import styled from "styled-components"

import ScrollView from "../../components/ScrollView"
import LinkedClient from "../../components/LinkedClient/LinkedClient"
import { FormatMoney, FormatDate } from "../../components/Util"
import DatepickerButton from "../../components/Util/DatepickerButton"
import Status from "../../components/Util/Status"

import {
	checkout,
	addToBasket,
	removeFromBasket,
	addMembership,
	setPriceCat,
	addAdditionalCosts,
	removeAdditionalCosts,
	clearBasket,
	setRentalDuration,
	setEntryDate,
	closeError,
} from "../../actions/basket"

import {
	setClient,
	removeClient,
} from "../../actions/app"

import { requestBorrowables } from "../../actions/movies"
import { requestClients } from "../../actions/clients"

import MovieOption from "./MovieOption"
import Footer from "./Footer"
import AdditionalCost from "./AdditionalCost"
import Rental from "./Rental"
import Reservation from "./Reservation"

import Borrowable from "../Movies/Borrowable"

const ReservationGrid = styled.div`
display: grid;
grid-template-columns: repeat(2, 1fr);
gap: 2em;
> .card {
	margin: 0;
}
`

class Basket extends Component {
	constructor(props) {
		super(props)

		this.state = {
			newAdditionalCostsNote: "",
			newAdditionalCostsAmount: "",
			cashAmount: 0,
		}

		this.handleRemove = this.handleRemove.bind(this)
		this.handleCheckout = this.handleCheckout.bind(this)
		this.handleMembershipSelect = this.handleMembershipSelect.bind(this)
		this.setMembershipDate = this.setMembershipDate.bind(this)
		this.handlePriceCatChange = this.handlePriceCatChange.bind(this)
		this.handleAddAdditionalCosts = this.handleAddAdditionalCosts.bind(this)
		this.handleRemoveAdditionalCosts = this.handleRemoveAdditionalCosts.bind(this)
		this.handleEditAdditionalCosts = this.handleEditAdditionalCosts.bind(this)
		this.handleChangeAdditionalCostsNote = this.handleChangeAdditionalCostsNote.bind(this)
		this.handleChangeAdditionalCostsAmount = this.handleChangeAdditionalCostsAmount.bind(this)
		this.setRentalDuration  = this.setRentalDuration.bind(this)
		this.setEntryDate  = this.setEntryDate.bind(this)
		this.searchMovies = _.debounce(this.searchMovies.bind(this), 200)
		this.handleAddMovie = this.handleAddMovie.bind(this)
		this.calcRentalPrice = this.calcRentalPrice.bind(this)
		this.calcTotals = this.calcTotals.bind(this)
		this.removeClient = this.removeClient.bind(this)
	}

	componentWillUnmount() {
		if (this.props.mode === "ack") {
			this.props.clearBasket();
		}
	}

	handleRemove(media, isReservation = false) {
		this.props.removeFromBasket(media, isReservation)
	}

	handleCheckout() {
		this.props.checkout()
	}

	handleMembershipSelect(e, select) {
		const id = parseInt(select.value, 0)
		const mShip = _.find(this.props.memberships, { id: id });
		if (mShip) {
			this.props.setMembership(_.assign(this.props.membership, mShip))
		} else {
			this.props.setMembership(null)
		}
	}

	setMembershipDate(date) {
		this.props.setMembership(_.assign({}, this.props.membership, {
			startDate: date.format()
		}))
	}

	setRentalDuration(e, select, entry) {
		let val = parseInt(select.value, 10)
		if(_.isNaN(val))
			val = 0;
		val = Math.max(entry.priceCat.duration, val)
		this.props.setRentalDuration(val, entry)
	}

	setEntryDate(date, entry) {
		this.props.setEntryDate(date, entry)
	}

	handleChangeDuration(e) {
		const val = parseInt(e.target.value, 10);
		if (_.isNaN(val))
			return;
		this.setState({
			duration: val
		});
	}

	handlePriceCatChange(e, select, rental) {
		// split value by "-" to get cat id and group
		const val = select.value
		const splitted = val.split("-")
		const id = parseInt(splitted[0], 10)
		const group = parseInt(splitted[1], 10)

		const priceCat = _.find(
			this.props.priceCategories,
			{id: id}
		);
		if (priceCat) {
			this.props.setPriceCat(rental, priceCat, group)
		}
	}

	handleAddAdditionalCosts(e) {
		this.setState({
			newAdditionalCostsNote: "",
			newAdditionalCostsAmount: "",
		});
		this.props.addAdditionalCosts(this.state.newAdditionalCostsAmount, this.state.newAdditionalCostsNote);
	}

	handleRemoveAdditionalCosts(id) {
		this.props.removeAdditionalCosts(id);
	}

	handleEditAdditionalCosts(id) {
		const costs = _.find(this.props.additionalCosts, {id: id});
		if (costs) {
			this.setState({
				newAdditionalCostsNote: costs.note,
				newAdditionalCostsAmount: costs.amount,
			});
			this.handleRemoveAdditionalCosts(id);
		}
	}

	handleChangeAdditionalCostsNote(e) {
		this.setState({
			newAdditionalCostsNote: e.target.value
		});
	}

	handleChangeAdditionalCostsAmount(e) {
		this.setState({
			newAdditionalCostsAmount: e.target.value
		});
	}

	calcRentalPrice(rental) {
		if (rental.price) return parseInt(rental.price, 10)
		if (rental.isSlave) return 0

		const baseCost = parseInt(rental.priceCat.price, 10)
		const postDuration = Math.max(0, rental.duration - parseInt(rental.priceCat.duration, 10))
		const postCost = parseInt(rental.priceCat.postPrice, 10) *
		Math.ceil(postDuration / rental.priceCat.postDuration)
		const cost = baseCost + postCost

		if (this.props.membership) return cost * (1 - this.props.membership.discount)
		if (this.props.client && this.props.client.membership)
			return cost * (1 - this.props.client.membership.discount)
		return cost
	}

	calcTotals() {
		const totals = {
			total: 0,
		}

		// amounts for membership
		if (this.props.membership) {
			totals.total += parseInt(this.props.membership.price, 10)
		}

		// amounts for rentals
		if (this.props.rentals) {
			_.each(this.props.rentals, (r) => {
				if (r.priceCat) totals.total = totals.total + this.calcRentalPrice(r)
			})
		}

		// amounts for additional costs
		if (this.props.additionalCosts) {
			_.each(this.props.additionalCosts, (c) => {
				if (c.amount) totals.total = totals.total + parseInt(c.amount, 10)
			})
		}

		return totals
	}

	searchMovies(input, callback, isReservation) {
		const uuid = Math.random().toString(36).substr(2, 16);
		this.currentOp = uuid
		request.get(this.props.baseHREF + "api/borrowable?q=" + JSON.stringify({
			ignorePriority: true,
			code: input,
			title: input,
		}) + "&p=0")
		.withCredentials()
		.end((err, res) => {
			if (err || !res.ok) {
				callback(err ? err : res.status)
				return
			}

			// If we're not the most recent ajax call, then ignore our result
			if (this.currentOp !== uuid) return

			// Map list of borrowables with medias to a list of medias
			// with their movie attached
			callback(null, {
				options: _.flatMap(res.body.borrowables, borrowable => {
					return _.map(
						_.filter(borrowable.medias, (media) => media.status === "rentable"),
						media => {
							const disabled = (!isReservation && !media.available) ? true :
											 (_.find(
												 this.props.reservations,
												 r => r.media.id === media.id
											 )) ? true :
											 (_.find(
												 this.props.rentals,
												 r => r.media.id === media.id
											 )) ? true :
											 false
							return {
								label: borrowable.customTitle,
								value: {
									media,
									borrowable,
								},
								disabled: disabled,
							}
						}
					)
				})
			})
		});
	}

	handleAddMovie({ value }, isReservation) {
		this.props.addToBasket(value.borrowable, value.media, isReservation)
	}

	removeClient() {
		if (this.props.mode === "ack") {
			this.props.clearBasket();
		}

		this.props.removeClient();
	}

	render() {
		// dynamically create Groups A / B / C / …
		const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("")
		const priceCats = []
		_.each(this.props.priceCategories, (cat) => {
			if (cat.isGroup) {
				const groups = [];
				_.each(this.props.rentals, (r) => {
					if(r.priceCat.id === cat.id) {
						if (_.indexOf(groups, r.priceCatGroup) === -1) {
							priceCats.push(_.assign({}, cat, {
								key: `${cat.id}-${r.priceCatGroup}`,
								groupIdx: r.priceCatGroup,
								groupKey: alphabet[groups.length]
							}))
							groups.push(r.priceCatGroup)
						}
					}
				})
				let priceCatGroup = _.max(groups) + 1
				if (_.isNaN(priceCatGroup)) priceCatGroup = 0
				priceCats.push(_.assign({}, cat, {
					id: `${cat.id}-${priceCatGroup}`,
					key: `${cat.id}-${priceCatGroup}`,
					groupIdx: priceCatGroup,
					groupKey: alphabet[groups.length]
				}))
			} else {
				priceCats.push(_.assign({}, cat, {key: `${cat.id}-`}))
			}
		})

		const displayPriceCats = _.map(priceCats, (cat) => ({
			key: cat.key,
			value: cat.key,
			text: `${cat.title} ${cat.groupKey ? cat.groupKey : ""}`,
		}))

		const mShipOptions = []
		if (this.props.client && this.props.client.membership) {
			mShipOptions.push({
				key: 0,
				value: 0,
				text: "Bestehende Mitgliedschaft",
			})
		}
		_.each(this.props.memberships, (m) => {
			mShipOptions.push({
				key: m.id,
				value: m.id,
				text: `${m.title} ${FormatMoney(m.price)}`,
			})
		})
		
		const availableReservations = (this.props.client?.mediaReservations ?? [])
			.filter(res => res.media.available)
			.filter(res => !this.props.rentals.find(rental => rental.media.id === res.media.id));

		const noMembership = this.props.client && !this.props.client.membership
		return (
			<ScrollView
				headerHeight="11em"
				footerHeight="5.8em"
				header={(
						<LinkedClient
							client={this.props.client}
							baseHREF={this.props.baseHREF}
							setClient={this.props.setClient}
							removeClient={this.removeClient}
							performSearch={this.props.performClientSearch}
						/>
					)}
				footer={(
						<Footer {...this.props}
							calcTotals={this.calcTotals}
							clearBasket={this.props.clearBasket}
							handleCheckout={this.handleCheckout}
						/>
					)}
			>
				{(this.props.client && !this.props.client.requesting) ?
					<div>
						<Header as="h3">Mitgliedschaft</Header>
						<Card fluid>
							<Card.Content>
								<Grid verticalAlign="middle">
									<Grid.Column width={6}>
										{(this.props.membership && this.props.mode) === "ack" ?
											<span>{this.props.membership.title}</span>
										:
											<Form.Select
												fluid
												value={(this.props.membership ? this.props.membership.id : 0)}
												options={mShipOptions}
												onChange={this.handleMembershipSelect}
												disabled={this.props.mode === "ack"}
											/>
										}
									</Grid.Column>
									<Grid.Column width={4} textAlign="right">
										{this.props.membership ?
											<span>Gültig ab:</span>
										: null}
									</Grid.Column>
									<Grid.Column width={2}>
										{(this.props.mode === "ack" && this.props.membership) ?
											<FormatDate rawDate={moment(this.props.membership.startDate)} />
										: this.props.membership &&
											<DatePicker
												disabled={noMembership}
												customInput={<DatepickerButton disabled={noMembership} value={new moment(this.props.membership.startDate)} fluid />}
												dateFormat="DD.MM.YYYY"
												selected={moment(this.props.membership.startDate)}
												onChange={this.setMembershipDate}
											/>
										}
									</Grid.Column>
									<Grid.Column width={2} textAlign="right">
										{this.props.membership ?
											<span>{FormatMoney(this.props.membership.price)}</span>
										:
											<span>{FormatMoney(0)}</span>
										}
									</Grid.Column>
									<Grid.Column width={2} verticalAlign="middle" textAlign="right">
										{(this.props.membership && this.props.mode === "ack") &&
											<Status
												status={this.props.membership.status}
												message={this.props.membership.errorMessage}
											/>
										}
									</Grid.Column>
								</Grid>
							</Card.Content>
						</Card>
					</div>
				: null}
				<Divider />
				{availableReservations.length ? <>
					<Header as="h3">Verfügbare Reservationen</Header>
					<ReservationGrid>
						{availableReservations.map(reservation => <Borrowable
							key={reservation.id}
							borrowable={reservation.media.borrowable}
							medias={[reservation.media]}
							addToBasket={this.props.addToBasket}
						/>)}
					</ReservationGrid>
				</> : undefined}
				<Header as="h3">Filme</Header>
				<Card fluid>
					{(this.props.rentals && this.props.rentals.length) ?
						_.map(this.props.rentals, rental => {
							const alreadyRented = this.props.client && rental.media.rentalsInfo &&
								rental.media.rentalsInfo[this.props.client.id];

							return <Rental
								key={rental.media.id + "-" + rental.media.filmcode}
								rental={rental}
								mode={this.props.mode}
								alreadyRented={alreadyRented}
								priceCategories={displayPriceCats}
								onSetEntryDate={this.setEntryDate}
								onSetRentalDuration={this.setRentalDuration}
								onPriceCatChange={this.handlePriceCatChange}
								calcRentalPrice={this.calcRentalPrice}
								onRemove={this.handleRemove}
							/>
						})
					: null}
				<Select.Async
					placeholder="Filme suchen"
					loadingPlaceholder="Laden…"
					loadOptions={(input, callback) => { this.searchMovies(input, callback, false) }}
					onChange={(value) => { this.handleAddMovie(value, false) }}
					cache={false}
					autoload={false}
					ignoreAccents={false}
					filterOption={() => true}
					optionComponent={MovieOption}
					searchPromptText="Suchbegriff eingeben"
					noResultsText="Keine Filme gefunden"
					className="tight"
					disabled={this.props.mode === "ack"}
				/>
				</Card>

				<Divider />
				<Header as="h3">Reservationen</Header>
				<Card fluid>
					{(this.props.reservations && this.props.reservations.length) ?
						_.map(this.props.reservations, reservation =>
							<Reservation
								key={reservation.media.id + "-" + reservation.media.filmcode}
								mode={this.props.mode}
								reservation={reservation}
								onSetEntryDate={this.setEntryDate}
								onRemove={this.handleRemove}
							/>
						)
					: null}
				<Select.Async
					placeholder="Reservationen suchen"
					loadingPlaceholder="Laden…"
					loadOptions={(input, callback) => { this.searchMovies(input, callback, true) }}
					onChange={(value) => { this.handleAddMovie(value, true) }}
					cache={false}
					autoload={false}
					ignoreAccents={false}
					filterOption={() => true}
					optionComponent={MovieOption}
					searchPromptText="Suchbegriff eingeben"
					noResultsText="Keine Filme gefunden"
					className="tight"
					disabled={this.props.mode === "ack"}
				/>
				</Card>

				<Divider />
				<Header as="h3">Zusatzkosten</Header>
				<Card fluid>
					{(this.props.additionalCosts && this.props.additionalCosts.length) ?
						_.map(this.props.additionalCosts, cost =>
							<AdditionalCost
								key={cost.id}
								cost={cost}
								mode={this.props.mode}
								onEdit={this.handleEditAdditionalCosts}
								onRemove={this.handleRemoveAdditionalCosts}
							/>
						)
					: null}
				<Card.Content>
					<Form>
						<Grid>
							<Grid.Column width={12}>
								<Form.Field>
									<Form.Input
										fluid
										placeholder="Notiz"
										name="note"
										value={this.state.newAdditionalCostsNote}
										onChange={this.handleChangeAdditionalCostsNote}
										disabled={this.props.mode === "ack"}
									/>
								</Form.Field>
							</Grid.Column>
							<Grid.Column width={2} textAlign="right">
								<Form.Field>
									<Input
										fluid
										labelPosition="right"
										placeholder="Betrag"
										name="amount"
										value={this.state.newAdditionalCostsAmount}
										onChange={this.handleChangeAdditionalCostsAmount}
										disabled={this.props.mode === "ack"}
									>
										<Label basic>CHF</Label>
										<input />
									</Input>
								</Form.Field>
							</Grid.Column>
							<Grid.Column width={2} textAlign="right">
								<Button
									onClick={this.handleAddAdditionalCosts}
									disabled={
										this.props.mode === "ack" ||
										isNaN(parseFloat(this.state.newAdditionalCostsAmount)) ||
										!this.state.newAdditionalCostsNote
									}
									icon="plus"
									circular
								/>
							</Grid.Column>
						</Grid>
					</Form>
				</Card.Content>
				</Card>

				<Modal basic open={this.props.error} onClose={this.props.closeErrorModal}>
					<Header icon="warning sign" content="Fehler" />
					<Modal.Content>
						<p>
							{this.props.error}
						</p>
					</Modal.Content>
					<Modal.Actions>
						<Button
							basic
							color="red"
							inverted
							onClick={this.props.closeErrorModal}
							icon="remove"
							content="Ok"
						/>
					</Modal.Actions>
				</Modal>
			</ScrollView>
		)
	}
}

const mapStateToProps = (state) => {
	if (state.basket.mode === "ack") {
		return _.assign({}, state.basket, state.basket.ackBasket, {
			memberships: state.app.memberships,
			priceCategories: state.app.priceCategories,
			client: state.app.client,
			baseHREF: state.app.baseHREF,
		});
	}
	return {
		...state.basket,
		memberships: state.app.memberships,
		priceCategories: state.app.priceCategories,
		client: state.app.client,
		baseHREF: state.app.baseHREF,
	};
}

const mapDispatchToProps = (dispatch) => {
	return {
		checkout: () => dispatch(checkout()),
		addToBasket: (borrowable, media, isReservation) => dispatch(addToBasket(borrowable, media, isReservation)),
		removeFromBasket: (media, isReservation = false) => dispatch(removeFromBasket(media, isReservation)),
		setClient: (client) => dispatch(setClient(client)),
		removeClient: () => dispatch(removeClient()),
		setMembership: (membership) => dispatch(addMembership(membership)),
		setPriceCat: (rental, priceCat, group) => dispatch(setPriceCat(rental, priceCat, group)),
		addAdditionalCosts: (amount, note) => dispatch(addAdditionalCosts(amount, note)),
		removeAdditionalCosts: (id) => dispatch(removeAdditionalCosts(id)),
		clearBasket: (gotoCashRegister = false) => dispatch(clearBasket(gotoCashRegister)),
		performMovieSearch: (query) => dispatch(requestBorrowables(query)),
		performClientSearch: (query) => dispatch(requestClients(query)),
		setRentalDuration: (duration, rental) => dispatch(setRentalDuration(duration, rental)),
		setEntryDate: (date, rental) => dispatch(setEntryDate(date, rental)),
		closeErrorModal: () => dispatch(closeError()),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Basket)
