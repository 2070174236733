import React from "react"
import moment from "moment"
import { Grid, Header, Button, Card, Label } from "semantic-ui-react"
import _ from "lodash"
import styled from "styled-components"
import MediaMetaInfos from "../../components/BorrowableDetails/MediaMetaInfos"

const MediaItem = styled.div`
	display: flex;
	flex-flow: row wrap;
	justify-content: space-between;
	align-items: center;
`

const STATUS_LABELS = {
	rentable: {
		color: "green",
		icon: "checkmark",
		text: "Ausleihbar",
	},
	unsorted: {
		color: "green",
		icon: "archive",
		text: "Noch in Originalhülle",
	},
	basement: {
		color: "grey",
		icon: "archive",
		text: "Im Keller",
	},
	buyable: {
		color: "blue",
		icon: "money",
		text: "Kaufbar",
	},
	damaged: {
		color: "grey",
		icon: "dont",
		text: "Beschädigt",
	},
	lost: {
		color: "grey",
		icon: "trash",
		text: "Verschollen",
	},
	deleted: {
		color: "black",
		icon: "trash",
		text: "Gelöscht",
	}
}

export const STATUS_OPTIONS = Object
	.entries(STATUS_LABELS)
	.map(([value, option]) => ({value, label: option.text}));

export function getStatusLabel(status) {
	if (status in STATUS_LABELS) {
		return STATUS_LABELS[status];
	}
	return {
		color: "grey",
		icon: "question circle",
		text: "Unbekannt",
	};
}

const Borrowable = (props) => {
	const { borrowable, isActive, selectBorrowable } = props;
	const medias = props.medias ?? borrowable.medias;

	return <Card fluid>
		<Card.Content>
			<Grid>
				<Grid.Column width={selectBorrowable ? 14 : 16}>
					<Header as="h3">
						<Header.Content>
							{borrowable.customTitle}
							{
								(
									borrowable.customOriginalTitle &&
									borrowable.customOriginalTitle.trim() &&
									borrowable.customOriginalTitle !== borrowable.customTitle
								)
								?
								` - ${borrowable.customOriginalTitle}`
								: null
							}
							{borrowable.discTitle && " - " + borrowable.discTitle}
							<Header.Subheader>
								{borrowable.customDirector}
								{
									borrowable.motionPictures && borrowable.motionPictures.length ?
									_.map(
										borrowable.motionPictures,
										(mp) => (
											<span key={`${borrowable.id}-${mp.id}`}>
												{" "}
												(
												{mp.originCountry}, {moment(
														mp.releaseDate
													).format("Y")}
												)
											</span>
										)
									)
									: null
								}
								{borrowable.customActor ? <br /> : undefined}
								{borrowable.customActor}
							</Header.Subheader>
						</Header.Content>
					</Header>
				</Grid.Column>
				{selectBorrowable ? <Grid.Column width={2} textAlign="right">
					{isActive ?
						<Button
							primary
							circular
							onClick={e => selectBorrowable(null)}
							icon="close"
						/>
					:
						<Button
							circular
							onClick={e => selectBorrowable(borrowable)}
							icon="info"
						/>
					}
				</Grid.Column> : undefined}
			</Grid>
		</Card.Content>
		{_.map(medias, (media) => {
			const statusLabel = getStatusLabel(media.status);
			return <Card.Content key={media.id}>
				{!media.available ?
					<Label
						ribbon
						color="red"
						icon="lock"
						content="Ausgeliehen"
					/>
				: null}
				{(media.reservations && media.reservations.length) ?
					<Label
						ribbon={media.available}
						color="pink"
						icon="tag"
						content={`${media.reservations.length} Reservation${media.reservations.length > 1 ? "en" : ""}`}
					/>
				: null}
				{media.status !== "rentable" ?
					<Label
						color={statusLabel.color}
						icon={statusLabel.icon}
						content={statusLabel.text}
					/>
				: null}

				<MediaItem>
					<div>
						<strong className={`ui header ${media.codeMatch ? 'green' : ''}`} style={{fontSize: 'inherit'}}>{media.filmcode}</strong>
						{" "}
						<MediaMetaInfos
							media={media}
							borrowable={borrowable}
						/>
					</div>
					<div>
						{media.isBasketRes ?
							<Button
								onClick={e => props.removeFromBasket(media, true)}
								icon="calendar"
								color="red"
								circular
							/>
						: !media.isRes && !media.isBasketRental &&
							<Button
								circular
								onClick={
									(e) => {
										props.addToBasket(borrowable, media, true)
									}
										}
								icon="calendar"
							/>
						}
						{media.isBasketRental ?
							<Button
								circular
								color="red"
								onClick={e => props.removeFromBasket(media)}
								icon="shopping basket"
							/>
						: media.available && !media.isBasketRes &&
							<Button
								circular
								primary
								onClick={e => props.addToBasket(borrowable, media)}
								icon="shopping basket"
							/>
						}
					</div>
				</MediaItem>
			</Card.Content>;
		})}
	</Card>
}

export default Borrowable
