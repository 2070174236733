import React from "react"
import _ from "lodash"
import { Menu } from 'semantic-ui-react'

const Pagination = (props) => {
	if (props.maxPage === 0) return null

	let startPage = 0
	let endPage = props.maxPage

	if (props.maxPage > 10) {
		startPage = props.page - 5
		if (startPage < 0) startPage = 0
		endPage = startPage + 10
		if (endPage > props.maxPage) {
			startPage -= endPage - props.maxPage
		}
		if (startPage < 0) startPage = 0
		endPage = startPage + 10
	}
	return (
		<Menu pagination>
			<Menu
				.Item
				icon="angle left"
				disabled={props.page === 0}
				onClick={e => props.handlePageChange(e, props.page - 1)}
			/>
			<Menu
				.Item
				icon="angle double left"
				disabled={props.page === 0}
				onClick={e => props.handlePageChange(e, 0)}
			/>
			{
				_.map(_.range(startPage, endPage), p => (
					<Menu
						.Item
						key={p}
						name={`${(p+1)}`}
						active={props.page === p}
						onClick={e => props.handlePageChange(e, p)}
					/>
				))
			}
			<Menu
				.Item
				icon="angle double right"
				disabled={props.page === props.maxPage-1}
				onClick={e => props.handlePageChange(e, props.maxPage - 1)}
			/>
			<Menu
				.Item
				icon="angle right"
				disabled={props.page === props.maxPage-1}
				onClick={e => props.handlePageChange(e, props.page + 1)}
			/>
		</Menu>
	)
}

export default Pagination
