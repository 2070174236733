import React from "react"
import styled from "styled-components"

const Wrap = styled.div`
display: flex;
flex-flow: row wrap;

> .button {
	margin: 0!important;
	display: block;
	flex: 1;
	border-bottom: none;
	border-left: none;

	&:last-child{
		border-right: none;
	}
}
`

const CardFooterButtons = (props) => (
	<Wrap>
		{props.children}
	</Wrap>
)

export default CardFooterButtons
