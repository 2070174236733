import React, { useRef, useEffect } from "react"
import styled from "styled-components"

const Wrap = styled.div`
	position: absolute;
	overflow: hidden;
	left: ${props => props.left ? props.left : 0};
	top: ${props => props.top ? props.top : 0};
	right: ${props => props.right ? props.right : 0};
	bottom: ${props => props.bottom ? props.bottom : 0};
`

const List = styled.div`
	position: absolute;
	overflow: hidden;
	left: 0;
	top: 0;
	right: ${props => props.detailsWidth ? props.detailsWidth : 0};
	bottom: 0;
`

const Details = styled.div`
	position: absolute;
	overflow-x: hidden;
	overflow-y: auto;
	width: ${props => props.detailsWidth ? props.detailsWidth : 0};
	top: 0;
	right: 0;
	bottom: 0;
	padding: 1em;
`

const Main = styled.div`
	padding: 1em;
	position: absolute;
	z-index: 10;
	overflow-x: hidden;
	overflow-y: scroll;
	left: 0;
	top: ${props => props.headerHeight ? props.headerHeight : 0};
	right: 0;
	bottom: ${props => props.footerHeight ? props.footerHeight : 0};
`

const Header = styled.div`
	position: absolute;
	z-index: 20;
	left: 0;
	top: 0;
	right: 0;
	height: ${props => props.headerHeight ? props.headerHeight : 0};
	padding: 1em;
`

const Footer = styled.div`
	position: absolute;
	z-index: 20;
	left: 0;
	height: ${props => props.footerHeight ? props.footerHeight : 0};
	right: 0;
	bottom: 0;
	padding: 1em;
`

const ScrollView = (p) => {
	const { resetScroll, ...props } = p;
	
	const scrollerRef = useRef();
	
	useEffect(() => {
		if (scrollerRef.current) {
			scrollerRef.current.scrollTop = 0;
		}
	}, [resetScroll, scrollerRef]);
	
	return <Wrap {...props}>
		<List {...props}>
			<Header {...props}>
				{props.header}
			</Header>
			<Main {...props} innerRef={scrollerRef}>
				{props.children}
			</Main>
			<Footer {...props}>
				{props.footer}
			</Footer>
		</List>
		<Details {...props}>
			{props.details}
		</Details>
	</Wrap>
}

export default ScrollView
