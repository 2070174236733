import React from "react"
import { Card, Grid, Button, Icon } from "semantic-ui-react"
import DatePicker from "react-datepicker"
import { Link } from "react-router-dom"

import Status from "../../components/Util/Status"
import { FormatDate } from "../../components/Util"
import DatepickerButton from "../../components/Util/DatepickerButton"
import MediaMetaInfos from "../../components/BorrowableDetails/MediaMetaInfos"

const Reservation = (props) => {
	const { mode, reservation, onSetEntryDate, onRemove } = props;

	return <Card.Content>
		<Grid verticalAlign="middle">
			<Grid.Column width={6}>
				<Link to={`/movies/${reservation.borrowable.id}`}>
					<strong><Icon name="film" /> {reservation.media.filmcode}</strong>
				</Link>
				<MediaMetaInfos media={reservation.media} borrowable={reservation.borrowable} />
				<br/>
				{reservation.borrowable.customTitle}
			</Grid.Column>
			<Grid.Column width={6} textAlign="right">
				<span>Reservation ab:</span>
			</Grid.Column>
			<Grid.Column width={2}>
				{mode === "ack" ?
					<FormatDate rawDate={reservation.date}/>
				:
					<DatePicker
						customInput={<DatepickerButton fluid />}
						dateFormat="DD.MM.YYYY"
						selected={reservation.date}
						onChange={(date) => onSetEntryDate(date, reservation)}
					/>
				}
			</Grid.Column>
			<Grid.Column width={2} verticalAlign="middle" textAlign="right">
				{mode === "ack" ?
					<Status
						status={reservation.status}
						message={reservation.errorMessage}
					/>
				:
					<div>
						<Button
							as={Link}
							to={`/movies/${reservation.borrowable.id}`}
							icon="info"
							circular
						/>
						<Button
							onClick={e => onRemove(reservation.media, true)}
							icon="trash"
							circular
						/>
					</div>
				}
			</Grid.Column>
		</Grid>
	</Card.Content>
}

export default Reservation
