export const REQUEST_TOKEN = "REQUEST_TOKEN"
export const RECEIVE_TOKEN = "RECEIVE_TOKEN"

export function requestToken(username = "", password = "") {
	return {
		type: REQUEST_TOKEN,
		username: username,
		password: password,
	}
}

export const REQUEST_LOGOUT = "REQUEST_LOGOUT"
export const RECEIVE_LOGOUT = "RECEIVE_LOGOUT"
export function requestLogout() {
	return {
		type: REQUEST_LOGOUT,
	}
}

export const SET_CLIENT = "SET_CLIENT"
export const SET_CLIENT_RESPONSE = "SET_CLIENT_RESPONSE"
export function setClient(client) {
	return {
		type: SET_CLIENT,
		client: client,
	}
}


export const REMOVE_CLIENT = "REMOVE_CLIENT"
export function removeClient() {
	return {
		type: REMOVE_CLIENT,
	}
}
