import _ from "lodash"

import {
	ADD_TO_BASKET,
	addMembership,
} from "../actions/basket"

import {
	requestRented,
} from "../actions/returns"

import {
	SET_CLIENT_RESPONSE,
	REMOVE_CLIENT,
} from "../actions/app"

const app = ({ dispatch, getState }) => next => action => {
	const state = getState()

	switch (action.type) {
		// Add the price category to the movie because it only has the price category id
		case ADD_TO_BASKET:
			action.priceCat = _.find(state.app.priceCategories, { id: action.media.priceCatId })
			break;

		case SET_CLIENT_RESPONSE:
			const client = action.data;
			if (client) {
				if (client.membership) {
					dispatch(addMembership(null));
				} else {
					// we add the default mShip to basket if client has none yet
					const m = _.clone(_.find(state.app.memberships, { isDefault: true }));
					dispatch(addMembership(m));
				}
			}
			// we request rented
			if (client) dispatch(requestRented(client.id))

			break;

		case REMOVE_CLIENT:
			// we request rented
			dispatch(requestRented())
			break;

		default:
			break;
	}

	next(action)
}

export default app
