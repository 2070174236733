import React, { Component } from "react";

class ClientOption extends Component {
	constructor(props) {
		super(props)

		this.handleMouseDown = this.handleMouseDown.bind(this)
		this.handleMouseEnter = this.handleMouseEnter.bind(this)
		this.handleMouseMove = this.handleMouseMove.bind(this)
	}
	handleMouseDown (event) {
		event.preventDefault();
		event.stopPropagation();
		this.props.onSelect(this.props.option, event);
	}
	handleMouseEnter (event) {
		this.props.onFocus(this.props.option, event);
	}
	handleMouseMove (event) {
		if (this.props.isFocused) return;
		this.props.onFocus(this.props.option, event);
	}
	render() {
		const { className, option } = this.props
		const client = option.value

		return (
			<div
				style={{display: "flex", flexFlow: "row wrap"}}
				onMouseDown={this.handleMouseDown}
				onMouseEnter={this.handleMouseEnter}
				onMouseMove={this.handleMouseMove}
				className={className}
			>
				<div style={{width: "25%"}}>
					{client.fullName}
				</div>
				<div style={{width: "25%"}}>
					{client.code}
				</div>
				<div style={{width: "25%"}}>
					{client.address}
				</div>
				<div style={{width: "25%"}}>
					{client.postalCode} {client.town}
				</div>
			</div>
		)
	}
}

export default ClientOption;
