import React from "react"
import { FormatMoney } from "../../components/Util"
import {
	Card, Icon, Table,
} from "semantic-ui-react"
import styled from "styled-components"
import _ from "lodash"
import moment from "moment"

const StyledDiv = styled.div`
.ui.basic.table {
	background: transparent;
	border: 0;

	& td, & th {
		padding: 0;
		border-top: 1px solid rgba(34,36,38,.1);
	}

	& th {
		font-size: 90%;
	}

	& tr:first-child {
		& td, & th {
			border-top: none;
	}
}
`
/*
 * invoice history pane
 */
const InvoiceHistoryPane = (props) => (
	<StyledDiv>
		{
		_.map(_.reverse(_.sortBy(props.invoices, "invoiceDate")), (invoice) => {
		const payments = _.filter(
			invoice.moneyTransactions,
			t => t.type === "Payment"
		)

		const bookings = _.filter(
			invoice.moneyTransactions,
			t => t.type !== "Payment"
		)

		return (
				<Card fluid key={invoice.id}>
					<Card.Content>
						<Table basic>
							<Table.Body>
								<Table.Row>
									<Table.HeaderCell>
										Nr
									</Table.HeaderCell>
									<Table.Cell textAlign="right">
										{invoice.invoiceNumber}
									</Table.Cell>
								</Table.Row>
								<Table.Row>
									<Table.HeaderCell>
										Datum
									</Table.HeaderCell>
									<Table.Cell textAlign="right">
										{moment(invoice.invoiceDate).format("DD.MM.YY")}
									</Table.Cell>
								</Table.Row>
								<Table.Row>
									<Table.HeaderCell>
										Betrag CHF
									</Table.HeaderCell>
									<Table.Cell textAlign="right">
										{FormatMoney(-invoice.amount)}
									</Table.Cell>
								</Table.Row>
								{
									invoice.balance ?
									<Table.Row>
										<Table.HeaderCell>
											Offen CHF
										</Table.HeaderCell>
										<Table.Cell textAlign="right">
											{FormatMoney(-invoice.balance)}
										</Table.Cell>
									</Table.Row>
									: null
								}
								<Table.Row>
									<Table.HeaderCell>
										PDF
									</Table.HeaderCell>
									<Table.Cell textAlign="right">
										<a
											href={`${props.baseHREF}pdf/invoice/${invoice.id}`}
											rel="noopener noreferrer"
											target="_blank"
										>
											<Icon name="download" /> Download
										</a>
									</Table.Cell>
								</Table.Row>
							</Table.Body>
						</Table>
						{
							payments.length ?
							<Table basic>
								<Table.Body>
									<Table.Row>
										<Table.HeaderCell colSpan="2">
											Zahlungen
										</Table.HeaderCell>
									</Table.Row>
									{
										_.map(
											payments,
											transaction => (
												<Table.Row key={transaction.id}>
													<Table.Cell>
														{
															moment(transaction.paymentDate)
																.format("DD.MM.YY")
														},
														{" "}
														{transaction.account}
													</Table.Cell>
													<Table.Cell textAlign="right">
														{FormatMoney(transaction.amount)}
													</Table.Cell>
												</Table.Row>
											)
										)
									}
								</Table.Body>
							</Table>
							: null
						}
						{
							bookings.length ?
							<Table basic>
								<Table.Body>
									<Table.Row>
										<Table.HeaderCell colSpan="2">
											Posten
										</Table.HeaderCell>
									</Table.Row>
									{
										_.map(
											bookings,
											transaction => (
												<Table.Row key={transaction.id}>
													<Table.Cell>
														{transaction.note}
													</Table.Cell>
													<Table.Cell textAlign="right">
														{FormatMoney(-transaction.amount)}
													</Table.Cell>
												</Table.Row>
											)
										)
									}
								</Table.Body>
							</Table>
							: null
						}
					</Card.Content>
				</Card>
				)
			})
		}
	</StyledDiv>
)

export default InvoiceHistoryPane
