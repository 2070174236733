import React, { Component } from "react"
import _ from "lodash"
import { Icon } from "semantic-ui-react"
import styled from "styled-components"

import { MEDIA_TYPE_LABELS } from '../../components/BorrowableDetails/MediaCard';

const StyledMovieOption = styled.div`
.wrap {
	display: flex;
	flex-flow: row wrap;

	&.disabled {
		color: #ff3333;
	}
	&.codeMatch {
		.filmcode {
			color: #21ba45;
		}
	}

	& div {
		width: 33.333%;
	}
}
`


class MovieOption extends Component {
	constructor(props) {
		super(props)

		this.handleMouseDown = this.handleMouseDown.bind(this)
		this.handleMouseEnter = this.handleMouseEnter.bind(this)
		this.handleMouseMove = this.handleMouseMove.bind(this)
	}
	handleMouseDown (event) {
		event.preventDefault();
		event.stopPropagation();
		if (this.props.isDisabled) return;
		this.props.onSelect(this.props.option, event);
	}
	handleMouseEnter (event) {
		this.props.onFocus(this.props.option, event);
	}
	handleMouseMove (event) {
		if (this.props.isFocused) return;
		this.props.onFocus(this.props.option, event);
	}
	render() {
		const { className, option } = this.props
		const { media, borrowable } = option.value
		
		const lang = media.languages || borrowable.customLanguages
		const sub = media.subtitles || borrowable.customSubtitle
		
		const releaseYears = []
		_.each(borrowable.motionPictures, (mp) => {
			releaseYears.push(parseInt(mp.releaseDate, 10))
		})
		const releaseYearsString = releaseYears.length? " ("+_.join(releaseYears, ", ")+")" : ""
		
		return (
			<StyledMovieOption
				onMouseDown={this.handleMouseDown}
				onMouseEnter={this.handleMouseEnter}
				onMouseMove={this.handleMouseMove}
				className={className}
			>
				<div className={`wrap ${this.props.isDisabled ? "disabled" : ""} ${media.codeMatch ? "codeMatch" : ""}`}>
					<div>
						<strong className="filmcode"><Icon name="film" /> {media.filmcode}</strong>
						<br/>
						{borrowable.customTitle}{releaseYearsString} {media.description}
					</div>
					<div>
						<Icon name="user" /> {borrowable.customDirector}
						<br/>
						<Icon name="users" /> {borrowable.customActor}
					</div>
					<div>
						{MEDIA_TYPE_LABELS[media.type]} {media.format} <Icon name="world" /> {media.regionCode}
						<br/>
						<Icon name="discussions" /> {lang} <Icon name="content" /> {sub}
					</div>
				</div>
			</StyledMovieOption>
		)
	}
}

export default MovieOption
