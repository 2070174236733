import React, { Component } from "react"
import { connect } from "react-redux"
import _ from "lodash"
import moment from "moment"
import {
	Card, Button, Grid, Form, Input, Modal,
	Label, Header, Message, Radio, Dimmer, Loader
} from "semantic-ui-react"
import DatePicker from "react-datepicker"

import { FormatMoney } from "../../components/Util"
import ScrollView from "../../components/ScrollView"
import LinkedClient from "../../components/LinkedClient/LinkedClient"
import DatepickerButton from "../../components/Util/DatepickerButton"

import Invoice from "./Invoice"
import Transaction from "./Transaction"

import {
	invoiceRequest,
	closeError,
} from "../../actions/cashRegister"

import {
	setClient,
	removeClient,
} from "../../actions/app"

import { requestClients } from "../../actions/clients"

class CashRegister extends Component {
	constructor(props) {
		super(props)


		this.state = {
			invoices: [],
			transactions: [],
			clientAccountCharge: 0,
			newInvoiceDate: new moment(),
		}

		this.handleInvoiceChange = this.handleInvoiceChange.bind(this)
		this.handleNewInvoiceDateChange = this.handleNewInvoiceDateChange.bind(this)
		this.calcTotals = this.calcTotals.bind(this)
		this.handleTransactionSelection = this.handleTransactionSelection.bind(this)
		this.handleInvoiceCheckout = this.handleInvoiceCheckout.bind(this)
		this.handleInvoiceDelete = this.handleInvoiceDelete.bind(this)
		this.handleCheckout = this.handleCheckout.bind(this)
		this.handlePdfSentClick = this.handlePdfSentClick.bind(this)
		this.handlePdfOverdueSentClick = this.handlePdfOverdueSentClick.bind(this)
		this.handleClientAccountChargeChange = this.handleClientAccountChargeChange.bind(this)
	}

	componentDidMount() {
		if (this.props.client && this.props.client.moneyTransactions) {
			this.loadTransactions(this.props.client.moneyTransactions)
		}
		if (this.props.client && this.props.client.invoices) {
			this.loadInvoices(this.props.client.invoices)
		}
	}

	componentWillReceiveProps(nextProps) {
		// we get the open transactions from all transactions
		if (
			nextProps.client &&
			nextProps.client.moneyTransactions &&
			this.props.client.moneyTransactions !== nextProps.client.moneyTransactions
		) {
			this.loadTransactions(nextProps.client.moneyTransactions)
		}

		// we get the unpaid invoices from all invoices
		if (
			nextProps.client &&
			nextProps.client.invoices &&
			this.props.client.invoices !== nextProps.client.invoices
		) {
			this.loadInvoices(nextProps.client.invoices)
		}

		// we want to indicate on each invoice if we are sending
		if (this.props.sendingInvoice && !nextProps.sendingInvoice) {
			this.setState({
				invoices: _.map(this.state.invoices, (i) => {i.sending = null;return i})
			})
		}
	}

	loadTransactions(transactions) {
		const openTransactions = _.map(
			_.filter(transactions, {invoiceId: 0, type: "Booking"}),
			(t) => {
				t.checked = false
				return t
			}
		)
		this.setState({
			transactions: openTransactions
		})
	}

	loadInvoices(invoices) {
		this.setState({ invoices: _.filter(invoices, i => i.balance !== 0 ? true : false) })
	}

	handleInvoiceChange(name, value, invoiceId, target=false) {
		const invoices = _.clone(this.state.invoices)
		let data = _.find(invoices, {id: invoiceId})
		if (!data) {
			return
		}
		if (name === "amount") {
			let val = Math.round(100 * parseFloat(_.replace(value, "'", ""))) / 100
			if (!val) val = 0
			if (_.isNaN(val)) return
			if (target) {
				if (_.isString(target)) document.getElementById(target).value = FormatMoney(val)
				else target.value = FormatMoney(val)
			}
			data.payAmount = val
		} else {
			if (value === "0") value = 0
			data[name] = value
		}
		this.setState({
			invoices: invoices
		})

		if (name === "date" && data && this.props.client) {
			data.invoiceDate = data.date.format("YYYY-MM-DD")
			this.props.invoiceRequest(data, this.props.client.id, invoiceId)
		}
	}

	handleNewInvoiceDateChange(date) {
		this.setState({
			newInvoiceDate: date
		})
	}

	handleTransactionSelection(checked, transactionId) {
		const ts = this.state.transactions

		if (transactionId === -1) {
			this.setState({
				transactions: _.map(ts, (t) => { t.checked = checked; return t })
			})
			return
		}

		let t = _.find(ts, { id: transactionId })
		if (t) {
			t.checked = checked
			this.setState({
				transactions: ts
			})
		}
	}

	handleInvoiceCheckout(e, invoiceId) {
		const data = _.find(this.state.invoices, {id: invoiceId})
		if (data && data.payAmount && data.account) {
			data.sending = true
			this.setState({
				invoices: _.map(this.state.invoices, i => i.id === data.id ? data : i)
			})
			data.payment = {
				amount: data.payAmount,
				account: data.account
			}
			delete data.payAmount
			delete data.account
			if (data.paymentDate) {
				data.payment.date = data.paymentDate
				delete data.paymentDate
			}
		}
		if (data && this.props.client) {
			this.props.invoiceRequest(data, this.props.client.id, invoiceId)
		}
	}

	handleInvoiceDelete(e, invoiceId) {
		const data = _.find(this.state.invoices, {id: invoiceId})

		if (this.props.client) {
			data.sending = true
			this.setState({
				invoices: _.map(this.state.invoices, i => i.id === data.id ? data : i)
			})
			this.props.invoiceRequest({delete: true}, this.props.client.id, invoiceId)
		}
	}

	handleCheckout(mode) {
		if (this.props.client) {
			const data = {}

			if (mode === "clientPayment") {
				// special case: charge client account
				data.mode = "client"
				data.amount = this.state.clientAccountCharge
				this.props.invoiceRequest(data, this.props.client.id, false)
				this.setState({
					clientAccountCharge: 0,
				})
				return
			}

			const totals = this.calcTotals()
			data.transactionIds = _.mapValues(_.filter(this.state.transactions, t => t.checked), "id")
			data.mode = mode

			if (mode === "cash") {
				data.payment = {
					account: "Kasse",
					amount: totals.total,
					date: this.state.newInvoiceDate.format("YYYY-MM-DD"),
				}
			}
			this.props.invoiceRequest(data, this.props.client.id, false)
		}
	}

	handlePdfSentClick(invoiceId) {
		if (this.props.client)
			this.props.invoiceRequest({pdfSent: true}, this.props.client.id, invoiceId)
	}

	handlePdfOverdueSentClick(invoiceId, unsend = false) {
		if (this.props.client) {
			if(unsend)
				this.props.invoiceRequest({unsend: unsend}, this.props.client.id, invoiceId)
			else
				this.props.invoiceRequest({pdfOverdueSent: true}, this.props.client.id, invoiceId)
		}
	}

	handleClientAccountChargeChange(e) {
		const val = parseInt(e.target.value, 10);
		if (!_.isNaN(val)) {
			this.setState({
				clientAccountCharge: val,
			});
		} else if (val === "") {
			this.setState({
				clientAccountCharge: 0,
			});
		}

	}

	calcTotals() {
		if (!this.state.transactions) {
			return {
				total: 0,
			}
		}
		return {
			total: -_.sumBy(_.filter(this.state.transactions, { checked: true }), "amount"),
		};
	}

	render() {
		const sendDisabled = !this.props.client || this.props.sending || !_.find(this.state.transactions, {checked: true})
		return (
			<ScrollView
				headerHeight="11em"
				header={(
					<LinkedClient
						client={this.props.client}
						baseHREF={this.props.baseHREF}
						setClient={this.props.setClient}
						removeClient={this.props.removeClient}
						performSearch={this.props.performClientSearch}
					/>
				)}
			>
				<div>
					{this.props.client &&
						<div>
							{!this.props.requesting ?
								<div>
									<Card fluid>
										<Card.Content>
											<Card.Content>
												<Grid verticalAlign="middle">
													<Grid.Column width={10}>
														<strong>
															Kundenkonto aufladen
														</strong>
													</Grid.Column>
													<Grid.Column width={4} textAlign="right">
														<Form.Field>
															<Input
																fluid
																labelPosition="right"
																placeholder="Betrag"
																name="amount"
																value={this.state.clientAccountCharge}
																onChange={this.handleClientAccountChargeChange}
															>
																<Label basic>CHF</Label>
																<input
																/>
															</Input>
														</Form.Field>
													</Grid.Column>
													<Grid.Column width={2} textAlign="right">
														<Button
															loading={this.props.sendingInvoice}
															fluid
															primary
															onClick={(e) => { this.handleCheckout("clientPayment") }}
															disabled={false}
															icon="money"
															content="Ok"
														/>
													</Grid.Column>
												</Grid>
											</Card.Content>
										</Card.Content>
									</Card>

									{(!this.props.client.requesting && this.state.invoices && this.state.invoices.length) ?
										<Card fluid>
											<Card.Content>
												<Card.Header>
													Offene Rechnungen
												</Card.Header>
											</Card.Content>
											<Card.Content>
												<Grid verticalAlign="middle">
													<Grid.Column width={2}>
														Rechnungsnummer
													</Grid.Column>
													<Grid.Column width={2} textAlign="center">
														R-Datum
													</Grid.Column>
													<Grid.Column width={2}>
														Rechnung
													</Grid.Column>
													<Grid.Column width={2}>
														Mahnung
													</Grid.Column>
													<Grid.Column width={2} textAlign="right">
														Offener Betrag
													</Grid.Column>
													<Grid.Column width={6}>
														Zahlung
													</Grid.Column>
												</Grid>
											</Card.Content>
											{_.map(this.state.invoices, invoice =>
												<Invoice
													key={invoice.id}
													invoice={invoice}
													baseHREF={this.props.baseHREF}
													onPdfSentClick={this.handlePdfSentClick}
													onPdfOverdueSentClick={this.handlePdfOverdueSentClick}
													onInvoiceChange={this.handleInvoiceChange}
													onInvoiceCheckout={this.handleInvoiceCheckout}
													onInvoiceDelete={this.handleInvoiceDelete}
												/>
											)}
											<Card.Content>
												<Grid verticalAlign="middle">
													<Grid.Column width={9}></Grid.Column>
													<Grid.Column width={3} textAlign="right">
														Offen: <strong>
															{FormatMoney(-_.sumBy(this.state.invoices, "balance"))}
														</strong>
													</Grid.Column>
													<Grid.Column width={2} textAlign="right">
														Total: <strong>
															{FormatMoney(_.sumBy(this.state.invoices, "amount") * -1)}
														</strong>
													</Grid.Column>
													<Grid.Column width={2} textAlign="right">
													</Grid.Column>
												</Grid>
											</Card.Content>
										</Card>
									:
										<Message positive>
											<Dimmer inverted active={this.props.client.requesting}>
												<Loader size="mini">Rechnungen werden geladen</Loader>
											</Dimmer>
											Keine offenen Rechnungen
										</Message>
									}
									{(!this.props.client.requesting && this.state.transactions && this.state.transactions.length) ?
										<Card fluid>
											<Card.Content>
												<Card.Header>
													<Grid verticalAlign="middle">
														<Grid.Column width={12}>
															Offene Posten
														</Grid.Column>
														<Grid.Column width={4} textAlign="right">
															<Radio
																size="tiny"
																toggle
																name="transaction-checkbox-all"
																checked={!_.find(this.state.transactions, { checked: false })}
																onChange={(e, { checked }) => this.handleTransactionSelection(checked, -1)}
															/>
														</Grid.Column>
													</Grid>
												</Card.Header>
											</Card.Content>
											{_.map(this.state.transactions, (transaction) =>
												<Transaction
													key={transaction.id}
													transaction={transaction}
													onTransactionSelection={this.handleTransactionSelection}
												/>
											)}
											<Card.Content>
												<Grid verticalAlign="middle">
													<Grid.Column width={14} textAlign="right">
														<strong>Total: {FormatMoney(this.calcTotals().total)}</strong>
													</Grid.Column>
													<Grid.Column width={2}>
													</Grid.Column>
												</Grid>
											</Card.Content>
											<Card.Content>
												<Grid verticalAlign="middle">
													<Grid.Row>
														<Grid.Column width={6}>
															<strong>
																Bezahlung per
															</strong>
														</Grid.Column>
														<Grid.Column width={3}>
															<Button
																fluid
																disabled={sendDisabled}
																loading={this.props.sendingInvoice}
																primary
																onClick={e => this.handleCheckout("invoice")}
																content="Rechnung"
																icon={"file pdf outline"}
															/>
														</Grid.Column>
														<Grid.Column width={3}>
															<Button
																fluid
																disabled={sendDisabled}
																loading={this.props.sendingInvoice}
																primary
																onClick={e => this.handleCheckout("client")}
																content="Kundenkonto"
																icon={"file pdf outline"}
															/>
														</Grid.Column>
														<Grid.Column width={2}>
															<DatePicker
																size="sm"
																disabled={sendDisabled}
																loading={this.props.sendingInvoice}
																customInput={<DatepickerButton disabled={sendDisabled} fluid/>}
																dateFormat="DD.MM.YYYY"
																selected={this.state.newInvoiceDate ? this.state.newInvoiceDate : moment()}
																onChange={this.handleNewInvoiceDateChange}
															/>
														</Grid.Column>
														<Grid.Column width={2}>
															<Button
																fluid
																disabled={sendDisabled}
																primary
																icon="send"
																content="Kasse"
																loading={this.props.sending}
																onClick={e => this.handleCheckout("cash")}
															/>
														</Grid.Column>
													</Grid.Row>
												</Grid>
											</Card.Content>
										</Card>
									:
										<Message positive>
											<Dimmer inverted active={this.props.client.requesting}>
												<Loader size="mini">Offene Posten werden geladen</Loader>
											</Dimmer>
											Keine offenen Posten
										</Message>
									}
								</div>
							: null}
						</div>
					}
				</div>
				<Modal basic open={this.props.error} onClose={this.props.closeErrorModal}>
					<Header icon="warning sign" content="Fehler" />
					<Modal.Content>
						<p>
							{this.props.error}
						</p>
					</Modal.Content>
					<Modal.Actions>
						<Button
							basic
							color="red"
							inverted
							onClick={this.props.closeErrorModal}
							icon="remove"
							content="Ok"
						/>
					</Modal.Actions>
				</Modal>
			</ScrollView>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		...state.cashRegister,
		client: state.app.client,
		baseHREF: state.app.baseHREF,
	};
}

const mapDispatchToProps = (dispatch) => {
	return {
		setClient: (client) => dispatch(setClient(client)),
		removeClient: () => dispatch(removeClient()),
		performClientSearch: (query) => dispatch(requestClients(query)),
		invoiceRequest: (data, clientId, invoiceId = false) => dispatch(invoiceRequest(data, clientId, invoiceId)),
		closeErrorModal: () => dispatch(closeError()),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(CashRegister)
