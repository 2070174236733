export const ADD_TO_BASKET = "ADD_TO_BASKET"
export function addToBasket(borrowable, media, isReservation) {
	return {
		type: ADD_TO_BASKET,
		borrowable: borrowable,
		media: media,
		isReservation: isReservation,
	}
}

export const REMOVE_FROM_BASKET = "REMOVE_FROM_BASKET"
export function removeFromBasket(media, isReservation) {
	return {
		type: REMOVE_FROM_BASKET,
		media: media,
		isReservation: isReservation,
	}
}

export const SET_PRICE_CATEGORY = "SET_PRICE_CATEGORY"
export function setPriceCat(rental, priceCat, group = false) {
	return {
		type: SET_PRICE_CATEGORY,
		rental: rental,
		priceCat: priceCat,
		group: group,
	}
}

export const ADD_MEMBERSHIP = "ADD_MEMBERSHIP"
export function addMembership(membership) {
	return {
		type: ADD_MEMBERSHIP,
		membership: membership,
	}
}

export const CHECKOUT = "CHECKOUT"
export const CHECKOUT_RESPONSE = "CHECKOUT_RESPONSE"
export function checkout() {
	return {
		type: CHECKOUT,
	}
}


export const ADD_ADDITIONAL_COST = "ADD_ADDITIONAL_COST"
export function addAdditionalCosts(amount, note) {
	return {
		type: ADD_ADDITIONAL_COST,
		amount: amount,
		note: note,
	}
}

export const REMOVE_ADDITIONAL_COST = "REMOVE_ADDITIONAL_COST"
export function removeAdditionalCosts(id) {
	return {
		type: REMOVE_ADDITIONAL_COST,
		id: id,
	}
}

export const CLEAR_BASKET = "CLEAR_BASKET"
export function clearBasket(gotoCashRegister) {
	return {
		type: CLEAR_BASKET,
		gotoCashRegister: gotoCashRegister,
	}
}

export const SET_RENTAL_DURATION = "SET_RENTAL_DURATION"
export function setRentalDuration(duration, entry) {
	return {
		type: SET_RENTAL_DURATION,
		duration: duration,
		entry: entry,
	}
}

export const SET_ENTRY_DATE = "SET_ENTRY_DATE"
export function setEntryDate(date, entry) {
	return {
		type: SET_ENTRY_DATE,
		date: date,
		entry: entry,
	}
}

export const CLOSE_BASKET_ERROR = "CLOSE_BASKET_ERROR"
export function closeError() {
	return {
		type: CLOSE_BASKET_ERROR,
	}
}
