import React, { Component } from "react"
import { FormatDateTime } from "../../components/Util"
import {
	Card, Button,
	Confirm, Dimmer,
	Divider,
} from "semantic-ui-react"

class Note extends Component {

	constructor(props) {
		super(props)

		this.state = {
			showDeleteModal: false
		}

		this.handleDelete = this.handleDelete.bind(this)
	}

	handleDelete(mode = null) {
		if(!this.props.note)
			return
		if (!mode)
			this.setState({showDeleteModal: true})
		else if (mode === "cancel")
			this.setState({showDeleteModal: null})
		else if (mode === "ok") {
			this.setState({
				showDeleteModal: null,
				isDeleting: true,
			})
			this.props.handleDelete()
		}
	}

	render() {
		const employeeName = this.props.employee ?
							 (
								 this.props.employee.firstName ?
								 this.props.employee.firstName : ""
							 ) +
							 (
								 this.props.employee.lastName ?
								 this.props.employee.lastName : ""
							 ) : ""
		return (
			<Dimmer.Dimmable as={Card.Content} dimmed={this.state.isDeleting}>
				<Dimmer
					active={this.state.isDeleting}
					inverted
				/>
				<Confirm
					open={this.state.showDeleteModal}
					onCancel={(e) => {this.handleDelete("cancel")}}
					onConfirm={(e) => {this.handleDelete("ok")}}
					header="Vorsicht!"
					content="Diese Notiz wirklich löschen?"
					cancelButton="Abbrechen"
					confirmButton="Löschen"
				/>
				<Card.Meta>
					{
						this.props.handleDelete &&
						<Button
							circular
							floated="right"
							color="red"
							onClick={(e) => {this.handleDelete()}}
							icon="trash"
/>
					}
				<div style={{ fontSize: "80%", textTransform: "uppercase" }}>
			<FormatDateTime rawDate={this.props.note.date}/>,
			{
				employeeName ?
				<span>
					{` ${employeeName}`}
				</span>
				: " System "
			}
				</div>
				</Card.Meta>
				<Card.Content style={{whiteSpace: "pre-wrap"}}>
					{this.props.note.content}
				</Card.Content>
				{
				!this.props.isLast && <Divider />
				}
			</Dimmer.Dimmable>
		)
	}

}

export default Note;
