import React, { Component } from "react"
import moment from "moment"
import {
	Card, Button, Label,
	Confirm, Dimmer
} from "semantic-ui-react"
import { Link } from "react-router-dom"
import CardFooterButtons from "../../components/CardFooterButtons"
import { getStatusLabel } from "../Movies/Borrowable"

class Reservation extends Component {

	constructor(props) {
		super(props)

		this.state = {
			showDeleteModal: false
		}

		this.handleDelete = this.handleDelete.bind(this)
	}

	handleDelete(mode = null) {
		if(!this.props.rental)
			return
		if (!mode)
			this.setState({showDeleteModal: true})
		else if (mode === "cancel")
			this.setState({showDeleteModal: null})
		else if (mode === "ok") {
			this.setState({
				showDeleteModal: null,
				isDeleting: true,
			})
			this.props.handleDelete(this.props.rental.id)
		}
	}

	render() {
		const rental = this.props.rental
		const statusLabel = getStatusLabel(rental.media.status);

		return (
			<Dimmer.Dimmable as={Card} dimmed={this.state.isDeleting}>
				<Dimmer
					active={this.state.isDeleting}
					inverted
				/>
				<Confirm
					open={this.state.showDeleteModal}
					onCancel={(e) => {this.handleDelete("cancel")}}
					onConfirm={(e) => {this.handleDelete("ok")}}
					header="Vorsicht!"
					content="Diese Reservation wirklich löschen?"
					cancelButton="Abbrechen"
					confirmButton="Löschen"
				/>
				<Card.Content>
					{
						rental.media.available ?
							<Label
								ribbon
								style={{ top: "-0.7em"}}
								color={statusLabel.color}
								icon={statusLabel.icon}
								content={statusLabel.text}
							/>
						:
							<Label color="red" ribbon style={{ top: "-0.7em"}}>Ausgeliehen</Label>
					}
				<Card.Header>
					<Link to={`/movies/${rental.media.borrowableId}`}>
						{rental.media.filmcode}
						<br />
						{rental.media.title}
					</Link>
				</Card.Header>
				<Card.Content>
					<Link to={`/clients/${rental.client.id}`}>
						{rental.client.fullName}
					</Link>
				</Card.Content>
				<Card.Description>
					Reserviert am <strong>{moment(rental.reservationDate).format("DD.MM.YYYY")}</strong>
					<br/>
						{
							rental.remindedDate ?
							<span>
								Reminder versendet am {
									moment(
										rental.remindedDate
									).format("DD.MM.YY")
								}
							</span>
							: null
						}
				</Card.Description>
				</Card.Content>
				<CardFooterButtons>
					<Button
						compact
						icon="trash"
						onClick={e => this.handleDelete()}
						content="Löschen"
					/>
					{
						rental.media.available ?
						<Button
							compact
							onClick={e => this.props.handleToBasket(rental)}
							icon="shopping basket"
							content="Warenkorb"
						/>
						: null
					}
				</CardFooterButtons>
				<CardFooterButtons>
					<Button
						compact
						onClick={
							e => this.props.remindMovieReservation(
								rental.id
							)
						}
						icon="alarm"
						content={
							`${rental.remindedDate ?
							"Erneut" : "Reminder"} als versendet markieren`
						}
					/>
				</CardFooterButtons>
			</Dimmer.Dimmable>
		)
	}

}

export default Reservation;
