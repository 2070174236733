import React from "react"
import { Grid, Header, Input, Icon, Button, Checkbox, Dropdown, Flag } from "semantic-ui-react"

const collator = new Intl.Collator("de-CH");

// Country codes that semantic-ui-react doesn’t have flags for
const noflag = ['AQ', 'BQ', 'CW', 'GG', 'IM', 'JE', 'BL', 'MF', 'SX', 'SS'];

const COUNTRIES = [
	{key: '', content: 'Alle', text: '', value: ''},
	{key: '⛔️', content: '⛔️ Ohne', text: '⛔️', value: '⛔️'},
	...Object.entries({
		AD: "Andorra",
		AE: "Vereinigte Arabische Emirate",
		AF: "Afghanistan",
		AG: "Antigua und Barbuda",
		AI: "Anguilla",
		AL: "Albanien",
		AM: "Armenien",
		AO: "Angola",
		AQ: "Antarktis",
		AR: "Argentinien",
		AS: "Amerikanisch-Samoa",
		AT: "Österreich",
		AU: "Australien",
		AW: "Aruba",
		AX: "Åland-Inseln",
		AZ: "Aserbaidschan",
		BA: "Bosnien und Herzegowina",
		BB: "Barbados",
		BD: "Bangladesch",
		BE: "Belgien",
		BF: "Burkina Faso",
		BG: "Bulgarien",
		BH: "Bahrain",
		BI: "Burundi",
		BJ: "Benin",
		BL: "Saint-Barthélemy",
		BM: "Bermuda",
		BN: "Brunei Darussalam",
		BO: "Bolivien",
		BQ: "Bonaire, Sint Eustatius und Saba",
		BR: "Brasilien",
		BS: "Bahamas",
		BT: "Bhutan",
		BV: "Bouvetinsel",
		BW: "Botswana",
		BY: "Belarus",
		BZ: "Belize",
		CA: "Kanada",
		CC: "Kokosinseln",
		CD: "Demokratische Republik Kongo",
		CF: "Zentralafrikanische Republik",
		CG: "Kongo",
		CH: "Schweiz",
		CI: "Elfenbeinküste",
		CK: "Cookinseln",
		CL: "Chile",
		CM: "Kamerun",
		CN: "China",
		CO: "Kolumbien",
		CR: "Costa Rica",
		CU: "Kuba",
		CV: "Kap Verde",
		CW: "Curaçao",
		CX: "Weihnachtsinsel",
		CY: "Zypern",
		CZ: "Tschechische Republik",
		DE: "Deutschland",
		DJ: "Dschibuti",
		DK: "Dänemark",
		DM: "Dominica",
		DO: "Dominikanische Republik",
		DZ: "Algerien",
		EC: "Ecuador",
		EE: "Estland",
		EG: "Ägypten",
		EH: "Westsahara",
		ER: "Eritrea",
		ES: "Spanien",
		ET: "Äthiopien",
		FI: "Finnland",
		FJ: "Fidschi",
		FK: "Falklandinseln",
		FM: "Mikronesien",
		FO: "Färöer-Inseln",
		FR: "Frankreich",
		GA: "Gabun",
		GB: "Vereinigtes Königreich",
		GD: "Grenada",
		GE: "Georgien",
		GF: "Französisch-Guayana",
		GG: "Guernsey",
		GH: "Ghana",
		GI: "Gibraltar",
		GL: "Grönland",
		GM: "Gambia",
		GN: "Guinea",
		GP: "Guadeloupe",
		GQ: "Äquatorialguinea",
		GR: "Griechenland",
		GS: "Südgeorgien und Südliche Sandwichinseln",
		GT: "Guatemala",
		GU: "Guam",
		GW: "Guinea-Bissau",
		GY: "Guyana",
		HK: "Hongkong",
		HM: "Heard-Insel und McDonald-Inseln",
		HN: "Honduras",
		HR: "Kroatien",
		HT: "Haiti",
		HU: "Ungarn",
		ID: "Indonesien",
		IE: "Irland",
		IL: "Israel",
		IM: "Insel Man",
		IN: "Indien",
		IO: "Britisches Territorium im Indischen Ozean",
		IQ: "Irak",
		IR: "Iran",
		IS: "Island",
		IT: "Italien",
		JE: "Jersey",
		JM: "Jamaika",
		JO: "Jordanien",
		JP: "Japan",
		KE: "Kenia",
		KG: "Kirgisistan",
		KH: "Kambodscha",
		KI: "Kiribati",
		KM: "Komoren",
		KN: "Saint Kitts und Nevis",
		KP: "Nordkorea",
		KR: "Südkorea",
		KW: "Kuwait",
		KY: "Kaimaninseln",
		KZ: "Kasachstan",
		LA: "Laos",
		LB: "Libanon",
		LC: "Saint Lucia",
		LI: "Liechtenstein",
		LK: "Sri Lanka",
		LR: "Liberia",
		LS: "Lesotho",
		LT: "Litauen",
		LU: "Luxemburg",
		LV: "Lettland",
		LY: "Libyen",
		MA: "Marokko",
		MC: "Monaco",
		MD: "Moldawien",
		ME: "Montenegro",
		MF: "Saint-Martin",
		MG: "Madagaskar",
		MH: "Marshallinseln",
		MK: "Nordmazedonien",
		ML: "Mali",
		MM: "Myanmar",
		MN: "Mongolei",
		MO: "Macau",
		MP: "Nördliche Marianen",
		MQ: "Martinique",
		MR: "Mauretanien",
		MS: "Montserrat",
		MT: "Malta",
		MU: "Mauritius",
		MV: "Malediven",
		MW: "Malawi",
		MX: "Mexiko",
		MY: "Malaysia",
		MZ: "Mosambik",
		NA: "Namibia",
		NC: "Neukaledonien",
		NE: "Niger",
		NF: "Norfolkinsel",
		NG: "Nigeria",
		NI: "Nicaragua",
		NL: "Niederlande",
		NO: "Norwegen",
		NP: "Nepal",
		NR: "Nauru",
		NU: "Niue",
		NZ: "Neuseeland",
		OM: "Oman",
		PA: "Panama",
		PE: "Peru",
		PF: "Französisch-Polynesien",
		PG: "Papua-Neuguinea",
		PH: "Philippinen",
		PK: "Pakistan",
		PL: "Polen",
		PM: "Saint-Pierre und Miquelon",
		PN: "Pitcairninseln",
		PR: "Puerto Rico",
		PS: "Palästinensische Gebiete",
		PT: "Portugal",
		PW: "Palau",
		PY: "Paraguay",
		QA: "Katar",
		RE: "Réunion",
		RO: "Rumänien",
		RS: "Serbien",
		RU: "Russland",
		RW: "Ruanda",
		SA: "Saudi-Arabien",
		SB: "Salomonen",
		SC: "Seychellen",
		SD: "Sudan",
		SE: "Schweden",
		SG: "Singapur",
		SH: "St. Helena",
		SI: "Slowenien",
		SJ: "Svalbard und Jan Mayen",
		SK: "Slowakei",
		SL: "Sierra Leone",
		SM: "San Marino",
		SN: "Senegal",
		SO: "Somalia",
		SR: "Suriname",
		SS: "Südsudan",
		ST: "São Tomé und Príncipe",
		SV: "El Salvador",
		SX: "Sint Maarten",
		SY: "Syrien",
		SZ: "Eswatini",
		TC: "Turks- und Caicosinseln",
		TD: "Tschad",
		TF: "Französische Süd- und Antarktisgebiete",
		TG: "Togo",
		TH: "Thailand",
		TJ: "Tadschikistan",
		TK: "Tokelau",
		TL: "Osttimor",
		TM: "Turkmenistan",
		TN: "Tunesien",
		TO: "Tonga",
		TR: "Türkei",
		TT: "Trinidad und Tobago",
		TV: "Tuvalu",
		TW: "Taiwan",
		TZ: "Tansania",
		UA: "Ukraine",
		UG: "Uganda",
		UM: "Amerikanische Überseeinseln",
		US: "Vereinigte Staaten",
		UY: "Uruguay",
		UZ: "Usbekistan",
		VA: "Vatikanstadt",
		VC: "Saint Vincent und die Grenadinen",
		VE: "Venezuela",
		VG: "Britische Jungferninseln",
		VI: "Amerikanische Jungferninseln",
		VN: "Vietnam",
		VU: "Vanuatu",
		WF: "Wallis und Futuna",
		WS: "Samoa",
		YE: "Jemen",
		YT: "Mayotte",
		ZA: "Südafrika",
		ZM: "Sambia",
		ZW: "Simbabwe"
	})
		.sort(([, name1], [,name2]) => collator.compare(name1, name2))
		.map(([key, name]) => ({
			key,
			text: key,
			value: key,
			content: name,
			flag: noflag.includes(key) ? undefined : key.toLowerCase()
		}))
];

const SearchHeader = (props) => {
	return <div>
		<Header
			style={{marginBottom: 0}}
			as="h3"
			content="Suche"
		/>
		<Grid>
			<Grid.Row verticalAlign="middle">
				<Grid.Column width={2}>
					<Input
						fluid
						name="code"
						placeholder="Code"
						onChange={props.handleQueryChange}
						value={props.query.code ?? ""}
					>
						<input accessKey="c" />
					</Input>
				</Grid.Column>
				<Grid.Column width={4}>
					<Input
						fluid
						disabled={!!props.query.code}
						name="title"
						placeholder="Titel"
						onChange={props.handleQueryChange}
						value={props.query.title ?? ""}
					/>
				</Grid.Column>
				<Grid.Column width={2}>
					<Input
						fluid
						disabled={!!props.query.code}
						name="director"
						placeholder="Regie"
						onChange={props.handleQueryChange}
						value={props.query.director ?? ""}
					/>
				</Grid.Column>
				<Grid.Column width={2}>
					<Input
						fluid
						disabled={!!props.query.code}
						name="actor"
						placeholder="Schauspieler"
						onChange={props.handleQueryChange}
						value={props.query.actor ?? ""}
					/>
				</Grid.Column>
				<Grid.Column width={3}>
					<Input
						fluid
						disabled={!!props.query.code}
						name="all"
						placeholder="Alles"
						onChange={props.handleQueryChange}
						value={props.query.all ?? ""}
					/>
				</Grid.Column>
				<Grid.Column width={1}>
					<Dropdown
						compact
						disabled={!!props.query.code}
						search={(countries, search) => {
							search = search.toLocaleUpperCase("de-CH");
							const filtered = countries.filter(country => country.key == search || country.content.toLocaleUpperCase("de-CH").startsWith(search));
							return filtered;
						}}
						direction="right"
						scrolling
						name="country"
						placeholder="Land"
						onChange={props.handleQueryChange}
						options={COUNTRIES}
						value={props.query.country ?? ""}
					/>
				</Grid.Column>
				<Grid.Column width={1}>
					<Checkbox
						label={<label title={`gelöschte ${props.excludeDeleted ? "anzeigen" : "verstecken"}`}>
							<Icon name="trash" />
						</label>}
						name="excludeDeleted"
						checked={!props.excludeDeleted}
						onChange={props.handleExcludeDeletedChange}
					/>
				</Grid.Column>
				<Grid.Column width={1} textAlign="right">
					<Button
						circular
						icon="eraser"
						onClick={props.resetQuery}
						disabled={!props.query.code && !props.query.title && !props.query.director && !props.query.actor && !props.query.country}
						accessKey="a"
					/>
				</Grid.Column>
			</Grid.Row>
		</Grid>
	</div>
}

export default SearchHeader
