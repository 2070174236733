import React, { Component } from "react"
import { Button } from "semantic-ui-react"

class DatepickerButton extends Component {

	render() {
		return (
			<Button
				onClick={this.props.onClick}
				size={this.props.size}
				disabled={this.props.disabled}
				fluid={this.props.fluid}
			>
				{this.props.value}
			</Button>
		)
	}
}

export default DatepickerButton
